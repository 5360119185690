import React from 'react';
import { Card } from 'react-bootstrap';
import './MemeFace.css';

const MemeFace = ({ memeFace, onClick }) => {
    return (
        <Card 
            className="meme-face-card"
            onClick={() => onClick(memeFace)}
        >
            <Card.Body className="p-2 text-center">
                <Card.Title className="mb-2 meme-face-title">{memeFace.name}</Card.Title>
                <div className="meme-face-img-container">
                    <img 
                        src={memeFace.thumb} 
                        alt={memeFace.name} 
                        className="meme-face-img"
                        loading="lazy" 
                    />
                </div>
                {memeFace.owner && memeFace.owner !== "memes" && (
                    <div className="meme-face-owner mt-1">
                        <small className="text-muted">by {memeFace.owner}</small>
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};

export default MemeFace;