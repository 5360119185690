import React, { Component } from "react";
import { Container, Row, Col, Card, Badge, ListGroup, Button, Form, Alert, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faTag, faHardDrive, faCalendarAlt, faImage, faSignOutAlt, faKey, faClipboard, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { getApiPath, getApiBase, humanFileSize, copyToClipboard } from "./Utils.js";
import './User.css';
import { useNavigate } from 'react-router-dom';

import TagManager from './TagManager';

function withNavigation(Component) {
  return props => {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  };
}

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            stashCount: 0,
            loading: true,
            error: null,
            // Password change form states
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            passwordError: null,
            passwordSuccess: null,
            passwordLoading: false,
            showPasswordForm: false,
            // API key copy state
            copySuccess: false
        };
    }

    componentDidMount() {
        this.fetchUserData();
    }

    fetchUserData = async () => {
        const apiKey = localStorage.getItem("apiKey");
        if (!apiKey) {
            this.setState({
                loading: false,
                error: "No API key found. Please log in."
            });
            return;
        }

        this.setState({ loading: true, error: null });
        
        try {
            // Fetch user data
            const userApiPath = getApiPath("user", apiKey, "");
            const userResponse = await fetch(userApiPath);
            
            if (!userResponse.ok) {
                throw new Error(`HTTP error ${userResponse.status} while fetching user data`);
            }
            
            const userData = await userResponse.json();
            
            // Fetch stash count
            const stashCountApiPath = `${getApiBase()}/${apiKey}/stashcount`;
            const stashCountResponse = await fetch(stashCountApiPath);
            
            if (!stashCountResponse.ok) {
                throw new Error(`HTTP error ${stashCountResponse.status} while fetching stash count`);
            }
            
            const stashCountData = await stashCountResponse.json();
            
            // Update state with all the data
            this.setState({
                user: userData,
                stashCount: stashCountData.count || 0,
                loading: false
            });
        } catch (error) {
            this.setState({
                loading: false,
                error: `Failed to load user data: ${error.message}`
            });
            console.error("Error fetching user data:", error);
        }
    }

    handleTagClick = (tag) => {
        this.props.navigate(`/stash?tag=${encodeURIComponent(tag)}`);
    }
    
    handleLogout = () => {
        // Clear user data from localStorage
        localStorage.removeItem("apiKey");
        localStorage.removeItem("username");
        localStorage.removeItem("user");
        localStorage.removeItem("apikey");
        
        // Redirect to login page
        this.props.navigate('/login');
    }
    
    // Password change handlers
    handlePasswordChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    
    togglePasswordForm = () => {
        this.setState(prevState => ({ 
            showPasswordForm: !prevState.showPasswordForm,
            // Reset form when toggling
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            passwordError: null,
            passwordSuccess: null
        }));
    }
    
    handlePasswordSubmit = async (e) => {
        e.preventDefault();
        
        const { currentPassword, newPassword, confirmPassword } = this.state;
        const apiKey = localStorage.getItem("apiKey");
        
        // Validate passwords
        if (newPassword !== confirmPassword) {
            this.setState({ passwordError: "New passwords don't match" });
            return;
        }
        
        if (newPassword.length < 8) {
            this.setState({ passwordError: "New password must be at least 8 characters long" });
            return;
        }
        
        this.setState({ passwordLoading: true, passwordError: null, passwordSuccess: null });
        
        try {
            const response = await fetch(`${getApiBase()}/pwchange`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    apikey: apiKey,
                    old_password: currentPassword,
                    new_password: newPassword
                }),
            });
            
            const data = await response.json();
            
            if (!response.ok) {
                throw new Error(data.error || 'Password change failed');
            }
            
            this.setState({
                passwordSuccess: "Password successfully changed!",
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
            });
            
        } catch (error) {
            this.setState({
                passwordError: error.message
            });
        } finally {
            this.setState({ passwordLoading: false });
        }
    }

    copyToClipboard = async () => {
        const apiKey = this.state.user?.apikey || localStorage.getItem("apiKey");
        if (apiKey) {
            try {
                // Use the utility function instead of directly using navigator.clipboard
                const success = await copyToClipboard(apiKey);
                
                if (success) {
                    this.setState({ copySuccess: true });
                    
                    // Reset the success state after 2 seconds
                    setTimeout(() => {
                        this.setState({ copySuccess: false });
                    }, 2000);
                }
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        }
    }

    render() {
        const { 
            user, stashCount, loading, error,
            showPasswordForm, currentPassword, newPassword, confirmPassword,
            passwordError, passwordSuccess, passwordLoading,
            copySuccess
        } = this.state;

        if (loading) {
            return (
                <Container className="user-profile mt-4">
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <p className="mt-2">Loading user profile...</p>
                    </div>
                </Container>
            );
        }

        if (error) {
            return (
                <Container className="user-profile mt-4">
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                </Container>
            );
        }

        if (!user) {
            return null;
        }

        // Format the date when the account was created
        const createdAt = user.created_at ? new Date(user.created_at).toLocaleDateString() : 'Unknown';

        // Get the API key from user object or localStorage as fallback
        const apiKey = user?.apikey || localStorage.getItem("apiKey");
        
      

        return (
            <Container className="user-profile mt-4">
                <h2 className="mb-4 user-profile-title">
                    <FontAwesomeIcon icon={faUser} className="me-2" />
                    User Profile
                </h2>
                
                <Row>
                    <Col lg={8} className="mx-auto">
                        <Card className="user-card shadow-sm">
                            <Card.Body>
                                <div className="user-header">
                                    <div className="user-avatar">
                                        {user.username ? user.username.charAt(0).toUpperCase() : '?'}
                                    </div>
                                    <div className="user-info">
                                        <h3 className="mb-0">{user.username || 'Anonymous User'}</h3>
                                        <p className="text-muted small mb-0">
                                            <FontAwesomeIcon icon={faCalendarAlt} className="me-1" />
                                            Member since {createdAt}
                                        </p>
                                    </div>
                                </div>

                                <hr className="my-4" />

                                <ListGroup variant="flush" className="user-details">
                                    <ListGroup.Item className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <FontAwesomeIcon icon={faImage} className="me-2 text-primary" />
                                            Stash Items
                                        </div>
                                        <Badge bg="info" pill>
                                            {stashCount.toLocaleString()}
                                        </Badge>
                                    </ListGroup.Item>
                                    
                                    <ListGroup.Item className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <FontAwesomeIcon icon={faHardDrive} className="me-2 text-primary" />
                                            Storage Used
                                        </div>
                                        <Badge bg="primary" pill>
                                            {humanFileSize(user.stash_size || 0, false, 1)}
                                        </Badge>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <div className="mb-2">
                                            <FontAwesomeIcon icon={faTag} className="me-2 text-primary" />
                                            Tags ({user.tags?.length || 0})
                                        </div>
                                        <div className="tag-collection">
                                            {user.tags && user.tags.length > 0 ? (
                                                // Sort tags alphabetically before mapping them
                                                [...user.tags].sort((a, b) => a.localeCompare(b)).map((tag, index) => (
                                                    <Badge 
                                                        key={index} 
                                                        bg="secondary" 
                                                        className="me-1 mb-1 tag-item clickable-tag" 
                                                        onClick={() => this.handleTagClick(tag)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {tag}
                                                    </Badge>
                                                ))
                                            ) : (
                                                <p className="text-muted small mb-0">No tags created yet</p>
                                            )}
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>

                                   <TagManager className='stash-tag-manager' tags={this.state.user.tags} signalTagsEdited={this.fetchUserData}></TagManager>   
                              
                                {/* API Key Section */}
                                <div className="mt-5">
                                    <hr />
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h5>
                                            <FontAwesomeIcon icon={faKey} className="me-2 text-warning" />
                                            Your API Key
                                        </h5>
                                    </div>
                                    
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            value={apiKey}
                                            readOnly
                                            className="api-key-display"
                                        />
                                        <Button 
                                            variant={copySuccess ? "success" : "outline-secondary"}
                                            onClick={this.copyToClipboard}
                                        >
                                            {copySuccess ? 
                                                <><FontAwesomeIcon icon={faCheckCircle} className="me-1" /> Copied</> : 
                                                <><FontAwesomeIcon icon={faClipboard} className="me-1" /> Copy</>
                                            }
                                        </Button>
                                    </InputGroup>
                                    <p className="text-muted small mb-0">
                                        This API key gives full access to your account. Keep it secure and don't share it.
                                    </p>
                                </div>

                                {/* Password change section */}
                                <div className="mt-5">
                                    <hr />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5>
                                            <FontAwesomeIcon icon={faKey} className="me-2" />
                                            Change Password
                                        </h5>
                                        <Button 
                                            variant="outline-secondary" 
                                            size="sm"
                                            onClick={this.togglePasswordForm}
                                        >
                                            {showPasswordForm ? 'Cancel' : 'Change Password'}
                                        </Button>
                                    </div>
                                    
                                    {showPasswordForm && (
                                        <div className="mt-3">
                                            {passwordError && <Alert variant="danger">{passwordError}</Alert>}
                                            {passwordSuccess && <Alert variant="success">{passwordSuccess}</Alert>}
                                            
                                            <Form onSubmit={this.handlePasswordSubmit}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Current Password (if migrating from apikey only use any value here)</Form.Label>
                                                    <Form.Control 
                                                        type="password"
                                                        name="currentPassword"
                                                        value={currentPassword}
                                                        onChange={this.handlePasswordChange}
                                                        required
                                                    />
                                                </Form.Group>
                                                
                                                <Form.Group className="mb-3">
                                                    <Form.Label>New Password</Form.Label>
                                                    <Form.Control 
                                                        type="password"
                                                        name="newPassword"
                                                        value={newPassword}
                                                        onChange={this.handlePasswordChange}
                                                        required
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Password must be at least 8 characters long.
                                                    </Form.Text>
                                                </Form.Group>
                                                
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Confirm New Password</Form.Label>
                                                    <Form.Control 
                                                        type="password"
                                                        name="confirmPassword"
                                                        value={confirmPassword}
                                                        onChange={this.handlePasswordChange}
                                                        required
                                                    />
                                                </Form.Group>
                                                
                                                <div className="d-grid">
                                                    <Button 
                                                        type="submit" 
                                                        variant="primary"
                                                        disabled={passwordLoading}
                                                    >
                                                        {passwordLoading ? 'Updating...' : 'Update Password'}
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    )}
                                </div>


                                <hr className="my-3" />
                                    
                                    {/* Logout section */}
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <FontAwesomeIcon icon={faSignOutAlt} className="me-2 text-danger" />
                                            Log Out of Account
                                        </div>
                                        <Button 
                                            variant="outline-danger" 
                                            size="sm"
                                            onClick={this.handleLogout}
                                        >
                                            Logout
                                        </Button>
                                    </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withNavigation(User);