import React, { Component } from 'react';
import { getApiPath } from "./Utils.js";
import MemeTemplateList from './MemeTemplateList.js';
import { Container } from 'react-bootstrap';
// import { Row } from 'react-bootstrap';

class Memes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            meme_defs: []
        };
    }

    componentDidMount() {
        // Replace 'API_URL' with the actual API URL
        const url = getApiPath("meme_def", localStorage.getItem("apiKey"), "");  
        fetch(url)
            .then(response => response.json())
            .then(data => {
                this.setState({ meme_defs: data });
            })
            .catch(error => console.error('Error fetching memes:', error));
    }

    render() {
        return (
            <Container>                
                <MemeTemplateList meme_defs={this.state.meme_defs} />
            </Container>
        );
    }
}

export default Memes;