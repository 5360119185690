import React, { Component, createRef } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import "./MemeTemplateList.css";
import { getApiBase, getApiPath, copyToClipboard, isUserAdmin } from './Utils';
import MemeModal from './MemeModal';
import { faPaintbrush, faTimes, faFilm, faImage, faGears } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// const THUMBNAIL_SIZE = 100;

class MemeTemplateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: '',
            currentPage: 1,
            itemsPerPage: this.calculateItemsPerPage(),  // Calculate initial value
            showModal: false,
            selectedMeme: null,
            text_strings: [],
            overlay_strings: [],
            mode: 'editing', // Add mode to state
            imageUrl: null,
            errorMessage: null,
            uploadUrl: null,
            isAdmin: isUserAdmin,
            filterType: 'all', // Add this new state for type filtering
            overlaysUsed: false, // Add this to track overlay usage
            isLoading: false,  // Add this for infinite scroll
            hasMore: true      // Add this for infinite scroll
        };
        this.firstInputRef = createRef();
        this.containerRef = createRef();  // Add this
        this.observer = null; // For intersection observer
    }

    calculateItemsPerPage = () => {
        // Get viewport width and height
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        
        // Calculate number of columns based on Bootstrap breakpoints
        let columnsPerRow;
        if (viewportWidth >= 992) {  // lg and up
            columnsPerRow = 6;  // showing 6 items per row
        } else if (viewportWidth >= 768) {  // md
            columnsPerRow = 4;
        } else if (viewportWidth >= 576) {  // sm
            columnsPerRow = 3;
        } else {  // xs
            columnsPerRow = 2;
        }

        // Calculate rows that would fit in viewport
        const itemHeight = 250;  // Approximate height of each item in pixels
        const availableHeight = viewportHeight - 200;  // Subtract space for header/footer/etc
        const rowsPerPage = Math.floor(availableHeight / itemHeight);
        
        // Calculate total items per page
        return columnsPerRow * Math.max(2, rowsPerPage);  // Minimum 2 rows
    }

    componentDidMount() {
        // Add window resize listener
        window.addEventListener('resize', this.handleResize);
        // Add keyboard listener
        document.addEventListener('keydown', this.handleKeyPress);
        window.addEventListener('scroll', this.handleStickyHeader);
        
        // Use Intersection Observer instead of scroll event
        this.setupInfiniteScroll();
    }

    componentWillUnmount() {
        // Clean up
        window.removeEventListener('resize', this.handleResize);
        // Remove keyboard listener
        document.removeEventListener('keydown', this.handleKeyPress);
        window.removeEventListener('scroll', this.handleStickyHeader);
        
        // Clean up the observer
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    setupInfiniteScroll = () => {
        // Create a sentinel element ref
        this.sentinelRef = createRef();
        
        // Create the observer
        this.observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && !this.state.isLoading && this.state.hasMore) {
                this.loadMoreItems();
            }
        }, { threshold: 0.1 });
        
        // Add the sentinel element to the render method and observe it
        setTimeout(() => {
            if (this.sentinelRef.current) {
                this.observer.observe(this.sentinelRef.current);
            }
        }, 100);
    }

    handleResize = () => {
        // Debounce the resize event
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }
        
        this.resizeTimeout = setTimeout(() => {
            const newItemsPerPage = this.calculateItemsPerPage();
            if (newItemsPerPage !== this.state.itemsPerPage) {
                this.setState({ 
                    itemsPerPage: newItemsPerPage,
                    currentPage: 1  // Reset to first page when resizing
                });
            }
        }, 250);  // Wait 250ms after last resize event
    }

    // Add handler for edit button
    handleEditClick = (e, meme) => {
        e.stopPropagation(); // Prevent triggering the parent div's click

        

        // Extract _id, handling potential $oid nesting
        const memeId = meme._id?.['$oid'] || meme._id;
        if (!memeId) {
            alert('Error: Unable to determine meme ID');
            return;
        }

        // Navigate to edit page
        window.location.href = `/meme?_id=${memeId}`;
    };

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.mode === 'editing' && prevState.mode !== 'editing') || 
            (this.state.showModal && !prevState.showModal)) {
            this.firstInputRef.current && this.firstInputRef.current.focus();
        }
        
        // If selectedMeme changed
        if (prevState.selectedMeme !== this.state.selectedMeme && this.state.selectedMeme) {
            const { texts = [], overlays = [] } = this.state.selectedMeme;
            
            // Create a new text_strings array with enough slots for both texts and overlays
            const text_strings = new Array(texts.length + overlays.length).fill('');
            
            this.setState({ text_strings });
        }
    }

    handleSearchChange = (event) => {
        this.setState({ searchQuery: event.target.value });
    }; 

    handleResetSearch = () => {
        this.setState({ searchQuery: '' });
    };

    handleItemClick = (meme) => {
        this.setState({ selectedMeme: meme, showModal: true, mode: 'editing' });
    };

    handleCloseModal = () => {
        this.setState({ 
            showModal: false, 
            selectedMeme: null, 
            text_strings: [], 
            mode: 'editing', 
            imageUrl: null, 
            errorMessage: null, 
            uploadUrl: null,
            overlaysUsed: false // Reset this state
        });
    };

    handleMake = async () => {
        const { selectedMeme, text_strings } = this.state;
        const textsLength = selectedMeme.texts?.length || 0;
        const overlaysLength = selectedMeme.overlays?.length || 0;
        
        // Check if there's at least one text or overlay with content
        const textValues = text_strings.slice(0, textsLength);
        const overlayValues = text_strings.slice(textsLength, textsLength + overlaysLength);
        
        const hasTextContent = textValues.some(text => text && text.trim() !== '');
        const hasOverlayContent = overlayValues.some(overlay => overlay && overlay.trim() !== '');
        
        // Ensure at least one field (text or overlay) has content
        if (!hasTextContent && !hasOverlayContent) {
            alert('Please enter at least one text or overlay URL.');
            return;
        }
        
        // Split the text_strings array into actual texts and overlays
        const actualTextStrings = text_strings.slice(0, textsLength);
        const actualOverlayStrings = text_strings.slice(textsLength, textsLength + overlaysLength);
        
        // Track if overlays were actually used (non-empty overlay URLs)
        const overlaysUsed = actualOverlayStrings.some(overlay => overlay && overlay.trim() !== '');
        
        const result = {
            name: selectedMeme.name,
            text_strings: actualTextStrings,
            overlay_strings: actualOverlayStrings,
            apikey: localStorage.getItem('apiKey')
        };        
        
        const url = getApiBase() + "/meme_by_name";

        this.setState({ mode: 'working' });

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(result)
            });

            // Enhanced error handling
            if (!response.ok) {
                const text = await response.text();
                throw new Error(`${response.status}: ${text}`);
            }

            // Check content-type header to ensure we received an image
            const contentType = response.headers.get('content-type');
            if (!contentType || !contentType.includes('image/')) {
                // If it's not an image, treat the response as an error message
                const errorText = await response.text();
                throw new Error(errorText || 'Server returned non-image content');
            }

            const blob = await response.blob();
            const imageUrl = URL.createObjectURL(blob);
            this.setState({ 
                imageUrl, 
                mode: 'done',
                overlaysUsed: overlaysUsed // Store whether overlays were used
            });
        } catch (error) {
            console.error('Error generating image:', error);
            
            // Format error message for better display
            let errorMsg = error.message;
            
            // If the error is HTML formatted, keep it as is
            // Otherwise, make it more readable
            if (!errorMsg.includes('<') && !errorMsg.includes('>')) {
                // Format non-HTML error messages
                if (errorMsg.includes(':')) {
                    const parts = errorMsg.split(':', 2);
                    if (parts[0].trim().match(/^\d+$/)) {
                        // It's likely a status code, format it nicely
                        errorMsg = `Error ${parts[0].trim()}: ${parts[1].trim()}`;
                    }
                }
            }
            
            this.setState({ 
                mode: 'error', 
                errorMessage: errorMsg 
            });
        }
    };

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.handleMake();
        }
    };
    handleCopy = async (p) => {
        try {
            await copyToClipboard(p);
            alert('Image copied to clipboard!');
        } catch (error) {
            alert('Clipboard feature does not work in this context.');
        }
    };
    

    handleUpload = async () => {
        try {
            this.setState({ mode: 'working' });
            const response = await fetch(this.state.imageUrl);
            const blob = await response.blob();
            const formData = new FormData();
            formData.append('file', blob);

            const url = getApiPath("upload", localStorage.getItem("apiKey"), "");

            const uploadResponse = await fetch(url, {
                method: 'POST',
                body: formData
            });

            if (!uploadResponse.ok) {
                const errorText = await uploadResponse.text();
                throw new Error(`${uploadResponse.status}: ${errorText}`);
            }

            const uploadUrl = await uploadResponse.text();
            this.setState({ mode: 'uploadsuccess', uploadUrl });
        } catch (error) {
            console.error('Error uploading image:', error);
            this.setState({ mode: 'uploadfailed', errorMessage: error.message });
        }
    };

    handleTextChange = (e, index, modeOrData = null) => {
        if (modeOrData) {
            if (typeof modeOrData === 'string') {
                // Handle simple mode changes
                this.setState({ mode: modeOrData });
            } else {
                // Handle complex state updates with mode and additional data
                this.setState({
                    mode: modeOrData.mode,
                    ...(modeOrData.uploadUrl && { uploadUrl: modeOrData.uploadUrl }),
                    ...(modeOrData.errorMessage && { errorMessage: modeOrData.errorMessage })
                });
            }
        } else {
            const text_strings = [...this.state.text_strings];
            text_strings[index] = e.target.value;
            this.setState({ text_strings });
        }
    };

    // In your parent component (likely MemeTemplateList.js)
    handleOverlayChange = (e, overlayIndex) => {
        const newText = e.target.value;
        // Calculate the actual index in the text_strings array
        const textIndex = overlayIndex + (this.state.selectedMeme.texts?.length || 0);
        
        this.setState(prevState => {
            const newTextStrings = [...prevState.text_strings];
            newTextStrings[textIndex] = newText;
            return { text_strings: newTextStrings };
        });
    };

    // Add this method to the class
    handleKeyPress = (event) => {
        // Don't handle keyboard navigation if modal is open
        if (this.state.showModal) return;

        // Only keep the Escape key handling for search reset
        if (event.key === 'Escape') {
            // Clear search if there's any search text
            if (this.state.searchQuery) {
                this.handleResetSearch();
            }
        }
    };

    // Add this helper method to get filtered memes (DRY principle)
    getFilteredMemes = () => {
        const { searchQuery, filterType } = this.state;
        const { meme_defs } = this.props;
        
        return meme_defs
            .filter(meme => {
                // First, apply name filter
                const nameMatch = !searchQuery || 
                    meme.name.toLowerCase().includes(searchQuery.toLowerCase());
                
                // Then, apply type filter if not "all"
                if (filterType === 'all') {
                    return nameMatch;
                } else if (filterType === 'animated') {
                    return nameMatch && (meme.animated || String(meme.background).endsWith(".gif"));
                } else if (filterType === 'static') {
                    return nameMatch && !(meme.animated || String(meme.background).endsWith(".gif"));
                }
                
                return nameMatch;
            });
    };

    handleFilterTypeChange = (filterType) => {
        this.setState({ 
            filterType,
            currentPage: 1 // Reset to first page when changing filter
        });
    };

    // Add this method for handling infinite scroll
    handleScroll = () => {
        // Don't load more if already loading or no more items
        if (this.state.isLoading || !this.state.hasMore) return;
        
        const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
        const scrollThreshold = document.documentElement.offsetHeight - 300;
        
        if (scrollPosition >= scrollThreshold) {
            this.loadMoreItems();
        }
    }

    // Method to load more items
    loadMoreItems = () => {
        const { currentPage, itemsPerPage } = this.state;
        const filteredMemes = this.getFilteredMemes();
        const totalPages = Math.ceil(filteredMemes.length / itemsPerPage);
        
        // Check if we have more pages to load
        if (currentPage < totalPages) {
            this.setState({ 
                isLoading: true 
            }, () => {
                // Simulate a delay for smoother UX
                setTimeout(() => {
                    this.setState(prevState => ({
                        currentPage: prevState.currentPage + 1,
                        isLoading: false,
                        hasMore: prevState.currentPage + 1 < totalPages
                    }));
                }, 300);
            });
        } else {
            this.setState({ hasMore: false });
        }
    }

    handleStickyHeader = () => {
        const filterContainer = document.querySelector('.filter-controls-container');
        const navbarHeight = document.querySelector('.navbar')?.offsetHeight || 56;  // Get actual navbar height
        
        if (filterContainer) {
            if (window.scrollY > 10) {
                filterContainer.classList.add('is-sticky');
                filterContainer.style.top = `${navbarHeight}px`;  // Dynamic height
            } else {
                filterContainer.classList.remove('is-sticky');
                filterContainer.style.top = `${navbarHeight}px`;  // Keep consistent
            }
        }
    }

    render() {
        const { 
            itemsPerPage, showModal, selectedMeme, imageUrl, mode, 
            errorMessage, uploadUrl, searchQuery, filterType, overlaysUsed,
            isLoading, currentPage
        } = this.state;

        const filteredMemes = this.getFilteredMemes();
        // Instead of slicing just the current page, show all memes up to the current page
        const currentMemes = filteredMemes.slice(0, currentPage * itemsPerPage);

        return (
            <div className="meme-template-page">
                <div className="filter-controls-container position-sticky">
                    <Container>
                        <Row className="mb-3 mt-3">
                            <Col sm={12} md={6} lg={4} className="d-flex">
                                <Form.Control
                                    type="text"
                                    placeholder="Search by name"
                                    value={searchQuery}
                                    onChange={this.handleSearchChange}
                                    className="me-2"
                                />
                                <Button 
                                    variant="secondary"
                                    onClick={this.handleResetSearch}
                                    disabled={!searchQuery}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                            </Col>
                            <Col sm={12} md={6} lg={8} className="d-flex align-items-center mt-sm-2 mt-md-0">
                                <div className="meme-filter-controls ms-md-auto">
                                    <div className="btn-group" role="group" aria-label="Filter by type">
                                        <Button 
                                            variant={filterType === 'all' ? 'primary' : 'outline-secondary'}
                                            onClick={() => this.handleFilterTypeChange('all')}
                                            className="filter-button"
                                        >
                                            All
                                        </Button>
                                        <Button 
                                            variant={filterType === 'animated' ? 'primary' : 'outline-secondary'} 
                                            onClick={() => this.handleFilterTypeChange('animated')}
                                            className="filter-button"
                                        >
                                            <FontAwesomeIcon icon={faFilm} className="me-1" /> Animated
                                        </Button>
                                        <Button 
                                            variant={filterType === 'static' ? 'primary' : 'outline-secondary'}
                                            onClick={() => this.handleFilterTypeChange('static')}
                                            className="filter-button"
                                        >
                                            <FontAwesomeIcon icon={faImage} className="me-1" /> Static
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container className='meme-template-list-container mt-4' ref={this.containerRef}>
                    <Row>
                        {currentMemes.map(meme => (
                            <Col className='meme-template-list-item' key={meme.name} xs={6} sm={4} md={3} lg={2} onClick={() => this.handleItemClick(meme)}>
                                <div className="meme-item">
                                    <p className="meme-name">                                        
                                        <FontAwesomeIcon icon={meme.animated || String(meme.background).endsWith(".gif") ? faFilm : faImage}   className='meme-icon me-2' />
                                        {meme.name} 
                                    </p>
                                    <img className='meme-template-list-img' src={meme.background} alt={meme.name} loading="lazy" />    
                                    <div className="button-container d-flex flex-column">
                                        <Button 
                                            className='meme-template-list-item-button mb-1 w-100' 
                                            variant="primary"
                                            onClick={() => this.handleItemClick(meme)}
                                        >
                                            <FontAwesomeIcon icon={faPaintbrush} className="me-1" /> Quick Create
                                        </Button>

                                    
                                        <Button 
                                            className='meme-template-list-item-button w-100' 
                                            variant="secondary"
                                            onClick={(e) => this.handleEditClick(e, meme)}
                                        >
                                            <FontAwesomeIcon icon={faGears} className="me-1" /> Customize
                                        </Button>
                                    
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    
                    {/* Loading indicator */}
                    {isLoading && (
                        <div className="text-center my-3">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    )}
                    
                    {/* Message when no more items */}
                    {!isLoading && !this.state.hasMore && filteredMemes.length > 0 && (
                        <div className="text-center my-3">
                            <p>No more memes to load</p>
                        </div>
                    )}
                    
                    {/* No results message */}
                    {filteredMemes.length === 0 && (
                        <div className="text-center my-5">
                            <h4>No memes found matching your search</h4>
                        </div>
                    )}
                    
                    {/* Sentinel element for infinite scroll */}
                    <div ref={this.sentinelRef} style={{ height: '10px', width: '100%' }}></div>
                </Container>
                {selectedMeme && (
                    <MemeModal
                        showModal={showModal}
                        handleCloseModal={this.handleCloseModal}
                        selectedMeme={selectedMeme}
                        text_strings={this.state.text_strings}
                        overlay_strings={this.state.overlay_strings}
                        handleTextChange={this.handleTextChange}
                        handleOverlayChange={this.handleOverlayChange}
                        handleKeyDown={this.handleKeyDown}
                        handleMake={this.handleMake}
                        handleCopy={this.handleCopy}
                        handleUpload={this.handleUpload}
                        mode={mode}
                        imageUrl={imageUrl}
                        errorMessage={errorMessage}
                        uploadUrl={uploadUrl}
                        overlaysUsed={overlaysUsed} // Pass this prop
                    />
                )}
            </div>
        );
    }
}

export default MemeTemplateList;