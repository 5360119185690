import React, { useState } from 'react';
import { Container, Form, Button, Image, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faUpload } from '@fortawesome/free-solid-svg-icons';
import { getApiBase, copyToClipboard, uploadImageBlobToService } from './Utils';
import WorkingOverlay from './WorkingOverlay';
import './MemeOverlay.css';

const MemeOverlay = () => {
  const [backgroundUrl, setBackgroundUrl] = useState('https://obj.shitpost.sh/shitpost.sh/ee8fe2a2-8849-45e0-8313-f94ab535caed.jpg');
  const [overlayUrl, setOverlayUrl] = useState('');
  const [generatedImage, setGeneratedImage] = useState(null);
  const [isWorking, setIsWorking] = useState(false);
  const [uploadedUrl, setUploadedUrl] = useState(null);
  const [copyMessage, setCopyMessage] = useState('');  // Add copy message state

  // Check for user authentication
  const userString = localStorage.getItem('user');
  if (!userString) {
    return <Container>nope</Container>;
  }

  const isValidUrl = (url) => {
    try {
      const parsed = new URL(url);
      return parsed.protocol === 'http:' || parsed.protocol === 'https:';
    } catch (e) {
      return false;
    }
  };

  const handleGenerate = async () => {
    setUploadedUrl(null);
    setGeneratedImage(null);

    if (!backgroundUrl || !overlayUrl) {
      alert('Both background and overlay URLs are required');
      return;
    }

    if (!isValidUrl(backgroundUrl) || !isValidUrl(overlayUrl)) {
      alert('Both URLs must be valid HTTP or HTTPS URLs');
      return;
    }

    setIsWorking(true);
    try {
      const response = await fetch(`${getApiBase()}/meme_overlay`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          background_url: backgroundUrl,
          overlay_url: overlayUrl
        })
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      setGeneratedImage(imageUrl);
    } catch (error) {
      alert(`Failed to generate overlay: ${error.message}`);
      setGeneratedImage(null);
    } finally {
      setIsWorking(false);
    }
  };

  const handleCopy = async () => {
    if (!generatedImage) return;
    
    try {
      // First fetch the image
      const response = await fetch(generatedImage);
      const blob = await response.blob();
      
      // Copy to clipboard
      const success = await copyToClipboard(URL.createObjectURL(blob));
      
      if (success) {
        setCopyMessage('Copied image');
        setTimeout(() => setCopyMessage(''), 5000);
      } else {
        setCopyMessage('Failed to copy image');
        setTimeout(() => setCopyMessage(''), 5000);
      }
    } catch (error) {
      console.error('Error copying image:', error);
      setCopyMessage('Failed to copy image');
      setTimeout(() => setCopyMessage(''), 5000);
    }
  };

  const handleUpload = async () => {
    if (!generatedImage) return;
    
    setIsWorking(true);
    try {
      const result = await uploadImageBlobToService(generatedImage);
      if (result.success) {
        setUploadedUrl(result.url);
        // alert(`Image uploaded successfully!`);
      } else {
        alert(`Failed to upload image: ${result.error}`);
      }
    } finally {
      setIsWorking(false);
    }
  };

  const handleCopyUrl = async () => {
    if (!uploadedUrl) return;
    
    try {
      await navigator.clipboard.writeText(uploadedUrl);
      setCopyMessage('Copied URL');
      setTimeout(() => setCopyMessage(''), 5000);
    } catch (error) {
      setCopyMessage('Failed to copy URL');
      setTimeout(() => setCopyMessage(''), 5000);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      handleGenerate();
    }
  };

  return (
    <Container>
      <WorkingOverlay isWorking={isWorking} message="WORKING" />
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Background URL</Form.Label>
          <Form.Control
            type="text"
            value={backgroundUrl}
            onChange={(e) => setBackgroundUrl(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Enter background image URL"
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Overlay URL</Form.Label>
          <Form.Control
            type="text"
            value={overlayUrl}
            onChange={(e) => setOverlayUrl(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Enter overlay image URL"
            required
          />
        </Form.Group>

        <div style={{ 
          height: '1.5rem',
          opacity: copyMessage ? 1 : 0,
          transition: 'opacity 0.3s ease-in-out'
        }}>
          {copyMessage}
        </div>

        <ButtonGroup className="mb-3">
          <Button 
            variant="primary" 
            onClick={handleGenerate}
            disabled={isWorking}
          >
            Generate Meme Overlay
          </Button>
          {generatedImage && (
            <>
              <Button variant="primary" onClick={handleCopy} disabled={isWorking}>
                <FontAwesomeIcon icon={faCopy} /> Copy
              </Button>
              <Button variant="primary" onClick={handleUpload} disabled={isWorking}>
                <FontAwesomeIcon icon={faUpload} /> Upload
              </Button>
            </>
          )}
        </ButtonGroup>

        {uploadedUrl && (
          <div className="mb-3 uploaded-url-container">
            <p>
              Uploaded URL: <a href={uploadedUrl} target="_blank" rel="noopener noreferrer">{uploadedUrl}</a>
            </p>
            <Button variant="secondary" onClick={handleCopyUrl}>
              <FontAwesomeIcon icon={faCopy} /> Copy Uploaded URL
            </Button>
          </div>
        )}

        {generatedImage && (
          <div className="mt-3">
            <div className="generated-image-container">
              <Image src={generatedImage} fluid className="generated-image" />
            </div>
          </div>
        )}
      </Form>
    </Container>
  );
};

export default MemeOverlay;