import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Form, InputGroup, Badge } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import './TagPicker.css';

const TagPicker = ({ 
  availableTags = [], 
  selectedTags = [], 
  onTagToggle,
  className = '',
  placeholder = 'Search or add tags...',
  showSelectedTags = true, // Control if selected tags are shown
  selectedTagsPosition = 'inline' // Can be 'inline' (horizontal) or 'block' (wrapped)
}) => {
  const [searchText, setSearchText] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredTags, setFilteredTags] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1); // For keyboard navigation
  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);
  const activeItemRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });

  // Filter available tags based on search text
  useEffect(() => {
    if (searchText.trim()) {
      const filtered = availableTags.filter(tag => 
        tag.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredTags(filtered);
      setActiveIndex(-1); // Reset active index when filter changes
    } else {
      setFilteredTags([]);
    }
  }, [searchText, availableTags]);

  // Handle clicks outside to close suggestions
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target) &&
          inputRef.current && !inputRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Scroll active item into view when navigating with keyboard
  useEffect(() => {
    if (activeItemRef.current && activeIndex >= 0) {
      activeItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [activeIndex]);

  // Update dropdown position when showing suggestions
  useEffect(() => {
    if (showSuggestions && inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width
      });
    }
  }, [showSuggestions]);

  const handleInputFocus = () => {
    setShowSuggestions(true);
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    setShowSuggestions(true);
  };

  const handleTagSelect = (tag) => {
    onTagToggle(tag);
    setSearchText('');
    setShowSuggestions(false);
    inputRef.current.focus();
  };

  const handleKeyDown = (e) => {
    // Get all selectable items for keyboard navigation
    let selectableItems = [];
    
    if (filteredTags.length > 0) {
      selectableItems = filteredTags;
    } else if (searchText.trim()) {
      selectableItems = [searchText.trim()];
    } else if (!searchText) {
      selectableItems = availableTags.filter(tag => !selectedTags.includes(tag)).slice(0, 10);
    }

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setActiveIndex(prevIndex => 
          prevIndex < selectableItems.length - 1 ? prevIndex + 1 : 0
        );
        break;
      
      case 'ArrowUp':
        e.preventDefault();
        setActiveIndex(prevIndex => 
          prevIndex > 0 ? prevIndex - 1 : selectableItems.length - 1
        );
        break;
      
      case 'Enter':
        e.preventDefault();
        if (activeIndex >= 0 && selectableItems[activeIndex]) {
          handleTagSelect(selectableItems[activeIndex]);
        } else if (searchText.trim()) {
          // If there's an exact match in available tags, toggle it
          const exactMatch = availableTags.find(
            tag => tag.toLowerCase() === searchText.toLowerCase()
          );
          
          if (exactMatch) {
            handleTagSelect(exactMatch);
          } else if (filteredTags.length > 0) {
            // Select the first suggestion
            handleTagSelect(filteredTags[0]);
          } else if (searchText.trim().length > 0) {
            // Create new tag if enabled
            handleTagSelect(searchText.trim());
          }
        }
        break;
      
      case 'Escape':
        setShowSuggestions(false);
        inputRef.current.blur();
        break;
        
      default:
        break;
    }
  };

  // Get non-selected available tags
  const availableNonSelectedTags = availableTags.filter(tag => !selectedTags.includes(tag));

  // Render selected tags in appropriate layout
  const renderSelectedTags = () => {
    if (!showSelectedTags || selectedTags.length === 0) return null;

    const flexClass = selectedTagsPosition === 'inline' 
      ? 'tag-picker-selected-tags-inline' 
      : 'tag-picker-selected-tags-block';

    return (
      <div className={`tag-picker-selected-tags ${flexClass} mt-2`}>
        {selectedTags.map(tag => (
          <Badge 
            key={tag} 
            bg="secondary" 
            className="me-1 mb-1 tag-badge"
            style={{cursor: 'pointer'}}
            onClick={() => onTagToggle(tag)}
          >
            {tag}
            <span className="ms-1">×</span>
          </Badge>
        ))}
      </div>
    );
  };

  // Render dropdown with suggestions
  const renderSuggestionsDropdown = () => {
    if (!showSuggestions) return null;
    
    const dropdownContent = (
      <div 
        className="tag-suggestions-wrapper" 
        style={{
          position: 'fixed',
          top: `${dropdownPosition.top}px`,
          left: `${dropdownPosition.left}px`,
          width: `${dropdownPosition.width}px`,
          zIndex: 9999
        }}
      >
        <div className="tag-suggestions" ref={suggestionsRef}>
          {filteredTags.length > 0 ? (
            <div className="tag-suggestions-list">
              {filteredTags.map((tag, index) => (
                <div 
                  key={tag} 
                  ref={activeIndex === index ? activeItemRef : null}
                  className={`tag-suggestion-item ${activeIndex === index ? 'active' : ''} ${selectedTags.includes(tag) ? 'already-selected' : ''}`}
                  onClick={() => handleTagSelect(tag)}
                >
                  <span className="tag-text">{tag}</span>
                </div>
              ))}
            </div>
          ) : searchText.trim() ? (
            <div 
              className={`tag-suggestion-item new-tag ${activeIndex === 0 ? 'active' : ''}`}
              ref={activeIndex === 0 ? activeItemRef : null}
              onClick={() => handleTagSelect(searchText.trim())}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              <span>Create "{searchText}"</span>
            </div>
          ) : (
            <div className="tag-suggestion-empty">
              {selectedTags.length === availableTags.length 
                ? "All tags selected" 
                : "Type to search tags"}
            </div>
          )}
          
          {/* Show available tags that aren't already selected when no search text */}
          {!searchText && availableNonSelectedTags.length > 0 && (
            <div className="available-tags-section">
              <div className="available-tags-header">Available Tags</div>
              <div className="available-tags-list">
                {availableNonSelectedTags
                  .slice(0, 10) // Limit to avoid overwhelming dropdown
                  .map((tag, index) => (
                    <div 
                      key={tag} 
                      className={`tag-suggestion-item ${activeIndex === index ? 'active' : ''}`}
                      onClick={() => handleTagSelect(tag)}
                    >
                      {tag}
                    </div>
                  ))}
                
                {availableNonSelectedTags.length > 10 && (
                  <div className="more-tags-available">
                    {availableNonSelectedTags.length - 10} more tags available...
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
    
    // Use portal to render dropdown outside of any containers that might clip it
    return ReactDOM.createPortal(
      dropdownContent,
      document.body
    );
  };

  return (
    <div className={`tag-picker-container ${className}`}>
      {/* Search input */}
      <div className="tag-search-container">
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
          <Form.Control
            ref={inputRef}
            type="text"
            placeholder={placeholder}
            value={searchText}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onKeyDown={handleKeyDown}
            autoComplete="off"
            aria-label="Search tags"
          />
        </InputGroup>
      </div>

      {/* Show selected tags */}
      {renderSelectedTags()}

      {/* Show dropdown */}
      {renderSuggestionsDropdown()}
    </div>
  );
};

export default TagPicker;