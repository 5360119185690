import React from 'react';
import './WorkingOverlay.css';
import { isShitpostDomain } from './Utils';

const getDomainSpecificImage = () => {
    if (isShitpostDomain) {
        return {
            src: "/images/poop-32x32.png",
            alt: "poop icon"
        };
    }
    return {
        src: "/images/fren-32x32.png",
        alt: "fren logo"
    };
};

const WorkingOverlay = ({ isWorking, message = 'WORKING' }) => {
    if (!isWorking) return null;

    const image = getDomainSpecificImage();

    return (
        <div className="overlay">
            <div className="overlay-content">
                <img 
                    src={image.src} 
                    alt={image.alt} 
                    width='100' 
                    height='100' 
                    className="poop-spin"
                />
                <div className="overlay-message">{message}</div>
            </div>
        </div>
    );
};

export default WorkingOverlay;