import React, { useRef, useEffect, useState } from 'react';
import { Modal, Button, Form, Row, InputGroup, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCopy, faUpload, faEdit, faClose, faPaintbrush, 
    faDownload,   faCheck, faCheckCircle, faClipboard,
    faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import './Modal.css';
import { copyToClipboard, uploadImageBlobToService, getShortenedUrl, getApiBase } from './Utils';
import WorkingOverlay from './WorkingOverlay';
import "./MemeModal.css";
import MemeFacePicker from './MemeFacePicker';

const MemeModal = ({
    showModal,
    handleCloseModal,
    selectedMeme,
    text_strings,
    overlay_strings,
    handleTextChange,
    handleOverlayChange,
    handleKeyDown,
    handleMake,
    mode,
    imageUrl,
    errorMessage,
    uploadUrl,
    overlaysUsed  // Add this prop
}) => {
    const firstInputRef = useRef(null);
    const [isWorking, setIsWorking] = useState(false);
    const [copyMessage, setCopyMessage] = useState('');
    const [isAnimated, setIsAnimated] = useState(false);
    const [discordUrlCopied, setDiscordUrlCopied] = useState(false);
    const [discordUrl, setDiscordUrl] = useState('');
    
    // Check if we're dealing with a GIF or animated WebP on component load or imageUrl change
    useEffect(() => {
        const checkIfAnimated = async () => {
            if (imageUrl && mode === 'done') {
                // First check if URL itself indicates a GIF
                const isGifUrl = imageUrl.toLowerCase().includes('.gif');
                
                // For blob URLs, we need to check the actual content type
                if (imageUrl.startsWith('blob:')) {
                    try {
                        const response = await fetch(imageUrl);
                        const blob = await response.blob();
                        const mimeType = blob.type;
                        
                        // Check if it's a GIF
                        const isGifBlob = mimeType === 'image/gif';
                        
                        // Check if it's a WebP (which might be animated)
                        const isWebP = mimeType === 'image/webp';
                        
                        if (isGifBlob) {
                            // GIFs are always animated
                            setIsAnimated(true);
                        } else if (isWebP) {
                            // For WebP, we need to check if it's animated
                            const isAnimatedWebP = await checkIfWebPIsAnimated(blob);
                            setIsAnimated(isAnimatedWebP);
                        } else {
                            setIsAnimated(false);
                        }
                    } catch (err) {
                        console.error("Error checking blob type:", err);
                        // If we can't determine, assume based on URL
                        setIsAnimated(isGifUrl);
                    }
                } else {
                    // For direct URLs, rely on the extension
                    setIsAnimated(isGifUrl);
                }
            } else {
                setIsAnimated(false);
            }
        };
        
        // Helper function to check if a WebP image is animated
        const checkIfWebPIsAnimated = async (blob) => {
            // Create an ArrayBuffer from the blob to check the WebP header
            const buffer = await blob.arrayBuffer();
            const arr = new Uint8Array(buffer);
            
            // WebP format checking based on headers
            // Animated WebP files contain 'ANIM' chunk
            // This is a simplified version of checking for animation
            const textDecoder = new TextDecoder('ascii');
            
            // Convert relevant parts of the buffer to strings to check for ANIM chunk
            // We need to look beyond the initial RIFF header
            for (let i = 0; i < arr.length - 4; i++) {
                if (i > 12) { // Skip the initial RIFF and WEBP headers
                    const chunk = textDecoder.decode(arr.slice(i, i + 4));
                    if (chunk === 'ANIM') {
                        return true; // Found ANIM chunk, it's an animated WebP
                    }
                }
            }
            
            return false; // No ANIM chunk found, likely not animated
        };
        
        checkIfAnimated();
    }, [imageUrl, mode]);

    useEffect(() => {
        if ((mode === 'editing' || showModal) && firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, [mode, showModal]);

    const handleCopy = async (contentToCopy) => {
        if (!contentToCopy) return;

        const success = await copyToClipboard(contentToCopy);
        if (success) {
            setCopyMessage(`Copied ${mode === 'uploadsuccess' ? 'URL' : 'image'}`);
            setTimeout(() => setCopyMessage(''), 5000);
        } else {
            setCopyMessage(`Failed to copy ${mode === 'uploadsuccess' ? 'URL' : 'image'}`);
            setTimeout(() => setCopyMessage(''), 5000);
        }
    };

    const handleSave = async (contentToCopy) => {
        if (!contentToCopy) return;

        const success = await copyToClipboard(contentToCopy, true);
        if (success) {
            setCopyMessage(`Saved ${isAnimated ? 'animated image' : 'image'}`);
            setTimeout(() => setCopyMessage(''), 5000);
        } else {
            setCopyMessage(`Failed to save ${isAnimated ? 'animated image' : 'image'}`);
            setTimeout(() => setCopyMessage(''), 5000);
        }
    };

    const handleUpload = async () => {
        if (!imageUrl) return;

        setIsWorking(true);
        const uploadResult = await uploadImageBlobToService(imageUrl);

        if (uploadResult.success) {
            const shortenResult = await getShortenedUrl(uploadResult.url);
            
            handleTextChange(null, null, {
                mode: 'uploadsuccess',
                uploadUrl: shortenResult.success ? shortenResult.shortUrl : uploadResult.url
            });
        } else {
            handleTextChange(null, null, {
                mode: 'uploadfailed',
                errorMessage: uploadResult.error
            });
        }
        
        setIsWorking(false);
    };

    // Add this validation function before handleMakeWithOverlay
    const validateInputs = () => {
        // Check if there are any text inputs with values
        const hasTextContent = selectedMeme.texts && 
            text_strings.slice(0, selectedMeme.texts.length).some(text => text && text.trim() !== '');
        
        // Check if there are any overlay inputs with values
        const textOffset = selectedMeme.texts ? selectedMeme.texts.length : 0;
        const hasOverlayContent = selectedMeme.overlays && 
            text_strings.slice(textOffset, textOffset + selectedMeme.overlays.length).some(overlay => overlay && overlay.trim() !== '');
        
        // At least one input field (text or overlay) must have a value
        return hasTextContent || hasOverlayContent;
    };

    // Update handleMakeWithOverlay to use validation
    const handleMakeWithOverlay = async () => {
        if (!validateInputs()) {
            alert('Please enter at least one text or overlay URL.');
            return;
        }
        
        setIsWorking(true);
        await handleMake();
        setIsWorking(false);
    };
/*
    // Enhanced key down handler that shows overlay
    const handleKeyDownWithOverlay = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission
            handleMakeWithOverlay();
        } else {
            // Pass along other key events to the original handler
            if (handleKeyDown) {
                handleKeyDown(event);
            }
        }
    };*/

    // Modify the handlePasteOverlay function to work with the new component
    const handlePasteOverlay = async (e, index) => {
        const clipboardData = e.clipboardData;
        const items = clipboardData.items;
        
        // Check if clipboard has an image
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                e.preventDefault(); // Prevent default paste behavior
                
                // Get image from clipboard
                const blob = items[i].getAsFile();
                const blobUrl = URL.createObjectURL(blob);
                
                // Show working overlay
                setIsWorking(true);
                
                try {
                    // Upload the image
                    const uploadResult = await uploadImageBlobToService(blobUrl);
                    
                    // Update the overlay field with the uploaded URL if successful
                    if (uploadResult.success) {
                        // Update the corresponding overlay input with the URL
                        const textOffset = selectedMeme.texts ? selectedMeme.texts.length : 0;
                        const newTextStrings = [...text_strings];
                        newTextStrings[index + textOffset] = uploadResult.url;
                        
                        // Call handleOverlayChange with the new URL
                        const mockEvent = { target: { value: uploadResult.url } };
                        handleOverlayChange(mockEvent, index);
                    } else {
                        alert('Failed to upload pasted image: ' + uploadResult.error);
                    }
                } catch (error) {
                    console.error('Error handling pasted image:', error);
                    alert('Failed to process pasted image');
                } finally {
                    // Clean up blob URL and hide working overlay
                    URL.revokeObjectURL(blobUrl);
                    setIsWorking(false);
                }
                
                // Only process the first image found
                return;
            }
        }
    };
 
    // Helper function to generate Discord URL
    const generateDiscordUrl = () => {
        if (!selectedMeme || !selectedMeme.name) return null;
        
        // URL encode the meme name - encodeURIComponent handles all special chars
        const encodedMemeName = encodeURIComponent(selectedMeme.name);

        // Extract the file extension from the meme background URL
        let fileExtension = '';
        if (selectedMeme.background) {
            const backgroundUrl = selectedMeme.background;
            const urlParts = backgroundUrl.split('.');
            if (urlParts.length > 1) {
                fileExtension = "." + urlParts[urlParts.length - 1].split(/[?#]/)[0].toLowerCase();
            }
        }

        if (fileExtension === '.gif') {
            fileExtension = ".webp"; // the api will return webp for .gif files
        }
        
        // Build query parameters for each text input - include all parameters even if empty
        const textParams = [];
        if (selectedMeme.texts) {
            selectedMeme.texts.forEach((_, index) => {
                // Include parameter even if text is empty
                const textValue = text_strings[index] ? text_strings[index].trim() : '';
                const encodedText = encodeURIComponent(textValue);
                textParams.push(`t${index + 1}=${encodedText}`);
            });
        }
        
        // Construct the final URL - always include query string since we always include parameters
        const queryString = textParams.length > 0 ? `?${textParams.join('&')}` : '';
        const discordUrl = `${getApiBase()}/meme/${encodedMemeName}/x${fileExtension}${queryString}`;
        
        return discordUrl;
    };

    const copyDiscordUrlToClipboard = async () => {
        if (!discordUrl) return;
        
        const success = await copyToClipboard(discordUrl);
        if (success) {
            setDiscordUrlCopied(true);
            setTimeout(() => setDiscordUrlCopied(false), 5000);
        }
    }   
    
    const handleCopyDiscordUrl = async () => {
        const generatedUrl = generateDiscordUrl();
        if (!generatedUrl) 
            return;
        
        // Set the URL in state
        setDiscordUrl(generatedUrl);        
        
        // Copy to clipboard
        // const success = await copyToClipboard(generatedUrl);
        
        handleTextChange(null, null, 'discordurlsuccess');
        
    };

    return (
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{selectedMeme.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <WorkingOverlay isWorking={isWorking} message={mode === 'working' ? "WORKING" : "Uploading your pasted item"} />
                
                {/* Add this Alert component to display error messages */}
                {mode === 'error' && errorMessage && (
                    <Alert variant="danger" className="mb-3">
                        <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                        <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
                    </Alert>
                )}
                
                <div className={isWorking ? 'non-interactable' : ''}>
                    {/* Modified condition to include error mode to keep showing the form */}
                    {(mode === 'editing' || mode === 'working' || mode === 'error') && (
                        <>
                            <img src={selectedMeme.background} alt={selectedMeme.name} className="modal-image mb-3" />
                            
                            {/* Text inputs without icons */}
                            {selectedMeme.texts && selectedMeme.texts.map((text, index) => (
                                <div key={`text-${index}`} className="mb-3">
                                    <Form.Control
                                        as="textarea"
                                        rows={2}
                                        placeholder={text.hint || `Text ${index + 1}`}
                                        value={text_strings[index] || ''}
                                        onChange={(e) => handleTextChange(e, index)}
                                        ref={index === 0 ? firstInputRef : null}
                                        onKeyDown={(e) => {
                                            // Only trigger "Make" on Ctrl+Enter or Command+Enter to allow normal newlines
                                            if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
                                                e.preventDefault();
                                                handleMakeWithOverlay();
                                            }
                                        }}
                                        required
                                        className="modal-text-input"
                                        style={{ fontFamily: 'monospace' }}
                                    />
                                </div>
                            ))}
                            
                            {/* Overlay inputs without icons */}
                            {selectedMeme.overlays && selectedMeme.overlays.map((overlay, index) => {
                                const textIndex = index + (selectedMeme.texts?.length || 0);
                                const overlayUrl = text_strings[textIndex] || '';
                                
                                // Create a proper meme face object if we have a URL
                                const selectedMemeFace = overlayUrl ? {
                                    objectUrl: overlayUrl,
                                    thumb: overlayUrl,
                                    name: overlay.hint || `Overlay ${index + 1}`
                                } : null;
                                
                                return (
                                    <div key={`overlay-${index}`} className="mb-3">
                                        <MemeFacePicker
                                            label={null}
                                            url={overlayUrl}
                                            selectedMemeFace={selectedMemeFace}
                                            readOnly={false}
                                            placeholder={overlay.hint || `Overlay ${index + 1}`}
                                            onChange={(e) => handleOverlayChange(e, index)}
                                            onPaste={(e) => handlePasteOverlay(e, index)}
                                            onMemeFaceSelect={(memeFace) => {
                                                // Create a synthetic event to use with handleOverlayChange
                                                const mockEvent = { 
                                                    target: { value: memeFace.objectUrl }
                                                };
                                                handleOverlayChange(mockEvent, index);
                                            }}
                                            onClear={() => {
                                                // Create a synthetic event to clear the field
                                                const mockEvent = { target: { value: '' } };
                                                handleOverlayChange(mockEvent, index);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </>
                    )}
                    {mode === 'done' && (
                        <Row>
                            <img src={imageUrl} alt="Generated Meme" style={{ maxWidth: '100%' }} />
                        </Row>
                    )}
                    {mode === 'uploadsuccess' && (
                        <>
                            <Row>
                                <img src={imageUrl} alt="Generated Meme" style={{ maxWidth: '100%' }} />
                            </Row>
                            <Row className="mt-3">
                                <p>Your image has been uploaded successfully!</p>
                                <p>URL: <a href={uploadUrl} target="_blank" rel="noopener noreferrer">{uploadUrl}</a></p>
                            </Row>
                        </>
                    )}

                    {mode === 'discordurlsuccess' && (
                        <>
                            <Row>
                                <img src={imageUrl} alt="Generated Meme" style={{ maxWidth: '100%' }} />
                            </Row>
                            <Row className="mt-3">
                                <p>Discord share link:</p>                                
                                <InputGroup className="mb-3">
                                <Form.Control
                                    type="text"
                                    value={discordUrl}
                                    readOnly                                    
                                />
                                <Button 
                                    variant={discordUrlCopied ? "success" : "outline-secondary"}
                                    onClick={copyDiscordUrlToClipboard}
                                >
                                    {discordUrlCopied ? 
                                        <><FontAwesomeIcon icon={faCheckCircle} className="me-1" /> Copied</> : 
                                        <><FontAwesomeIcon icon={faClipboard} className="me-1" /> Copy</>
                                    }
                                </Button>
                            </InputGroup>
                            </Row>
                        </>
                    )}

                    {mode === 'uploadfailed' && (
                        <p>Error: {errorMessage}</p>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ 
                    marginRight: 'auto', 
                    opacity: copyMessage ? 1 : 0,
                    transition: 'opacity 0.3s ease-in-out'
                }}>
                    {copyMessage}
                </div>
                <Button variant="secondary" onClick={handleCloseModal} disabled={isWorking}>
                    <FontAwesomeIcon icon={faClose} /> Close
                </Button>
                {(mode === 'editing' || mode === 'error') && (
                    <Button 
                        variant="primary" 
                        onClick={handleMakeWithOverlay} 
                        disabled={isWorking || !validateInputs()}
                        title={validateInputs() ? "Generate meme" : "Please enter at least one text or overlay URL"}
                    >
                        <FontAwesomeIcon icon={faPaintbrush} /> Make
                    </Button>
                )}
                {mode === 'done' && (
                    <>
                        <Button variant="primary" onClick={() => handleTextChange(null, null, 'editing')} disabled={isWorking}>
                            <FontAwesomeIcon icon={faEdit} /> Edit
                        </Button>
                        {isAnimated ? (
                            <Button 
                                variant="primary" 
                                onClick={() => handleSave(imageUrl)} 
                                disabled={isWorking}
                                title="Save animated image to your device"
                            >
                                <FontAwesomeIcon icon={faDownload} /> Save
                            </Button>
                        ) : (
                            <Button 
                                variant="primary" 
                                onClick={() => handleCopy(imageUrl)} 
                                disabled={isWorking}
                                title="Copy image to clipboard"
                            >
                                <FontAwesomeIcon icon={faCopy} /> Copy
                            </Button>
                        )}
                         {/* Only show Discord button if no overlays were used */}
                         {!overlaysUsed && (
                            <Button 
                                variant="primary"
                                onClick={handleCopyDiscordUrl} 
                                disabled={isWorking}
                                title="Copy link formatted for Discord embedding"
                                className="ms-2"
                            >
                                <FontAwesomeIcon icon={discordUrlCopied ? faCheck : faCopy} />  {discordUrlCopied ? 'Copied!' : 'Discord Link'}
                            </Button>
                        )}

                        <Button 
                            variant="primary" 
                            onClick={handleUpload} 
                            disabled={isWorking}
                            title="Upload and get a shareable link"
                        >
                            <FontAwesomeIcon icon={faUpload} /> Upload
                        </Button>

                        
                    </>
                )}
                {mode === 'uploadsuccess' && (
                    <>
                        <Button variant="primary" onClick={() => handleCopy(uploadUrl)} disabled={isWorking}>
                            <FontAwesomeIcon icon={faCopy} /> Copy Link
                        </Button>
                        
                       
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default MemeModal;
