import React, { Component } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import RemoveBackgroundModal from './RemoveBackgroundModal';
import MemeFacePicker from './MemeFacePicker'; // Import the new component

class RemoveBackground extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showMemeFacePicker: false,
            returnedUrl: '', // For testing the meme face picker
            selectedMemeFace: null // Store the entire meme face object
        };
    }

    toggleModal = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    };

    toggleMemeFacePicker = () => {
        this.setState(prevState => ({
            showMemeFacePicker: !prevState.showMemeFacePicker
        }));
    };

    // Handler for when a meme face is selected
    handleMemeFacePicked = (memeFace) => {
        this.setState({ 
            returnedUrl: memeFace.objectUrl,
            selectedMemeFace: memeFace, // Store the entire meme face object
            showMemeFacePicker: false // Close the modal after selection
        });
    };

    clearSelectedMemeFace = () => {
        this.setState({
            returnedUrl: '',
            selectedMemeFace: null
        });
    };

    render() {
        const { selectedMemeFace } = this.state;
        
        return (
            <Container className="mt-4">
                <Row className="justify-content-center">
                    <Col md={8}>
                        <h2 className="text-center mb-4">Background Removal Tool</h2>
                        <p className="text-center">
                            Upload an image and remove its background with one click.
                        </p>
                        <div className="text-center mt-4">
                            <Button 
                                variant="primary" 
                                size="lg" 
                                onClick={this.toggleModal}
                                className="mb-4"
                            >
                                Remove Background
                            </Button>
                        </div>

                        {/* Test area for Meme Face Picker */}
                        <div className="mt-5 border p-4 rounded">
                            <h3>Test Meme Face Picker</h3>
                            <div className="mb-3">
                                <MemeFacePicker
                                    label="Returned URL"
                                    url={this.state.returnedUrl}
                                    selectedMemeFace={selectedMemeFace}
                                    onMemeFaceSelect={this.handleMemeFacePicked}
                                    onClear={this.clearSelectedMemeFace}
                                    onChange={(e) => this.setState({ returnedUrl: e.target.value })}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>

                {/* Modal for background removal */}
                <RemoveBackgroundModal 
                    show={this.state.showModal} 
                    onHide={this.toggleModal} 
                />

            </Container>
        );
    }
}

export default RemoveBackground;