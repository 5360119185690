import React, {Component} from "react";
import {Navbar, Nav, Container} from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { isShitpostDomain, isUserAdmin } from './Utils';

class AppHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
    }
    
    // Toggle the navbar
    setExpanded = (expanded) => {
        this.setState({ expanded });
    }
    
    // Handle link click to close the navbar
    handleNavLinkClick = () => {
        this.setExpanded(false);
    }

    getDomainSpecificContent() {
        if (isShitpostDomain) {
            return {
                src: "/images/poop-32x32.png",
                alt: "poop icon",
                text: " Shitpost",
                bot: "Shitbot"
            };
        }
        return {
            src: "/images/fren-32x32.png",
            alt: "fren logo",
            text: " Frenpost",
            bot: "Frenbot"
        };
    }

    render() {
        const content = this.getDomainSpecificContent();

        return (
            <Navbar 
                collapseOnSelect 
                expand="lg" 
                className="bg-body-tertiary" 
                sticky="top"
                expanded={this.state.expanded}
                onToggle={this.setExpanded}
            >
                <Container>
                    <Navbar.Brand as={Link} to="/stash" onClick={this.handleNavLinkClick}>
                        <img 
                            src={content.src} 
                            alt={content.alt} 
                            width='20' 
                            height='20'
                        />
                        <span>{content.text}</span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
                    <Navbar.Collapse>
                        <Nav className="me-auto">                                                       
                            <Nav.Link as={NavLink} to="/stash" onClick={this.handleNavLinkClick} className={({isActive}) => isActive ? 'nav-link active' : 'nav-link'}>
                                Stash
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/memes" onClick={this.handleNavLinkClick} className={({isActive}) => isActive ? 'nav-link active' : 'nav-link'}>
                                Memes
                            </Nav.Link>
                            <Nav.Link 
                                as={NavLink} 
                                to="/meme" 
                                className={({isActive}) => isActive ? 'nav-link active' : 'nav-link'}
                                hidden={!isUserAdmin}
                                onClick={this.handleNavLinkClick}
                            >
                                Meme Control
                            </Nav.Link>
                            {/* <Nav.Link 
                                as={NavLink} 
                                to="/meme_overlay" 
                                className={({isActive}) => isActive ? 'nav-link active' : 'nav-link'}
                                hidden={!isUserAdmin}
                            >
                                Meme Overlay
                            </Nav.Link> */}
                            <Nav.Link as={NavLink} to="/apps" onClick={this.handleNavLinkClick} className={({isActive}) => isActive ? 'nav-link active' : 'nav-link'}>
                                Apps & Clients
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/bot" onClick={this.handleNavLinkClick} className={({isActive}) => isActive ? 'nav-link active' : 'nav-link'}>
                                {content.bot}
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/about" onClick={this.handleNavLinkClick} className={({isActive}) => isActive ? 'nav-link active' : 'nav-link'}>
                                About
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/remove-background" onClick={this.handleNavLinkClick} className={({isActive}) => isActive ? 'nav-link active' : 'nav-link'}>
                                Remove Background
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/user" onClick={this.handleNavLinkClick} className={({isActive}) => isActive ? 'nav-link active' : 'nav-link'}>
                                My Profile
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}

export default AppHeader
