import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal, Button, Form, InputGroup, Spinner, Image, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faPaste, faCopy, faUpload, faClose, faCheck, faCrop, faUndo } from '@fortawesome/free-solid-svg-icons';
import { copyToClipboard, uploadImageBlobToService, getApiBase, getUserTags } from './Utils.js';
import TagPicker from './TagPicker';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const RemoveBackgroundModal = ({ show, onHide }) => {
    const [imageUrl, setImageUrl] = useState('');
    const [resultImageUrl, setResultImageUrl] = useState('');
    const [isWorking, setIsWorking] = useState(false);
    const [mode, setMode] = useState('input'); // input, working, result, error
    const [errorMessage, setErrorMessage] = useState('');
    const [copyMessage, setCopyMessage] = useState('');
    const [urlCopied, setUrlCopied] = useState(false);
    const [imageName, setImageName] = useState('');
    const [tags, setTags] = useState([]);
    const [availableTags, setAvailableTags] = useState([]);
    
    // Crop related states
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState(null);
    const [isCropping, setIsCropping] = useState(false);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    
    const inputRef = useRef(null);
    const imageRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const croppedUrlRef = useRef(null);
    const cropTimeoutRef = useRef(null);

    // Add these near the top of your component with other state variables
    const [isUploaded, setIsUploaded] = useState(false);
    const [uploadedUrl, setUploadedUrl] = useState('');

    // Focus on the input field when the modal is shown
    useEffect(() => {
        if (show && inputRef.current) {
            inputRef.current.focus();
        }
        
        // Load available tags when modal is shown
        if (show) {
            try {
                const userTags = getUserTags() || [];
                setAvailableTags(userTags);
            } catch (error) {
                console.error("Error loading tags:", error);
            }
        }
    }, [show]);

    // Reset the state when modal is closed
    useEffect(() => {
        if (!show) {
            setTimeout(() => {
                setImageUrl('');
                setResultImageUrl('');
                setMode('input');
                setErrorMessage('');
                setCopyMessage('');
                setUrlCopied(false);
                setImageName('');
                setTags([]);
                setCrop(undefined);
                setCompletedCrop(null);
                setIsCropping(false);
                setCroppedImageUrl(null);
                
                // Clean up any existing blob URLs
                if (croppedUrlRef.current) {
                    URL.revokeObjectURL(croppedUrlRef.current);
                    croppedUrlRef.current = null;
                }
                
                // Clear any pending timeouts
                if (cropTimeoutRef.current) {
                    clearTimeout(cropTimeoutRef.current);
                    cropTimeoutRef.current = null;
                }
            }, 300);
        }
    }, [show]);

    // Generate the cropped image when completedCrop changes
    const generateCropPreview = useCallback((crop) => {
        if (!crop || !imageRef.current || !previewCanvasRef.current) return;
        
        const image = imageRef.current;
        const canvas = previewCanvasRef.current;
        
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        
        const pixelRatio = window.devicePixelRatio;
        
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
        
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
        
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
        
        // Revoke previous blob URL if it exists
        if (croppedUrlRef.current) {
            URL.revokeObjectURL(croppedUrlRef.current);
            croppedUrlRef.current = null;
        }
        
        // Convert canvas to blob URL
        canvas.toBlob(blob => {
            if (!blob) return;
            
            const newCroppedUrl = URL.createObjectURL(blob);
            croppedUrlRef.current = newCroppedUrl;
            setCroppedImageUrl(newCroppedUrl);
        }, 'image/png', 1);
    }, []);

    // Effect to handle cropping with debounce
    useEffect(() => {
        // Skip effect if no completed crop
        if (!completedCrop || !imageRef.current || !previewCanvasRef.current) {
            return;
        }
        
        // Clear any existing timeout
        if (cropTimeoutRef.current) {
            clearTimeout(cropTimeoutRef.current);
        }
        
        // Use a much longer debounce in development mode to prevent excessive updates
        const debounceTime = process.env.NODE_ENV === 'development' ? 1000 : 300;
        
        cropTimeoutRef.current = setTimeout(() => {
            // Use the generateCropPreview function instead of duplicating the logic here
            generateCropPreview(completedCrop);
        }, debounceTime);
        
        return () => {
            if (cropTimeoutRef.current) {
                clearTimeout(cropTimeoutRef.current);
            }
        };
    }, [completedCrop, isCropping, generateCropPreview]); // Add generateCropPreview as a dependency
    
    // Clean up any blob URLs when component unmounts
    useEffect(() => {
        return () => {
            if (croppedUrlRef.current) {
                URL.revokeObjectURL(croppedUrlRef.current);
            }
            if (cropTimeoutRef.current) {
                clearTimeout(cropTimeoutRef.current);
            }
        };
    }, []);

    const handleRemoveBackground = async () => {
        if (!imageUrl) {
            setErrorMessage('Please enter an image URL or paste an image');
            return;
        }

        setIsWorking(true);
        setMode('working');

        try {
            const response = await fetch(`${getApiBase()}/face_crop`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',                    
                },
                body: JSON.stringify({
                    img_url: imageUrl,
                    apikey: localStorage.getItem("apiKey")
                })
            });

            if (!response.ok) {
                throw new Error(`Failed to remove background: ${response.statusText}`);
            }

            const blob = await response.blob();
            const resultUrl = URL.createObjectURL(blob);
            setResultImageUrl(resultUrl);
            
            // Don't set tags here to avoid rendering loop
            setMode('result');
        } catch (error) {
            console.error("Error removing background:", error);
            setErrorMessage(error.message || 'Failed to remove background');
            setMode('error');
        } finally {
            setIsWorking(false);
        }
    };

    const handleCopy = async () => {
        // Use the cropped image if available and in cropping mode
        const imageToCopy = (isCropping && croppedImageUrl) ? croppedImageUrl : resultImageUrl;
        if (!imageToCopy) return;

        try {
            const success = await copyToClipboard(imageToCopy);
            
            if (success) {
                setCopyMessage('Image copied to clipboard');
                setTimeout(() => setCopyMessage(''), 3000);
            } else {
                setCopyMessage('Failed to copy image');
                setTimeout(() => setCopyMessage(''), 3000);
            }
        } catch (error) {
            console.error('Error copying image:', error);
            setCopyMessage('Failed to copy image');
            setTimeout(() => setCopyMessage(''), 3000);
        }
    };

    const handleUpload = async () => {
        // Use the cropped image if available and in cropping mode
        const imageToUpload = (isCropping && croppedImageUrl) ? croppedImageUrl : resultImageUrl;
        if (!imageToUpload) return;
        
        setIsWorking(true);
        try {
            // Include name and tags in the upload - make sure tags is an array
            const tagsToSend = Array.isArray(tags) ? tags : [];
            
            const result = await uploadImageBlobToService(imageToUpload, {
                name: imageName,
                tags: tagsToSend
            });
            
            if (result.success) {
                // Keep the original result image URL
                const uploadedUrl = result.url;
                setCopyMessage('Image uploaded successfully');
                
                // Set uploaded state to true
                setIsUploaded(true);
                // Store the uploaded URL 
                setUploadedUrl(uploadedUrl);
                
                // Reset URL copied state
                setUrlCopied(false);
                
                // Set a timeout to clear the message
                setTimeout(() => setCopyMessage(''), 3000);
                
                // REMOVE THIS LINE - Don't open in a new tab
                // window.open(uploadedUrl, '_blank');
            } else {
                setErrorMessage(`Failed to upload: ${result.error}`);
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            setErrorMessage(`Failed to upload: ${error.message}`);
        } finally {
            setIsWorking(false);
        }
    };

    const handleCopyUrl = async () => {
        if (!resultImageUrl) return;

        try {
            const success = await copyToClipboard(resultImageUrl);
            
            if (success) {
                setUrlCopied(true);
                setTimeout(() => setUrlCopied(false), 3000);
            }
        } catch (error) {
            console.error('Error copying URL:', error);
        }
    };

    const handlePaste = async (e) => {
        const items = e.clipboardData?.items;
        if (!items) return;

        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                e.preventDefault();
                
                // Get image from clipboard
                const blob = items[i].getAsFile();
                const blobUrl = URL.createObjectURL(blob);
                
                setIsWorking(true);
                try {
                    // Upload the image
                    const uploadResult = await uploadImageBlobToService(blobUrl);
                    
                    // Update the field with the uploaded URL if successful
                    if (uploadResult.success) {
                        setImageUrl(uploadResult.url);
                    } else {
                        setErrorMessage('Failed to upload pasted image: ' + uploadResult.error);
                    }
                } catch (error) {
                    console.error('Error handling pasted image:', error);
                    setErrorMessage('Failed to process pasted image');
                } finally {
                    URL.revokeObjectURL(blobUrl);
                    setIsWorking(false);
                }
                
                // Only process the first image
                break;
            }
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleRemoveBackground();
        }
    };

    // Handle tag toggle (add or remove)
    const handleTagToggle = (tag) => {
        setTags(currentTags => {
            if (currentTags.includes(tag)) {
                return currentTags.filter(t => t !== tag);
            } else {
                return [...currentTags, tag];
            }
        });
    };

    // Toggle cropping mode
    const toggleCropMode = () => {
        // If turning off cropping mode, clean up first
        if (isCropping) {
            // Clean up cropped image URL
            if (croppedUrlRef.current) {
                URL.revokeObjectURL(croppedUrlRef.current);
                croppedUrlRef.current = null;
            }
            setCroppedImageUrl(null);
            setCompletedCrop(null);
            setCrop(undefined);
        } else {
            // Set initial crop dimensions
            setCrop({
                unit: '%',
                width: 50,
                height: 50,
                x: 25,
                y: 25
            });
        }
        
        // Toggle the cropping mode last to prevent unnecessary renders
        setIsCropping(!isCropping);
    };

    return (
        <Modal 
            show={show} 
            onHide={onHide}
            size="lg"
            centered
            backdrop="static"
            keyboard={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>Remove Image Background</Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
                {mode === 'input' && (
                    <>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                <FontAwesomeIcon icon={faImage} className="me-2" />
                                Image URL
                            </Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter image URL or paste an image"
                                    value={imageUrl}
                                    onChange={(e) => setImageUrl(e.target.value)}
                                    onPaste={handlePaste}
                                    onKeyPress={handleKeyPress}
                                    ref={inputRef}
                                />
                                <Button 
                                    variant="outline-secondary" 
                                    title="Paste from clipboard"
                                >
                                    <FontAwesomeIcon icon={faPaste} />
                                </Button>
                            </InputGroup>
                            <Form.Text className="text-muted">
                                Paste an image (Ctrl+V) or enter a URL to an image
                            </Form.Text>
                        </Form.Group>
                        
                        {errorMessage && (
                            <div className="alert alert-danger">{errorMessage}</div>
                        )}
                        
                        {imageUrl && (
                            <div className="mt-3 text-center">
                                <p>Preview:</p>
                                <Image 
                                    src={imageUrl} 
                                    alt="Preview" 
                                    style={{ maxWidth: '100%', maxHeight: '300px' }} 
                                />
                            </div>
                        )}
                    </>
                )}

                {mode === 'working' && (
                    <div className="text-center p-5">
                        <Spinner animation="border" role="status" variant="primary" />
                        <p className="mt-3">Processing your image...</p>
                        <p className="text-muted small">This may take a few seconds</p>
                    </div>
                )}

                {mode === 'result' && (
                    <>
                        <div className="text-center">
                            <h4 className="mb-3">Background Removed!</h4>
                            
                            {/* Only show crop button if not yet uploaded */}
                            {!isUploaded && (
                                <div className="mb-3">
                                    <Button 
                                        variant={isCropping ? "primary" : "outline-primary"} 
                                        onClick={toggleCropMode}
                                        className="mb-2"
                                    >
                                        {isCropping ? (
                                            <><FontAwesomeIcon icon={faUndo} /> Cancel Crop</>
                                        ) : (
                                            <><FontAwesomeIcon icon={faCrop} /> Crop Image</>
                                        )}
                                    </Button>
                                    
                                    {isCropping && (
                                        <div className="small text-muted">
                                            Click and drag on the image to select crop area
                                        </div>
                                    )}
                                </div>
                            )}
                            
                            {/* Image display section */}
                            {!isUploaded ? (
                                // Original pre-upload view with side-by-side images
                                <Row>
                                    <Col md={isCropping ? 6 : 6} className="mb-3">
                                        <h5>Original</h5>
                                        <Image 
                                            src={imageUrl} 
                                            alt="Original" 
                                            thumbnail
                                            style={{ maxHeight: '200px' }} 
                                        />
                                    </Col>
                                    <Col md={isCropping ? 6 : 6} className="mb-3">
                                        <h5>Result</h5>
                                        {isCropping ? (
                                            <div className="crop-container">
                                                <ReactCrop
                                                    crop={crop}
                                                    onChange={(c) => setCrop(c)}
                                                    onComplete={setCompletedCrop}
                                                >
                                                    <img
                                                        ref={imageRef}
                                                        src={resultImageUrl}
                                                        alt="Crop me"
                                                        style={{ maxWidth: '100%', maxHeight: '300px' }}
                                                    />
                                                </ReactCrop>
                                                
                                                {/* Hidden canvas for creating the cropped image */}
                                                <div style={{ display: 'none' }}>
                                                    <canvas
                                                        ref={previewCanvasRef}
                                                        style={{
                                                            width: completedCrop?.width ?? 0,
                                                            height: completedCrop?.height ?? 0
                                                        }}
                                                    />
                                                </div>
                                                
                                                {/* If we have a cropped image, show it for preview */}
                                                {croppedImageUrl && (
                                                    <div className="mt-3">
                                                        <h6>Cropped Preview</h6>
                                                        <img
                                                            src={croppedImageUrl}
                                                            alt="Cropped preview"
                                                            style={{ maxWidth: '100%', maxHeight: '150px' }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <Image 
                                                src={resultImageUrl} 
                                                alt="Result" 
                                                thumbnail
                                                style={{ maxHeight: '200px' }} 
                                            />
                                        )}
                                    </Col>
                                </Row>
                            ) : (
                                // Post-upload view with just the uploaded image
                                <div className="mt-4 mb-4 text-center">
                                    <h5>Uploaded Image</h5>
                                    <div className="mt-3">
                                        <img 
                                            src={uploadedUrl} 
                                            alt="Uploaded Result" 
                                            style={{ maxWidth: '100%', maxHeight: '300px' }}
                                            className="img-thumbnail" 
                                        />
                                    </div>
                                </div>
                            )}
                            
                            {copyMessage && (
                                <div className="alert alert-success mt-3">
                                    {copyMessage}
                                </div>
                            )}
                            
                            {/* Form section OR uploaded URL section */}
                            {!isUploaded ? (
                                // Pre-upload form for name and tags
                                <Form className="mt-4">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Image Name (optional)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Give your image a name"
                                            value={imageName}
                                            onChange={(e) => setImageName(e.target.value)}
                                        />
                                    </Form.Group>
                                    
                                    <Form.Group className="mb-3">
                                        <Form.Label>Tags (optional)</Form.Label>
                                        <TagPicker 
                                            availableTags={availableTags}
                                            selectedTags={tags}
                                            onTagToggle={handleTagToggle}
                                            placeholder="Add tags to your image..."
                                        />
                                    </Form.Group>
                                    
                                    <div className="alert alert-warning">
                                        <strong>Note:</strong> Unnamed and untagged images are eligible for courtesy flush.
                                    </div>
                                    
                                    <InputGroup className="mt-3">
                                        <Form.Control
                                            type="text"
                                            value={resultImageUrl}
                                            readOnly
                                        />
                                        <Button 
                                            variant={urlCopied ? "success" : "outline-secondary"}
                                            onClick={handleCopyUrl}
                                        >
                                            {urlCopied ? 
                                                <><FontAwesomeIcon icon={faCheck} /> Copied</> : 
                                                <><FontAwesomeIcon icon={faCopy} /> Copy URL</>
                                            }
                                        </Button>
                                    </InputGroup>
                                </Form>
                            ) : (
                                // Post-upload URL section
                                <div className="mt-4">
                                    <h5>Image URL</h5>
                                    <InputGroup>
                                        <Form.Control
                                            type="text"
                                            value={uploadedUrl}
                                            readOnly
                                        />
                                        <Button 
                                            variant={urlCopied ? "success" : "outline-secondary"}
                                            onClick={() => {
                                                copyToClipboard(uploadedUrl).then(success => {
                                                    if (success) {
                                                        setUrlCopied(true);
                                                        setTimeout(() => setUrlCopied(false), 3000);
                                                    }
                                                });
                                            }}
                                        >
                                            {urlCopied ? 
                                                <><FontAwesomeIcon icon={faCheck} /> Copied</> : 
                                                <><FontAwesomeIcon icon={faCopy} /> Copy URL</>
                                            }
                                        </Button>
                                    </InputGroup>
                                    <div className="mt-3 text-center">
                                        <Button 
                                            variant="outline-primary" 
                                            onClick={() => {
                                                // Reset upload state to allow editing/uploading again
                                                setIsUploaded(false);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faUndo} className="me-1" /> 
                                            Edit & Upload Again
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}

                {mode === 'error' && (
                    <div className="alert alert-danger">
                        <p>Error: {errorMessage}</p>
                    </div>
                )}
            </Modal.Body>

            <Modal.Footer>
                {mode === 'input' && (
                    <>
                        <Button variant="secondary" onClick={onHide}>
                            <FontAwesomeIcon icon={faClose} className="me-1" /> Cancel
                        </Button>
                        <Button 
                            variant="primary" 
                            onClick={handleRemoveBackground}
                            disabled={isWorking || !imageUrl}
                        >
                            {isWorking ? 
                                <>
                                    <Spinner as="span" animation="border" size="sm" className="me-1" />
                                    Processing...
                                </> : 
                                'Remove Background'
                            }
                        </Button>
                    </>
                )}

                {mode === 'result' && (
                    <>
                        <Button variant="secondary" onClick={onHide}>
                            Close
                        </Button>
                        
                        {!isUploaded ? (
                            // Pre-upload buttons
                            <>
                                <Button 
                                    variant="primary" 
                                    onClick={handleCopy}
                                    disabled={isWorking}
                                >
                                    <FontAwesomeIcon icon={faCopy} className="me-1" /> 
                                    Copy {isCropping && croppedImageUrl ? 'Cropped' : ''}
                                </Button>
                                <Button 
                                    variant="success" 
                                    onClick={handleUpload}
                                    disabled={isWorking || (isCropping && !croppedImageUrl)}
                                >
                                    <FontAwesomeIcon icon={faUpload} className="me-1" /> 
                                    Upload {isCropping && croppedImageUrl ? 'Cropped' : ''}
                                    {isWorking && <Spinner as="span" animation="border" size="sm" className="ms-2" />}
                                </Button>
                            </>
                        ) : (
                            // Post-upload buttons
                            <Button 
                                variant="primary" 
                                onClick={() => copyToClipboard(uploadedUrl)}
                            >
                                <FontAwesomeIcon icon={faCopy} className="me-1" /> Copy URL
                            </Button>
                        )}
                    </>
                )}

                {mode === 'error' && (
                    <>
                        <Button variant="secondary" onClick={onHide}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => setMode('input')}>
                            Try Again
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default RemoveBackgroundModal;