import React, { Component, createRef } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { getApiPath } from './Utils';
import MemeFace from './MemeFace';
import './MemeFacePickerModal.css';

// Static cache for meme faces data
const memeFacesCache = {
    data: null,
    timestamp: null,
    expiryTime: 5 * 60 * 1000 // 5 minutes in milliseconds
};

class MemeFacePickerModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: '',
            memeFaces: [],
            filteredFaces: [],
            loading: false,
            error: null,
            currentPage: 1,
            itemsPerPage: 24,
            hasMore: true,
            usingCachedData: false
        };
        
        this.sentinelRef = createRef();
        this.observer = null;
        this.containerRef = createRef();
    }

    componentDidMount() {
        // Set up intersection observer when component mounts
        this.setupInfiniteScroll();
    }

    componentDidUpdate(prevProps) {
        // Fetch faces when modal opens
        if (this.props.show && !prevProps.show) {
            this.loadMemeFaces();
        }
        
        // Reset when modal closes
        if (!this.props.show && prevProps.show) {
            this.setState({
                searchQuery: '',
                filteredFaces: [],
                currentPage: 1,
                hasMore: true
            });
        }
    }

    componentWillUnmount() {
        // Clean up observer
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    setupInfiniteScroll = () => {
        this.observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && !this.state.loading && this.state.hasMore) {
                this.loadMoreItems();
            }
        }, { threshold: 0.1 });
        
        setTimeout(() => {
            if (this.sentinelRef.current && this.props.show) {
                this.observer.observe(this.sentinelRef.current);
            }
        }, 100);
    }

    // Check if cache is valid
    isCacheValid = () => {
        return (
            memeFacesCache.data && 
            memeFacesCache.timestamp && 
            (new Date().getTime() - memeFacesCache.timestamp) < memeFacesCache.expiryTime
        );
    }

    // Main function to load meme faces (either from cache or API)
    loadMemeFaces = async () => {
        if (this.isCacheValid()) {
            console.log("Using cached meme faces data");
            this.setState({
                memeFaces: memeFacesCache.data,
                filteredFaces: memeFacesCache.data,
                loading: false,
                usingCachedData: true
            });
            return;
        }
        
        await this.fetchMemeFaces();
    }

    // Fetch data from API
    fetchMemeFaces = async () => {
        this.setState({ loading: true, error: null });
        
        const apiKey = localStorage.getItem('apiKey');
        if (!apiKey) {
            this.setState({ 
                loading: false, 
                error: 'No API key found. Please log in.' 
            });
            return;
        }
        
        try {
            const url = getApiPath('meme_faces', apiKey, '');
            const response = await fetch(url);
            
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            
            const data = await response.json();
            
            // Update cache
            memeFacesCache.data = data;
            memeFacesCache.timestamp = new Date().getTime();
            
            this.setState({
                memeFaces: data,
                filteredFaces: data,
                loading: false,
                usingCachedData: false
            });
        } catch (error) {
            console.error('Error fetching meme faces:', error);
            this.setState({
                loading: false,
                error: `Failed to load meme faces: ${error.message}`
            });
        }
    }

    // Force refresh data from API even if cache exists
    refreshData = () => {
        this.fetchMemeFaces();
    }

    handleSearchChange = (e) => {
        const query = e.target.value;
        this.setState({ 
            searchQuery: query,
            currentPage: 1 // Reset to first page when searching
        }, this.filterMemeFaces);
    }

    handleSearchSubmit = (e) => {
        e.preventDefault();
        this.filterMemeFaces();
    }

    filterMemeFaces = () => {
        const { searchQuery, memeFaces } = this.state;
        
        if (!searchQuery.trim()) {
            this.setState({
                filteredFaces: memeFaces,
                hasMore: true
            });
            return;
        }
        
        const query = searchQuery.toLowerCase();
        const filtered = memeFaces.filter(face => 
            face.name.toLowerCase().includes(query)
        );
        
        this.setState({
            filteredFaces: filtered,
            hasMore: true
        });
    }

    handleClearSearch = () => {
        this.setState({
            searchQuery: '',
            filteredFaces: this.state.memeFaces,
            currentPage: 1,
            hasMore: true
        });
    }

    loadMoreItems = () => {
        const { currentPage, itemsPerPage, filteredFaces } = this.state;
        const totalPages = Math.ceil(filteredFaces.length / itemsPerPage);
        
        if (currentPage < totalPages) {
            this.setState(prevState => ({
                currentPage: prevState.currentPage + 1,
                hasMore: prevState.currentPage + 1 < totalPages
            }));
        } else {
            this.setState({ hasMore: false });
        }
    }

    handleMemeFaceClick = (memeFace) => {
        if (this.props.onMemeFaceSelect) {
            this.props.onMemeFaceSelect(memeFace);
        }
    }

    render() {
        const { show, onHide } = this.props;
        const { 
            searchQuery, filteredFaces, loading, error, 
            currentPage, itemsPerPage, usingCachedData
        } = this.state;
        
        // Get the faces to display based on current page
        const displayedFaces = filteredFaces.slice(0, currentPage * itemsPerPage);
        
        return (
            <Modal 
                show={show} 
                onHide={onHide}
                size="lg"
                centered
                backdrop="static"
                className="meme-face-picker-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Pick a meme face
                        {usingCachedData && (
                            <Button 
                                variant="link" 
                                size="sm" 
                                className="ms-2"
                                onClick={this.refreshData}
                                title="Refresh data"
                            >
                                <FontAwesomeIcon icon="sync" spin={loading} />
                            </Button>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSearchSubmit} className="mb-4">
                        <InputGroup>
                            <Form.Control
                                type="text"
                                placeholder="Search by name..."
                                value={searchQuery}
                                onChange={this.handleSearchChange}
                                aria-label="Search meme faces"
                            />
                            {searchQuery && (
                                <Button 
                                    variant="outline-secondary" 
                                    onClick={this.handleClearSearch}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                            )}
                            <Button 
                                variant="primary" 
                                type="submit"
                            >
                                <FontAwesomeIcon icon={faSearch} />
                            </Button>
                        </InputGroup>
                    </Form>
                    
                    {loading && !displayedFaces.length ? (
                        <div className="text-center py-5">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : error ? (
                        <div className="text-center text-danger py-5">
                            {error}
                        </div>
                    ) : displayedFaces.length === 0 ? (
                        <div className="text-center py-5">
                            No meme faces found matching "{searchQuery}"
                        </div>
                    ) : (
                        <div ref={this.containerRef} className="meme-faces-container">
                            <Row xs={2} sm={3} md={4} lg={6} className="g-3">
                                {displayedFaces.map((face, index) => (
                                    <Col key={`${face.name}-${index}`}>
                                        <MemeFace 
                                            memeFace={face} 
                                            onClick={this.handleMemeFaceClick}
                                        />
                                    </Col>
                                ))}
                            </Row>
                            
                            {/* Loading indicator at bottom */}
                            {loading && (
                                <div className="text-center py-3">
                                    <Spinner animation="border" size="sm" role="status">
                                        <span className="visually-hidden">Loading more...</span>
                                    </Spinner>
                                </div>
                            )}
                            
                            {/* Sentinel element for infinite scroll */}
                            <div 
                                ref={this.sentinelRef} 
                                style={{ height: '10px', width: '100%' }} 
                            />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default MemeFacePickerModal;