export const isShitpostDomain = window.location.hostname.includes('shitpost.sh');

/**
 * Retrieves user tags from localStorage.
 * @returns {Array} An array of user tags or an empty array if not found.
 */
export function getUserTags() {
  const userString = localStorage.getItem('user');
  if (!userString) {
    return [];
  }
  
  try {
    const userData = JSON.parse(userString);
    return userData.tags || [];
  } catch (error) {
    console.error('Error parsing user data:', error);
    return [];
  }
}


export function setUserStorageItem(apiKey) {

  // console.log("setting user storage item with apikey: " + apiKey);
  if (apiKey === null)
      return;

    

  const url = getApiPath('user', apiKey, '');
      
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          localStorage.setItem('user', JSON.stringify(data));
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
          // alert('Failed to validate API key. Please check your settings.');
          localStorage.removeItem('user');
          localStorage.removeItem('apiKey');
          window.location.href = '/login';
        });
}

// Add method to check admin status
function checkAdminStatus() {

  console.log("checking admin status");
  if (localStorage.getItem('apiKey') === null && ! window.location.href.endsWith('/login') && ! window.location.href.endsWith('/signup')) 
  {
    window.location.href = '/login';
    return;
  }

  const userString = localStorage.getItem('user');
  ///try to set it 
  const apiKey = localStorage.getItem('apiKey');

  setUserStorageItem(apiKey);

  if (!userString) {
    return false;
  }
  const user = JSON.parse(userString);
  return user.isAdmin === true;
};

export const isUserAdmin = checkAdminStatus();

function getApiBase () {

  var domain = window.location.host.replace("www-dev.", "");            
  domain = domain.replace("www.", "");
  
  const apiHost = window.location.host.indexOf("dev") > 0 ? "api-dev" : "api";            
  var apipath = window.location.protocol+ "//" + apiHost + "." + domain;

  var override = localStorage.getItem("apiOverride")

  if (override !== null)  
      apipath = override;
  
  return apipath;
}

/**
 * Constructs the API path based on the provided mode, API key, and version.
 *
 * @param {string} mode - The mode of the API (e.g., 'test', 'prod').
 * @param {string} apikey - The API key to be used for authentication.
 * @param {string} v - wut
 * @returns {string} The constructed API path.
 */
function getApiPath (mode, apikey, v)
{
    var domain = window.location.host.replace("www-dev.", "");            
    domain = domain.replace("www.", "");
    
    const apiHost = window.location.host.indexOf("dev") > 0 ? "api-dev" : "api";            
    var apipath = window.location.protocol+ "//" + apiHost + "." + domain + "/" + apikey + "/" + mode;
//////////
    

    var override = localStorage.getItem("apiOverride")

    if (override !== null)
    {
        // apipath = "https://" + domain + "/" + apikey + "/" + mode;
        apipath = override + "/" + apikey + "/" + mode;
        //////////////////
    }
    
    if( v !== "" )
        apipath +="/" + v;

    return apipath;
}

function getTogglePostPath(postid, tag)
{
    var apiPath = getApiPath("post", localStorage.getItem("apiKey"), postid) + "/tag/" + tag;
    return apiPath;
}


/**
 * https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
 * Format bytes as human-readable text.
 * 
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use 
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * 
 * @return Formatted string.
 */
function humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;
  
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
  
    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
  
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  
  
    return bytes.toFixed(dp) + ' ' + units[u];
  }
  


async function copyImageBlobToClipboard(blobUrl) {
  try {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    const clipboardItem = new ClipboardItem({ 'image/png': blob });
    await navigator.clipboard.write([clipboardItem]);
    return true;
  } catch (error) {
    console.error('Error copying to clipboard:', error);
    return false;
  }
}

async function uploadImageBlobToService(blobUrl, metadata = {}) {
  try {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    
    const formData = new FormData();
    formData.append('file', blob);
    
    // Add name if provided and not empty
    if (metadata.name && metadata.name.trim() !== '') {
      formData.append('name', metadata.name.trim());
    }
    
    // Add tags if provided and not an empty array
    if (metadata.tags && Array.isArray(metadata.tags) && metadata.tags.length > 0) {
      formData.append('tags', JSON.stringify(metadata.tags));
    }
    
    const uploadResponse = await fetch(`${getApiBase()}/${localStorage.getItem("apiKey")}/upload`, {
      method: 'POST',
      body: formData
    });

    if (!uploadResponse.ok) {
      throw new Error(`HTTP error! status: ${uploadResponse.status}`);
    }

    const url = await uploadResponse.text();
    return { success: true, url: url };
  } catch (error) {
    console.error('Upload error:', error);
    return { success: false, error: error.message };
  }
}



async function copyToClipboard(content, forceSave = false) {
  try {
    // For regular strings (not blob URLs), use writeText
    if (typeof content === 'string' && !content.startsWith('blob:') && !forceSave) {
      await navigator.clipboard.writeText(content);
      return true;
    } 
    // For blob URLs, fetch the blob and use its original MIME type
    else if (content.startsWith('blob:')) {
      try {
        const response = await fetch(content);
        const blob = await response.blob();
        const mimeType = blob.type;
        
        // Check if it's GIF - most browsers don't support copying GIFs to clipboard
        const isGif = mimeType === 'image/gif';
        
        // If forceSave is true or it's a GIF, use the download method
        if (forceSave || isGif) {
          // Create a temporary anchor and trigger download
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          
          // Set appropriate file extension based on MIME type
          let extension = 'file';
          if (mimeType === 'image/png') extension = 'png';
          else if (mimeType === 'image/jpeg') extension = 'jpg';
          else if (mimeType === 'image/gif') extension = 'gif';
          else if (mimeType === 'image/webp') extension = 'webp';
          
          a.download = `meme.${extension}`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
          
          return true;
        }
        
        // Check if ClipboardItem is available (required for HTTPS)
        if (typeof ClipboardItem !== 'undefined') {
          if (isGif && !forceSave) {
            // For GIFs, try to copy as URL instead since browsers don't support GIF copying
            console.log('GIF detected - clipboard API may not support this format');
            await navigator.clipboard.writeText(content);
            return true;
          }
          
          // For images that aren't GIFs, copy using original MIME type
          if (mimeType.startsWith('image/')) {
            // WebP is often not well supported in clipboard, convert to PNG if needed
            if (mimeType === 'image/webp') {
              // Convert WebP to PNG using Canvas
              const img = new Image();
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              
              // Create a promise to handle image loading
              await new Promise((resolve, reject) => {
                img.onload = resolve;
                img.onerror = reject;
                img.src = URL.createObjectURL(blob);
              });
              
              // Set canvas size to match image
              canvas.width = img.width;
              canvas.height = img.height;
              
              // Draw image and convert to PNG
              ctx.drawImage(img, 0, 0);
              const pngBlob = await new Promise(resolve => {
                canvas.toBlob(resolve, 'image/png');
              });
              
              // Clean up
              URL.revokeObjectURL(img.src);
              
              // Copy PNG to clipboard
              const clipboardItem = new ClipboardItem({ 'image/png': pngBlob });
              await navigator.clipboard.write([clipboardItem]);
            } else {
              // Use the original MIME type for other image formats (JPEG, PNG, etc.)
              const clipboardItem = new ClipboardItem({
                [mimeType]: blob
              });
              await navigator.clipboard.write([clipboardItem]);
            }
            return true;
          } else {
            // For non-image blobs, fall back to URL
            await navigator.clipboard.writeText(content);
            return true;
          }
        } else {
          // Fallback for development/non-HTTPS environments
          console.warn('ClipboardItem API not available - this feature requires HTTPS in production');
          
          // Create a temporary anchor and trigger download instead
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          
          // Set appropriate file extension based on MIME type
          let extension = 'file';
          if (mimeType === 'image/png') extension = 'png';
          else if (mimeType === 'image/jpeg') extension = 'jpg';
          else if (mimeType === 'image/gif') extension = 'gif';
          else if (mimeType === 'image/webp') extension = 'webp';
          
          a.download = `meme.${extension}`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
          
          return true;
        }
      } catch (error) {
        console.error('Error handling blob:', error);
        // Try to copy as text URL if blob handling fails
        try {
          await navigator.clipboard.writeText(content);
          return true;
        } catch (fallbackError) {
          console.error('Fallback text copy failed:', fallbackError);
          return false;
        }
      }
    }
    return false;
  } catch (error) {
    console.error('Error copying to clipboard:', error);
    return false;
  }
}





async function getShortenedUrl(longUrl) {
  const apiKey = localStorage.getItem('apiKey');
  
  try {
    const shortenResponse = await fetch(getApiBase() + '/tfer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        long_url: longUrl,
        apikey: apiKey
      })
    });

    if (shortenResponse.ok) {
      const data = await shortenResponse.json();
      return { success: true, shortUrl: data.short_url };
    } else {
      return { success: false, error: 'Failed to shorten URL' };
    }
  } catch (error) {
    return { success: false, error: error.message };
  }
}

export { 
  getApiPath, 
  getTogglePostPath, 
  humanFileSize, 
  getApiBase,
  uploadImageBlobToService, 
  copyImageBlobToClipboard,
  getShortenedUrl,
  copyToClipboard
};