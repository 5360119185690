import React, { Component, createRef } from 'react';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import './ImageOverlay.css';

class ImageOverlay extends Component {
    constructor(props) {
        super(props);
        const { overlay } = props;
        
        this.state = {
            position: {
                x: typeof overlay.pos.x === 'number' ? overlay.pos.x : 0,
                y: typeof overlay.pos.y === 'number' ? overlay.pos.y : 0
            },
            prevPropsHash: this.getPropPositionHash(props.overlay),
            isResizing: false,
            isDragging: false,
            borderColor: this.generateRandomColor()
        };
        
        // Create refs
        this.draggableRef = createRef();
        this.overlayRef = createRef();
        this.imageRef = createRef();
    }

    // Generate a random semi-transparent color (hex with alpha)
    generateRandomColor = () => {
        const hexPart = Math.floor(Math.random()*16777215).toString(16).padStart(6, '0');
        return `#${hexPart}80`; // 80 is 50% opacity in hex
    };

    getPropPositionHash = (overlay) => {
        return `${overlay.pos.x}-${overlay.pos.y}-${overlay.pos.w}-${overlay.pos.h}-${overlay.pos.rotation_angle || 0}`;
    };

    componentDidUpdate(prevProps) {
        const { overlay } = this.props;
        const prevOverlay = prevProps.overlay;
        
        // Handle position changes from props
        const newPropHash = this.getPropPositionHash(overlay);
        const prevHash = this.state.prevPropsHash;
        
        if (!this.state.isResizing && newPropHash !== prevHash && 
            (overlay.pos.x !== prevOverlay.pos.x || 
             overlay.pos.y !== prevOverlay.pos.y || 
             overlay.pos.w !== prevOverlay.pos.w || 
             overlay.pos.h !== prevOverlay.pos.h ||
             overlay.pos.rotation_angle !== prevOverlay.pos.rotation_angle)) {
            
            this.setState({
                position: {
                    x: typeof overlay.pos.x === 'number' ? overlay.pos.x : 0,
                    y: typeof overlay.pos.y === 'number' ? overlay.pos.y : 0
                },
                prevPropsHash: newPropHash
            });
        }
        
        // Update if overlay_url changes
        if (overlay.overlay_url !== prevOverlay.overlay_url) {
            // Generate new border color when overlay changes
            this.setState({ borderColor: this.generateRandomColor() });
        }
    }

    handleDragStart = () => {
        if (this.state.isResizing) {
            return false;
        }
        this.setState({ isDragging: true });
    };

    handleDragStop = (e, data) => {
        if (this.state.isResizing) return;
        
        try {
            const previewEl = e.target.closest('.wysiwyg-preview');
            if (!previewEl) {
                this.updatePosition(data.x, data.y);
                return;
            }

            const rect = previewEl.getBoundingClientRect();
            const imageEl = previewEl.querySelector('img');
            
            if (!imageEl) {
                this.updatePosition(data.x, data.y);
                return;
            }

            const imageRect = imageEl.getBoundingClientRect();
            const relativeX = data.x - (imageRect.left - rect.left);
            const relativeY = data.y - (imageRect.top - rect.top);

            this.updatePosition(relativeX, relativeY);
        } catch (error) {
            console.warn('Error during drag operation:', error);
            this.updatePosition(data.x, data.y);
        }
        
        // Reset dragging state
        setTimeout(() => {
            this.setState({ isDragging: false });
        }, 100);
    };

    updatePosition = (x, y) => {
        const { overlay, imageWidth, imageHeight, index, onPositionChange } = this.props;
        const width = typeof overlay.pos.w === 'number' ? overlay.pos.w : 100;
        const height = typeof overlay.pos.h === 'number' ? overlay.pos.h : 100;

        // Keep within bounds
        const newX = Math.max(0, Math.min(x, imageWidth - width));
        const newY = Math.max(0, Math.min(y, imageHeight - height));

        // Generate new hash
        const newPropHash = `${newX}-${newY}-${overlay.pos.w}-${overlay.pos.h}`;
        
        // Update local state
        this.setState({
            position: { x: newX, y: newY },
            prevPropsHash: newPropHash
        });

        // Update parent
        onPositionChange(index, {
            x: newX,
            y: newY
        });
    };

    handleResizeStart = (e) => {
        e.stopPropagation();
        this.setState({ isResizing: true });
    };

    handleResizeStop = (e, { size }) => {
        e.stopPropagation();
        
        const { index, onPositionChange, imageWidth, imageHeight } = this.props;
        const { position } = this.state;
        const { pos } = this.props.overlay;
        
        // Calculate new dimensions
        const newWidth = Math.min(size.width, imageWidth - position.x);
        const newHeight = Math.min(size.height, imageHeight - position.y);
        const rotationAngle = pos.rotation_angle || 0;
        
        // Generate new hash
        const newPropHash = `${position.x}-${position.y}-${newWidth}-${newHeight}-${rotationAngle}`;
        
        // Update state
        this.setState({ 
            prevPropsHash: newPropHash,
            isResizing: false 
        }, () => {
            setTimeout(() => {
                this.setState({ isDragging: false });
            }, 100);
        });
        
        // Update parent
        onPositionChange(index, {
            w: newWidth,
            h: newHeight
        });
    };

    prepareForCapture = () => {
        if (this.overlayRef.current) {
            this.overlayRef.current.style.border = '0';
            this.overlayRef.current.style.outline = 'none';
            this.overlayRef.current.style.boxShadow = 'none';
        }
    };

    componentDidMount() {
        // Add event listener for capture mode
        window.addEventListener('beforecapture', this.prepareForCapture);
    }

    componentWillUnmount() {
        window.removeEventListener('beforecapture', this.prepareForCapture);
    }

    render() {
        const { overlay, imageWidth, imageHeight } = this.props;
        const { position, isResizing, borderColor } = this.state;
        const { pos, overlay_url } = overlay;

        const width = typeof pos.w === 'number' ? pos.w : 100;
        const height = typeof pos.h === 'number' ? pos.h : 100;
        const rotationAngle = typeof pos.rotation_angle === 'number' ? pos.rotation_angle : 0;
        
        // Check if overlay URL is valid
        const hasValidUrl = overlay_url && overlay_url.trim() !== '';

        // Style for the overlay container
        const overlayStyle = {
            width: '100%',
            height: '100%',
            boxSizing: 'border-box',
            position: 'relative',
            // Only show borders when not capturing screenshot and URL is valid
            border: document.querySelector('.capturing-screenshot') || !hasValidUrl 
                ? 'none' 
                : `3px dashed ${borderColor}`,
            borderRadius: '4px',
            overflow: 'hidden',
            cursor: isResizing ? 'auto' : 'move',
            // Add rotation transform
            transform: `rotate(${rotationAngle}deg)`,
            transformOrigin: 'center center'
        };

        const imageStyle = {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            display: hasValidUrl ? 'block' : 'none'
        };

        // Placeholder text style
        const placeholderStyle = {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f0f0f0',
            color: '#888',
            fontStyle: 'italic',
            padding: '10px',
            textAlign: 'center',
            overflow: 'hidden'
        };

        return (
            <Draggable
                nodeRef={this.draggableRef}
                position={position}
                onStart={this.handleDragStart}
                onStop={this.handleDragStop}
                bounds="parent"
                grid={[5, 5]}
                disabled={isResizing}
                cancel=".react-resizable-handle"
            >
                <div 
                    ref={this.draggableRef}
                    style={{ position: 'absolute', padding: 0, margin: 0 }}
                >
                    <ResizableBox
                        width={width}
                        height={height}
                        onResizeStart={this.handleResizeStart}
                        onResizeStop={this.handleResizeStop}
                        minConstraints={[50, 20]}
                        maxConstraints={[
                            Math.max(50, imageWidth - position.x),
                            Math.max(20, imageHeight - position.y)
                        ]}
                        resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
                        handleSize={[8, 8]}
                    >
                        <div 
                            ref={this.overlayRef}
                            className="image-overlay-box"
                            style={overlayStyle}
                        >
                            {hasValidUrl ? (
                                <img 
                                    ref={this.imageRef}
                                    src={overlay_url} 
                                    alt="Overlay"
                                    style={imageStyle}
                                    crossOrigin="anonymous"
                                />
                            ) : (
                                <div style={placeholderStyle}>
                                    {overlay.hint || "Enter Image URL"}
                                </div>
                            )}
                        </div>
                    </ResizableBox>
                </div>
            </Draggable>
        );
    }
}

export default ImageOverlay;