import { Component } from "react";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import { Container } from "react-bootstrap";
import { isShitpostDomain } from './Utils';

class Shitbot extends Component {   
    constructor(props) {
        super(props);
        this.state = {
            content: null
        }
        
        // Define term replacements
        this.replacements = [
            { old: /shitbot/gi, new: 'frenbot' },
            { old: /shithook/gi, new: 'frenhook' },
            { old: /shitpost\.sh/gi, new: 'frenpost.xyz' },
            { old: /\bshitpost\b(?!\.sh)/gi, new: 'frenpost' },
            { old: /kingshit/gi, new: 'topfren' },
            { old: /king shit/gi, new: 'top fren' },
            { old: /@kingshit/gi, new: '@topfren' },
            { old: /@kings/gi, new: '@topf' }
        ];
    }

    transformContent = (content) => {
        if (isShitpostDomain) {
            return content;
        }

        // Apply all replacements
        return this.replacements.reduce((text, replacement) => {
            return text.replace(replacement.old, replacement.new);
        }, content);
    }

    componentDidMount = () => {
        if (this.state.content === null) {
            fetch("/markdown/shitbot.md")
                .then(response => response.text())
                .then(data => {
                    const transformedContent = this.transformContent(data);
                    this.setState({ content: transformedContent });
                });
        }
    }

    render() {
        return (
            <Container>
                <ReactMarkdown remarkPlugins={remarkGfm}>
                    {this.state.content}
                </ReactMarkdown>
            </Container>
        )
    }
}

export default Shitbot;