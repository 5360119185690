import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPlus, 
  faPaintbrush, 
  faSave, 
  faTrash, 
  faCopy,
  faUpload, // Add upload icon
  faClipboard, // Add clipboard icon
  faChevronDown,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import TextDef from './TextDef.js';
import OverlayDef from './OverlayDef.js';
import './MemeMaker.css';

import { getApiBase, copyToClipboard, isUserAdmin, uploadImageBlobToService, getShortenedUrl } from "./Utils.js";
// import { useNavigate } from 'react-router-dom';

import TextOverlay from "./TextOverlay.js";
import ImageOverlay from './ImageOverlay';

import WorkingOverlay from './WorkingOverlay';

import 'react-resizable/css/styles.css';
import html2canvas from 'html2canvas';


class MemeMaker extends Component {

  constructor(props) {
    super(props);
    
    // Check if there's an _id in the URL params
    const urlParams = new URLSearchParams(window.location.search);
    const hasId = urlParams.has('_id');
    
    this.state = {
      "_id": null,
      "name": "",
      "background": "",
      "animated": false,  // Add this line for animation state
      "texts": [], // Empty array instead of [this.defaultText]
      "overlays" : [],
      "resize_x": null,
      "resize_y": null,
      isWorking: false,
      isJsonExpanded: false,
      isImageExpanded: true,
      isAdmin: isUserAdmin, // Add admin status to state
      copyMessage: '',  // Add new state for copy feedback
      availableFonts: {},  // Add this to store fonts from API
      imageHeight: null,  // Add imageHeight to state
      imageWidth: null,  // Add imageWidth to state
      isMemeBasicsExpanded: !hasId, // Expanded by default when creating new meme
      isTextDefsExpanded: true,     // Add these two lines 
      isOverlayDefsExpanded: true,   // for new collapsible sections
      isMobile: window.innerWidth < 768, // Add mobile detection
    };
    
    // Add resize listener reference
    this.resizeListener = null;
  }

  handlePositionChange = (index, newPos) => {
    const newTexts = [...this.state.texts];
    newTexts[index].pos = {
        ...newTexts[index].pos,
        ...newPos
    };
    this.setState({ texts: newTexts });
  };

  handleOverlayPositionChange = (index, newPos) => {
    const newOverlays = [...this.state.overlays];
    newOverlays[index].pos = {
        ...newOverlays[index].pos,
        ...newPos
    };
    this.setState({ overlays: newOverlays });
  };

  isValidUrl = (url) => {
    try {
      const parsed = new URL(url);
      return parsed.protocol === 'http:' || parsed.protocol === 'https:';
    } catch (e) {
      return false;
    }
  };

  validateMemeDefinition = (operation) => {
    const { name, background, texts, overlays } = this.state;
    
    // For SAVE operations, name is required
    if (operation === 'save') {
      if (!name || name.trim() === '') {
        alert('Name is required for saving meme definitions');
        return false;
      }
    }
    
    // For all operations, background is required
    if (!background || !this.isValidUrl(background)) {
      alert('Background must be a valid HTTP or HTTPS URL');
      return false;
    }
    
    // For GENERATE IMAGE operations, we need at least one text or overlay with content
    if (operation === 'generate') {
      const hasTextContent = texts && texts.some(text => text.text && text.text.trim() !== '');
      const hasOverlayContent = overlays && overlays.some(overlay => overlay.overlay_url && overlay.overlay_url.trim() !== '');
      
      if (!hasTextContent && !hasOverlayContent) {
        alert('At least one text or image overlay with content is required for image generation');
        return false;
      }
    }
    
    // For any operation, validate structure of text objects if they exist
    for (let i = 0; i < texts.length; i++) {
      const text = texts[i];
      
      // Skip structural validation for empty text objects
      if (!text.text || text.text.trim() === '') {
        continue;
      }
      
      // Check upperCase field exists
      if (typeof text.upperCase !== 'boolean') {
        alert(`Text definition ${i + 1} must have an upperCase field`);
        return false;
      }
      
      // Check pos object and its required fields
      if (!text.pos || typeof text.pos !== 'object') {
        alert(`Text definition ${i + 1} must have a pos field`);
        return false;
      }
      
      const { x, y, w, h } = text.pos;
      // Check if x, y, w, h are valid numbers or "auto"
      const isValidPosValue = (value) => typeof value === 'number' || value === 'auto';
      if (!isValidPosValue(x) || !isValidPosValue(y) || 
          !isValidPosValue(w) || !isValidPosValue(h)) {
        alert(`Text definition ${i + 1} must have valid x, y, w, h values in pos`);
        return false;
      }
    }
    
    // Validate overlay objects structure if they exist
    for (let i = 0; i < overlays.length; i++) {
      const overlay = overlays[i];
      
      // Skip structural validation for empty overlay objects
      if (!overlay.overlay_url || overlay.overlay_url.trim() === '') {
        continue;
      }
      
      // Check pos object and its required fields
      if (!overlay.pos || typeof overlay.pos !== 'object') {
        alert(`Overlay definition ${i + 1} must have a pos field`);
        return false;
      }
      
      const { x, y, w, h } = overlay.pos;
      // Check if x, y, w, h are valid numbers
      const isValidPosValue = (value) => typeof value === 'number';
      if (!isValidPosValue(x) || !isValidPosValue(y) || 
          !isValidPosValue(w) || !isValidPosValue(h)) {
        alert(`Overlay definition ${i + 1} must have valid x, y, w, h values in pos`);
        return false;
      }
    }
    
    return true;
  };

  generatePayload = (isGenerateImage = false) => {
    // Create payload, conditionally including fields
    const payload = {
      ...(this.state._id && { _id: this.state._id }),
      name: this.state.name,
      background: this.state.background,
      animated: this.state.animated, // Add this line
      texts: this.state.texts.map(text => {
        // Extract all optional fields
        const { bgcolor, outline, outline_wt, hint, color, font, text: textContent, ...rest } = text;
        return {
          ...(isGenerateImage && { text: textContent }), // Only include text for image generation
          ...rest,
          ...(color !== null && color !== "" && { color }),
          ...(font !== null && font !== "" && { font }),
          ...(bgcolor !== null && bgcolor !== "" && { bgcolor }),
          ...(outline !== null && outline !== "" && { outline }),
          ...(outline_wt !== null && { outline_wt }),
          ...(hint !== null && hint !== "" && { hint })
        };
      }),
      ...(this.state.overlays && this.state.overlays.length > 0 && {
        overlays: this.state.overlays
          // Only include non-empty overlays
          .filter(o => o.overlay_url || !isGenerateImage)
          // For each overlay, conditionally include fields based on isGenerateImage
          .map(overlay => {
            if (isGenerateImage) {
              // For image generation, include all fields
              return overlay;
            } else {
              // For saving/updating, exclude overlay_url
              const { overlay_url, ...rest } = overlay;
              return {
                ...rest,
                // If you want to keep a flag that this overlay had a URL, you could do:
                // has_url: !!overlay_url
              };
            }
          })
      }),
      ...(this.state.resize_x !== null && { resize_x: this.state.resize_x }),
      ...(this.state.resize_y !== null && { resize_y: this.state.resize_y })
    };
    return payload;
  };

  // Perform HTTP POST request
  handleGenerateImage = () => {
    if (!this.validateMemeDefinition('generate')) {
      return;
    }
    
    const apiBase = getApiBase() + '/memetest';
    const payload = this.generatePayload(true); // Pass true for image generation

    this.setState({ isWorking: true }); // Show overlay while generating

    fetch(apiBase, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        const imageUrl = URL.createObjectURL(blob);
        this.setState({ 
          imageUrl,
          isWorking: false  // Hide overlay after success
        });
      })
      .catch(error => {
        console.error('Error generating image:', error);
        this.setState({ 
          imageUrl: null,
          isWorking: false  // Hide overlay after error
        });
      });
  };

  // Update the copy definition handler
  handleCopyDefinition = async () => {
    const textToCopy = JSON.stringify(this.generatePayload(false), null, "\t");
    const success = await copyToClipboard(textToCopy);
    
    if (success) {
      this.setState({ copyMessage: 'Copied definition' });
      setTimeout(() => this.setState({ copyMessage: '' }), 5000);
    } else {
      this.setState({ copyMessage: 'Failed to copy definition' });
      setTimeout(() => this.setState({ copyMessage: '' }), 5000);
    }
  };

  // Update the handleTopLevelChange method to detect GIF backgrounds

handleTopLevelChange = (field) => (event) => {
  const value = event.target.value === '' ? null : event.target.value; // Convert empty string to null for resize fields
  
  // Special handling for background field
  if (field === 'background') {
    const isGif = value && typeof value === 'string' && value.toLowerCase().endsWith('.gif');
    
    // Update with animated flag for GIFs but don't remove overlays
    this.setState({
      [field]: value,
      ...(isGif && { animated: true }) // Set animated to true if it's a GIF
    });
  } else {
    // For all other fields, just update normally
    this.setState({
      [field]: field === 'resize_x' || field === 'resize_y' ? 
        (isNaN(Number(value)) ? value : Number(value)) : 
        value
    });
  }
};

  componentDidMount = () => {
    // Add font loading
    this.loadAvailableFonts();
  
    const urlParams = new URLSearchParams(window.location.search);
    const _id = urlParams.get('_id');
    const backgroundUrl = urlParams.get('bg');
  
    if (_id) {
      // Fetch meme definition by _id
      const url = `${getApiBase()}/${localStorage.getItem("apiKey")}/meme_def?_id=${_id}`;
      
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          // Process and normalize text properties
          const processedTexts = (data.texts || []).map(text => ({
            text: text.text || '',
            color: text.color || '', // Preserve raw color format
            bgcolor: text.bgcolor || '',
            font: text.font || '',
            hint: text.hint || '',
            outline: text.outline || '',
            outline_wt: text.outline_wt ?? 1,
            pos: {
              x: typeof text.pos?.x === 'number' ? text.pos.x : 0,
              y: typeof text.pos?.y === 'number' ? text.pos.y : 0,
              w: typeof text.pos?.w === 'number' ? text.pos.w : 100,
              h: typeof text.pos?.h === 'number' ? text.pos.h : 50,
              rotation_angle: typeof text.pos?.rotation_angle === 'number' ? text.pos.rotation_angle : 0
            },
            upperCase: text.upperCase ?? true
          }));
  
          // Process overlays if they exist in the data
          const processedOverlays = (data.overlays || []).map(overlay => ({
            overlay_url: overlay.overlay_url || '',
            hint: overlay.hint || '',
            round_corners: overlay.round_corners || false,
            pos: {
              x: typeof overlay.pos?.x === 'number' ? overlay.pos.x : 0,
              y: typeof overlay.pos?.y === 'number' ? overlay.pos.y : 0,
              w: typeof overlay.pos?.w === 'number' ? overlay.pos.w : 100,
              h: typeof overlay.pos?.h === 'number' ? overlay.pos.h : 100,
              rotation_angle: typeof overlay.pos?.rotation_angle === 'number' ? overlay.pos.rotation_angle : 0
            }
          }));
  
          // Check if background is a GIF
          const isGifBackground = data.background && 
            typeof data.background === 'string' && 
            data.background.toLowerCase().endsWith('.gif');
  
          // Update state with processed data
          this.setState({
            _id: data._id,
            name: data.name || '',
            background: data.background || '',
            // If it's a GIF or was marked animated, set to true
            animated: isGifBackground || data.animated || false,
            // If it's a GIF, don't include overlays
            texts: processedTexts.length > 0 ? processedTexts : [],
            overlays: isGifBackground ? [] : (processedOverlays || []),
            resize_x: data.resize_x ?? null,
            resize_y: data.resize_y ?? null,
            // When loading an existing meme, close the meme basics panel
            isMemeBasicsExpanded: false
          });
        })
        .catch(error => {
          console.error('Error loading meme definition:', error);
          alert('Failed to load meme definition');
        });
    } else if (backgroundUrl) {
      // If there's a bg parameter but no _id, set the background URL
      const isGifBackground = backgroundUrl.toLowerCase().endsWith('.gif');
      
      // Set the state with the background URL
      this.setState({
        background: backgroundUrl,
        animated: isGifBackground, // Automatically set animated flag for GIF backgrounds
        // Keep the meme basics panel expanded when pre-filling just the background
        isMemeBasicsExpanded: true
      });
  
      // Optionally, you could pre-create a text element to make it faster for users to add text
      // this.handleAddText();
    }

    // Add resize listener to update mobile state
    this.resizeListener = () => {
      this.setState({
        isMobile: window.innerWidth < 768
      });
    };
    window.addEventListener('resize', this.resizeListener);
  };

  // Clean up event listeners
  componentWillUnmount() {
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener);
    }
  }

  // Update the loadAvailableFonts method
  loadAvailableFonts = () => {
    const apiBase = getApiBase();
    
    fetch(`${apiBase}/meme_fonts`)
        .then(response => response.json())
        .then(data => {
            // Store both server fonts and web fonts mapping
            this.setState({ 
                availableFonts: data.server_fonts || {},
                webFontMapping: data.web_fonts || {}
            });
            
            // Load any Google web fonts
            this.loadGoogleFonts(data.web_fonts);
        })
        .catch(error => {
            console.error('Error loading fonts:', error);
        });
};

// Add a method to load Google web fonts if needed
loadGoogleFonts = (webFontMapping) => {
    if (!webFontMapping) return;
    
    try {
        const WebFont = require('webfontloader');
        
        // Extract font families from the mapping values
        const fontFamilies = Object.values(webFontMapping)
            .map(fontStack => fontStack.split(',')[0].trim())
            .filter(font => 
                !['Arial', 'Times', 'Courier', 'Verdana', 'Georgia', 'Helvetica', 'Impact']
                    .some(system => font.includes(system))
            );
        
        // Load any non-system fonts via Google Fonts
        if (fontFamilies.length > 0) {
            WebFont.load({
                google: {
                    families: fontFamilies
                },
                active: () => {
                    console.log('Fonts loaded successfully');
                    this.forceUpdate();
                }
            });
        }
    } catch (error) {
        console.error('Error loading web fonts:', error);
    }
};

  onTextsChange = (newTextDefs) => {
    this.setState({
      texts: newTextDefs
    });
  };

  onOverlaysChange = (newOverlayDefs) => {
    this.setState({
      overlays: newOverlayDefs
    });
  };

  handleCreateUpdate = () => {
    if (!this.validateMemeDefinition('save')) {
      return;
    }

    const payload = this.generatePayload(false); // Pass false for meme_def endpoints
    const isUpdate = !!this.state._id;
    const url = `${getApiBase()}/${localStorage.getItem("apiKey")}/meme_def`;

    this.setState({ isWorking: true });

    fetch(url, {
      method: isUpdate ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const newId = data._id?.['$oid'] || data._id;  // Handle both formats
        if (!isUpdate) {
          window.history.pushState({}, '', `?_id=${newId}`);
        }
        this.setState({
          _id: newId,
          isWorking: false
        });
      })
      .catch(error => {
        console.error('Error:', error);
        this.setState({ isWorking: false });
        alert(`Failed to ${isUpdate ? 'update' : 'create'} meme definition: ${error.message}`);
      });
  };

  // Add the delete handler method
  handleDelete = () => {
    const { name, _id } = this.state;
    
    // Extract _id, handling potential $oid nesting
    const memeId = _id?.['$oid'] || _id;
    if (!memeId) {
      alert('Error: Unable to determine meme ID');
      return;
    }
    
    if (window.confirm(`ARE YOU SURE YOU WANT TO DELETE ${name}?`)) {
      const url = `${getApiBase()}/${localStorage.getItem("apiKey")}/meme_def/${memeId}`;
      
      this.setState({ isWorking: true });
      
      fetch(url, {
        method: 'DELETE'
      })
        .then(response => {
          if (!response.ok) {
            return response.text().then(text => {
              throw new Error(text);
            });
          }
          // Success - reset state and URL
          window.history.pushState({}, '', window.location.pathname);
          this.setState({
            _id: null,
            name: '',
            background: '',
            animated: false, // Reset animated state
            texts: [], // Reset to empty array
            overlays: [], // Reset to empty array
            resize_x: null,
            resize_y: null,
            isWorking: false
          });
          alert('Meme definition deleted successfully');
        })
        .catch(error => {
          console.error('Delete error:', error);
          this.setState({ isWorking: false });
          alert(`Failed to delete meme definition: ${error.message}`);
        });
    }
  };

  toggleJsonPreview = () => {
    this.setState(prevState => ({
      isJsonExpanded: !prevState.isJsonExpanded
    }));
  };

  toggleImagePreview = () => {
    this.setState(prevState => ({
      isImageExpanded: !prevState.isImageExpanded
    }));
  };

  // Add this method to get image dimensions
  handleImageLoad = (event) => {
    const imgWidth = event.target.naturalWidth;
    const imgHeight = event.target.naturalHeight;
    
    // Calculate appropriate dimensions
    let displayWidth = imgWidth;
    let displayHeight = imgHeight;
    
    // If on mobile and image is large, constrain it
    if (this.state.isMobile && (imgWidth > 300 || imgHeight > 300)) {
      const aspectRatio = imgWidth / imgHeight;
      if (aspectRatio >= 1) {
        // Landscape or square image
        displayWidth = 300;
        displayHeight = 300 / aspectRatio;
      } else {
        // Portrait image
        displayHeight = 300;
        displayWidth = 300 * aspectRatio;
      }
    }
    
    this.setState({
      imageWidth: displayWidth,
      imageHeight: displayHeight
    });
  };

  // Update handleAddText to use the class property
  handleAddText = () => {
    const newText = {
      text: '',
      color: 'ffffff', // Default text color: white
      bgcolor: '',
      font: '',
      hint: '',
      outline: '000000', // Default outline color: black
      outline_wt: 1,
      pos: {
        x: 0,
        y: 0,
        w: 100,
        h: 50,
        rotation_angle: 0
      },
      upperCase: true
    };
    
    this.setState(prevState => ({
      texts: [...prevState.texts, newText]
    }));
  };

  // Update the handleAddOverlay method

handleAddOverlay = () => {
  const defaultOverlay = {
    overlay_url: '',
    pos: {
      x: 0,
      y: 0,
      w: 100,
      h: 100,
      rotation_angle: 0
    }
  };
  
  this.setState(prevState => ({
    overlays: [...prevState.overlays, defaultOverlay]
  }));
};

  // Update the capturePreview method to use the imported html2canvas directly

capturePreview = () => {
  const previewElement = document.querySelector('.wysiwyg-preview');
  if (!previewElement) {
    alert('No preview to capture');
    return;
  }
  
  this.setState({ isWorking: true, copyMessage: 'Capturing preview...' });
  
  // Add a temporary class to hide handles and borders
  const textOverlays = previewElement.querySelectorAll('.react-draggable');
  textOverlays.forEach(overlay => {
    overlay.classList.add('capturing-screenshot');
  });
  
  // Create a style element with our temporary styles
  const tempStyle = document.createElement('style');
  tempStyle.textContent = `
    .capturing-screenshot .react-resizable-handle {
      display: none !important;
    }
    .capturing-screenshot .text-overlay-box {
      border: none !important;
      outline: none !important;
    }
  `;
  document.head.appendChild(tempStyle);
  
  // Use the imported html2canvas directly
  html2canvas(previewElement, {
    allowTaint: true,
    useCORS: true,
    backgroundColor: null,
    scale: 2 // Higher quality
  }).then(canvas => {
    // Clean up - remove temporary styles and classes
    document.head.removeChild(tempStyle);
    textOverlays.forEach(overlay => {
      overlay.classList.remove('capturing-screenshot');
    });
    
    this.openCanvasInNewWindow(canvas);
  }).catch(err => {
    // Clean up even on error
    document.head.removeChild(tempStyle);
    textOverlays.forEach(overlay => {
      overlay.classList.remove('capturing-screenshot');
    });
    
    console.error('Error capturing preview:', err);
    this.setState({ isWorking: false, copyMessage: 'Failed to capture preview' });
    setTimeout(() => this.setState({ copyMessage: '' }), 3000);
  });
};

// Add this method to save the canvas directly without toBlob
openCanvasInNewWindow = (canvas) => {
  try {
    // Try to create a data URL directly
    const dataUrl = canvas.toDataURL('image/png');
    
    // Open in new window
    const newWindow = window.open();
    if (newWindow) {
      newWindow.document.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <title>Meme Preview</title>
            <style>
              body { margin: 0; padding: 20px; text-align: center; background: #f0f0f0; }
              img { max-width: 100%; box-shadow: 0 4px 8px rgba(0,0,0,0.1); }
              .controls { margin-top: 20px; }
              button { padding: 8px 16px; margin: 0 10px; cursor: pointer; }
            </style>
          </head>
          <body>
            <img src="${dataUrl}" alt="Meme Preview" />
            <div class="controls">
              <button onclick="window.print()">Print</button>
              <button onclick="downloadImage()">Download</button>
            </div>
            <script>
              function downloadImage() {
                const a = document.createElement('a');
                a.href = "${dataUrl}";
                a.download = "${this.state.name || 'meme'}.png";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              }
            </script>
          </body>
        </html>
      `);
      newWindow.document.close();
    } else {
      alert('Popup blocked. Please allow popups for this site.');
      
      // Fallback: Create a download link
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `${this.state.name || 'meme'}.png`;
      link.click();
    }
    
    this.setState({ 
      isWorking: false,
      copyMessage: 'Preview captured!'
    });
    setTimeout(() => this.setState({ copyMessage: '' }), 3000);
  } catch (error) {
    console.error('Error opening canvas in new window:', error);
    
    // If toDataURL failed due to tainted canvas, try proxy approach
    this.tryProxyApproach();
    
    this.setState({ 
      isWorking: false,
      copyMessage: 'Preview failed - CORS issue. Try the API instead.'
    });
    setTimeout(() => this.setState({ copyMessage: '' }), 5000);
  }
};

// Optional proxy approach for handling tainted canvas
tryProxyApproach = () => {
  // Create a server-side proxy solution if possible
  const { background } = this.state;
  
  if (background) {
    // This is just a placeholder - if you have a CORS proxy available
    // you could use it here to fetch the image through your server
    alert(`
      The image couldn't be captured due to cross-origin restrictions.
      Consider:
      1. Hosting the image on your own server with proper CORS headers
      2. Using the API-generated image instead
      3. Setting up a CORS proxy on your server
    `);
  }
};

// Copy canvas image to clipboard
copyCanvasToClipboard = () => {
  const previewElement = document.querySelector('.wysiwyg-preview');
  if (!previewElement) {
    alert('No preview to copy');
    return;
  }
  
  this.setState({ isWorking: true, copyMessage: 'Copying to clipboard...' });
  
  // Deep clone the preview element to avoid affecting the original
  const clonedPreview = previewElement.cloneNode(true);
  clonedPreview.style.position = 'absolute';
  clonedPreview.style.left = '-9999px';
  clonedPreview.style.top = '-9999px';
  document.body.appendChild(clonedPreview);
  
  // Apply styles to the cloned element
  this.applyTemporaryStyles(clonedPreview);
  
  // Wait longer for styles to apply in production
  setTimeout(() => {
    // Double check that styles are really applied
    clonedPreview.querySelectorAll('.react-draggable, .react-resizable-handle').forEach(el => {
      el.style.border = '0';
      el.style.outline = 'none';
      el.style.boxShadow = 'none';
    });
    
    const html2canvasOptions = {
      allowTaint: true,
      useCORS: true,
      backgroundColor: null,
      scale: 2,
      logging: false,
      width: previewElement.offsetWidth,
      height: previewElement.offsetHeight,
      ignoreElements: (element) => {
        return element.classList.contains('react-resizable-handle');
      },
      onclone: (documentClone) => {
        // Double-check the cloned document
        const clonedElements = documentClone.querySelectorAll('.react-draggable, .text-overlay-box');
        clonedElements.forEach(el => {
          el.style.border = '0 !important';
          el.style.outline = 'none !important';
          el.style.boxShadow = 'none !important';
        });
      }
    };

    html2canvas(clonedPreview, html2canvasOptions).then(canvas => {
      // Clean up
      document.body.removeChild(clonedPreview);
      this.removeTemporaryStyles();
      
      // Draw on a fresh canvas
      const finalCanvas = document.createElement('canvas');
      finalCanvas.width = canvas.width;
      finalCanvas.height = canvas.height;
      const ctx = finalCanvas.getContext('2d');
      ctx.drawImage(canvas, 0, 0);
      
      // Convert to blob URL
      finalCanvas.toBlob(blob => {
        const blobUrl = URL.createObjectURL(blob);
        
        // Use the utility function
        copyToClipboard(blobUrl)
          .then(success => {
            this.setState({
              isWorking: false,
              copyMessage: success ? 'Image copied to clipboard!' : 'Failed to copy image to clipboard'
            });
            setTimeout(() => this.setState({ copyMessage: '' }), 3000);
            
            // Clean up blob URL
            URL.revokeObjectURL(blobUrl);
          });
      }, 'image/png');
    }).catch(err => {
      document.body.removeChild(clonedPreview);
      this.removeTemporaryStyles();
      console.error('Error capturing image:', err);
      this.setState({ 
        isWorking: false, 
        copyMessage: 'Failed to capture image' 
      });
      setTimeout(() => this.setState({ copyMessage: '' }), 3000);
    });
  }, 300); // Longer delay for production
};

// Upload canvas image to server
uploadCanvasImage = () => {
  const previewElement = document.querySelector('.wysiwyg-preview');
  if (!previewElement) {
    alert('No preview to upload');
    return;
  }
  
  this.setState({ isWorking: true, copyMessage: 'Preparing to upload...' });
  
  // Temporarily hide handle elements
  this.applyTemporaryStyles(previewElement);
  
  html2canvas(previewElement, {
    allowTaint: true,
    useCORS: true,
    backgroundColor: null,
    scale: 2 // Higher quality
  }).then(canvas => {
    // Remove temporary styles
    this.removeTemporaryStyles();
    
    // Convert to blob and get URL
    canvas.toBlob(blob => {
      const blobUrl = URL.createObjectURL(blob);
      
      // Use the utility function for uploading
      uploadImageBlobToService(blobUrl)
        .then(result => {
          if (result.success) {
            // Get shortened URL
            getShortenedUrl(result.url)
              .then(shortenResult => {
                const finalUrl = shortenResult.success ? shortenResult.shortUrl : result.url;
                
                // Use copyToClipboard utility for better cross-browser support
                copyToClipboard(finalUrl)
                  .then(copySuccess => {
                    if (copySuccess) {
                      this.setState({
                        isWorking: false,
                        copyMessage: 'Image uploaded! URL copied to clipboard.'
                      });
                    } else {
                      this.setState({
                        isWorking: false,
                        copyMessage: `Image uploaded! URL: ${finalUrl}`
                      });
                      console.log('URL for manual copy:', finalUrl);
                    }
                    setTimeout(() => this.setState({ copyMessage: '' }), 5000);
                  })
                  .catch(copyError => {
                    console.error('Error copying to clipboard:', copyError);
                    this.setState({
                      isWorking: false,
                      copyMessage: `Image uploaded! URL: ${finalUrl} (copy failed)`
                    });
                    console.log('URL for manual copy:', finalUrl);
                    setTimeout(() => this.setState({ copyMessage: '' }), 5000);
                  });
              })
              .catch(shortenError => {
                console.error('Error shortening URL:', shortenError);
                
                // If shortening fails, use the original URL
                copyToClipboard(result.url)
                  .then(copySuccess => {
                    if (copySuccess) {
                      this.setState({
                        isWorking: false,
                        copyMessage: 'Image uploaded! URL copied to clipboard.'
                      });
                    } else {
                      this.setState({
                        isWorking: false,
                        copyMessage: `Image uploaded! URL: ${result.url}`
                      });
                      console.log('URL for manual copy:', result.url);
                    }
                    setTimeout(() => this.setState({ copyMessage: '' }), 5000);
                  })
                  .catch(copyError => {
                    console.error('Error copying to clipboard:', copyError);
                    this.setState({
                      isWorking: false,
                      copyMessage: `Image uploaded! URL: ${result.url} (copy failed)`
                    });
                    console.log('URL for manual copy:', result.url);
                    setTimeout(() => this.setState({ copyMessage: '' }), 5000);
                  });
              });
          } else {
            this.setState({
              isWorking: false,
              copyMessage: 'Failed to upload image'
            });
            setTimeout(() => this.setState({ copyMessage: '' }), 3000);
          }
          
          // Clean up blob URL
          URL.revokeObjectURL(blobUrl);
        })
        .catch(uploadError => {
          console.error('Error uploading image:', uploadError);
          this.setState({
            isWorking: false,
            copyMessage: 'Failed to upload image: ' + (uploadError.message || 'Unknown error')
          });
          setTimeout(() => this.setState({ copyMessage: '' }), 3000);
          
          // Clean up blob URL
          URL.revokeObjectURL(blobUrl);
        });
    }, 'image/png');
  }).catch(err => {
    // Clean up even on error
    this.removeTemporaryStyles();
    console.error('Error capturing image:', err);
    this.setState({ 
      isWorking: false, 
      copyMessage: 'Failed to capture image' 
    });
    setTimeout(() => this.setState({ copyMessage: '' }), 3000);
  });
};

// Helper methods for temporary styles
applyTemporaryStyles = (previewElement) => {
  // Apply classes first
  const textOverlays = previewElement.querySelectorAll('.react-draggable');
  textOverlays.forEach(overlay => {
    overlay.classList.add('capturing-screenshot');
    // Also directly apply inline styles for maximum compatibility
    overlay.style.border = '0 !important';
    overlay.style.outline = 'none !important';
    overlay.style.boxShadow = 'none !important';
    overlay.style.backgroundColor = 'transparent !important';
    overlay.style.margin = '0 !important';
    overlay.style.padding = '0 !important';
  });
  
  // Add class to the container and all nested elements
  previewElement.classList.add('capturing-screenshot');
  previewElement.querySelectorAll('*').forEach(el => {
    el.classList.add('capturing-screenshot');
    // Apply inline styles to all elements for maximum compatibility
    if (el !== previewElement) {
      el.style.border = '0 !important';
      el.style.outline = 'none !important';
      el.style.boxShadow = 'none !important';
      el.style.margin = '0 !important';
      el.style.padding = '0 !important';
    }
  });
  
  // The rest of your existing style injection
  const tempStyle = document.createElement('style');
  tempStyle.id = 'temp-capture-styles';
  tempStyle.textContent = `
    /* Even more aggressive styles for production */
    .capturing-screenshot,
    .capturing-screenshot *,
    .capturing-screenshot .react-draggable,
    .capturing-screenshot .text-overlay-box,
    .wysiwyg-preview.capturing-screenshot,
    .wysiwyg-preview.capturing-screenshot *,
    .wysiwyg-preview .capturing-screenshot,
    .wysiwyg-preview .capturing-screenshot * {
      border: 0 !important;
      border-width: 0 !important;
      border-style: none !important;
      border-color: transparent !important;
      outline: none !important;
      box-shadow: none !important;
      background-color: transparent !important;
      margin: 0 !important;
      padding: 0 !important;
      border-radius: 0 !important;
    }
    
    /* Hide all resize handles with high specificity */
    .react-resizable-handle,
    .react-draggable .react-resizable-handle,
    .capturing-screenshot .react-resizable-handle,
    div.react-resizable-handle,
    span.react-resizable-handle,
    .react-resizable-handle.react-resizable-handle-se {
      display: none !important;
      visibility: hidden !important;
      opacity: 0 !important;
      width: 0 !important;
      height: 0 !important;
    }
    
    /* Ensure text content remains visible */
    .capturing-screenshot .text-content {
      visibility: visible !important;
      display: block !important;
    }
  `;
  document.head.appendChild(tempStyle);
};

removeTemporaryStyles = () => {
  // Remove temporary styles
  const tempStyle = document.getElementById('temp-capture-styles');
  if (tempStyle) {
    document.head.removeChild(tempStyle);
  }
  
  // More thorough cleanup of all elements with the capturing-screenshot class
  document.querySelectorAll('.capturing-screenshot').forEach(el => {
    el.classList.remove('capturing-screenshot');
  });
};

  // Add this method to toggle the collapsible section
  toggleMemeBasics = () => {
    this.setState(prevState => ({
      isMemeBasicsExpanded: !prevState.isMemeBasicsExpanded
    }));
  }

  // Add these toggle methods
  toggleTextDefsSection = () => {
    this.setState(prevState => ({
      isTextDefsExpanded: !prevState.isTextDefsExpanded
    }));
  }

  toggleOverlayDefsSection = () => {
    this.setState(prevState => ({
      isOverlayDefsExpanded: !prevState.isOverlayDefsExpanded
    }));
  }

  // Update the render method with new column layout
  render() {
    const { background, texts, overlays, name, resize_x, resize_y, imageUrl, _id, 
            isWorking, isJsonExpanded, isImageExpanded, isAdmin, copyMessage, availableFonts,
             } = this.state;

   

    return (
    <Container fluid className="meme-maker-container">
        <WorkingOverlay isWorking={isWorking} message="WORKING" />
        <Row className="meme-maker-row">
            {/* Left Column - WYSIWYG Preview - now with responsive classes */}
            <Col md={6} className="wysiwyg-column mb-4 mb-md-0">
                <div className="wysiwyg-container">
                    {background && (
                        <div className="wysiwyg-preview">
                            <img 
                                src={background} 
                                alt="Background" 
                                onLoad={this.handleImageLoad}
                                crossOrigin="anonymous"
                                style={{ 
                                    width: resize_x || 'auto',
                                    height: resize_y || 'auto',
                                    maxWidth: '100%' // Ensure it doesn't overflow
                                }} 
                            />
                            <div className="text-overlay-container">
                                {texts.map((text, index) => (
                                    <TextOverlay
                                        key={`text-${index}`}
                                        text={text}
                                        index={index}
                                        imageWidth={this.state.imageWidth || resize_x || 500}
                                        imageHeight={this.state.imageHeight || resize_y || 500}
                                        onPositionChange={this.handlePositionChange}
                                        webFontMapping={this.state.webFontMapping} // Pass the mapping here
                                    />
                                ))}
                                {overlays.map((overlay, index) => (
                                    <ImageOverlay
                                        key={`overlay-${index}`}
                                        overlay={overlay}
                                        index={index}
                                        imageWidth={this.state.imageWidth || resize_x || 500}
                                        imageHeight={this.state.imageHeight || resize_y || 500}
                                        onPositionChange={this.handleOverlayPositionChange}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                
                {/* Mobile-friendly display for the resulting image */}
                {imageUrl && (
                  <div className="json-preview mb-3">
                    <div className="json-preview-header d-flex justify-content-between align-items-center" onClick={this.toggleImagePreview}>
                      <h6 className="mb-0">API Generated Image</h6>
                      <span>{isImageExpanded ? '▼' : '▶'}</span>
                    </div>
                    {isImageExpanded && (
                      <div className="mt-3">
                        <img src={imageUrl} alt="Generated Meme" style={{ maxWidth: '100%', height: 'auto' }} />
                      </div>
                    )}
                  </div>
                )}

                {/* Status message display */}
                <Row>
                    <Col>
                      <div className="status-message" style={{ 
                        height: '1.5rem',
                        opacity: copyMessage ? 1 : 0,
                        transition: 'opacity 0.3s ease-in-out'
                      }}>
                        {copyMessage}
                      </div>
                    </Col>
                </Row>
            </Col>

            {/* Right column - now properly responsive */}
            <Col md={6} className="form-column">
  <Container className="form-container">
    <WorkingOverlay isWorking={isWorking} message="WORKING" />
    
    {isAdmin && ( // Only show for admins
      <div className="meme-basics-panel mb-4">
        <div 
          className="meme-basics-header d-flex justify-content-between align-items-center p-3"
          onClick={this.toggleMemeBasics}
          style={{ 
            cursor: 'pointer', 
            backgroundColor: '#f8f9fa',
            borderBottom: this.state.isMemeBasicsExpanded ? '1px solid #dee2e6' : 'none',
            borderRadius: '.25rem',
            boxShadow: '0 1px 2px rgba(0,0,0,.05)',
            transition: 'background-color 0.2s'
          }}
        >
          <h5 className="mb-0">
            <FontAwesomeIcon 
              icon={this.state.isMemeBasicsExpanded ? 'fa-chevron-down' : 'fa-chevron-right'} 
              className="me-2" 
            />
            Meme Definition Settings
          </h5>
          <span>{this.state.isMemeBasicsExpanded ? '▼' : '▶'}</span>
        </div>
           
        
        {this.state.isMemeBasicsExpanded && (
          <div className="meme-basics-body p-3" style={{ 
            border: '1px solid #dee2e6', 
            borderTop: 'none', 
            borderRadius: '0 0 .25rem .25rem'
          }}>
            {/* Name Input */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>Name:</Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={this.handleTopLevelChange('name')}
                  placeholder="Enter meme name"
                />
              </Col>
            </Form.Group>

            {/* Background Input */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>Background:</Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  value={background}
                  onChange={this.handleTopLevelChange('background')}
                  placeholder="Enter background image URL"
                />
              </Col>
            </Form.Group>

            {/* Animated Checkbox */}
<Form.Group as={Row} className="mb-3">
  <Form.Label column sm={2}>Animated:</Form.Label>
  <Col sm={10}>
    <Form.Check 
      id="animated-checkbox"
      type="checkbox"
      checked={this.state.animated}
      onChange={(e) => {
        const isAnimated = e.target.checked;
        this.setState({ animated: isAnimated });
      }}
      disabled={background && background.toLowerCase().endsWith('.gif')} // Disable for GIFs
      label={background && background.toLowerCase().endsWith('.gif') ? 
        "GIF backgrounds are always animated" : 
        "Is this an animated meme?"}
      className="mt-2"
    />
  </Col>
</Form.Group>

            {/* Resize X and Y Inputs */}
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>Resize:</Form.Label>
              <Col sm={10}>
                <div className="resize-controls">
                  <div className="resize-input-group">
                    <Form.Label>W:</Form.Label>
                    <Form.Control
                      className="like-number"
                      type="text"
                      value={resize_x === null ? '' : resize_x}
                      onChange={this.handleTopLevelChange('resize_x')}
                      placeholder=""
                    />
                  </div>
                  <div className="resize-input-group">
                    <Form.Label>H:</Form.Label>
                    <Form.Control
                      className="like-number"
                      type="text"
                      value={resize_y === null ? '' : resize_y}
                      onChange={this.handleTopLevelChange('resize_y')}
                      placeholder=""
                    />
                  </div>
                </div>
              </Col>
            </Form.Group>
            
            {/* Add admin buttons at the bottom of the Meme Definition Settings panel */}
            <div className="admin-buttons mt-4 d-flex flex-wrap">
              <Button 
                variant="primary" 
                onClick={this.handleCreateUpdate} 
                className="me-2 mb-2"
              >
                <FontAwesomeIcon icon={faSave} className="me-1" /> {_id ? 'Update' : 'Create'} Definition
              </Button>
              
              {_id && (
                <Button 
                  variant="danger" 
                  onClick={this.handleDelete} 
                  className="me-2 mb-2"
                >
                  <FontAwesomeIcon icon={faTrash} className="me-1" /> Delete Definition
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    )}

    <div className='meme-button-container d-flex flex-wrap'>
  <Button 
    variant="secondary" 
    onClick={this.copyCanvasToClipboard} 
    className="me-2 mb-2 flex-fill"
    disabled={!background}
  >
    <FontAwesomeIcon icon={faClipboard} className="me-1" /> Copy
  </Button>

  <Button 
    variant="secondary" 
    onClick={this.uploadCanvasImage} 
    className="me-2 mb-2 flex-fill"
    disabled={!background}
  >
    <FontAwesomeIcon icon={faUpload} className="me-1" /> Upload
  </Button>

  <Button 
    variant="success" 
    onClick={this.handleGenerateImage} 
    className="mb-2 flex-fill"
    disabled={!background}
    title={background ? "Generate meme using API" : "Please add a background image first"}
  >
    <FontAwesomeIcon icon={faPaintbrush} className="me-1" /> Generate
  </Button>
</div>

    {/* Text Section */}
    <div className="meme-texts-panel mb-4">
      <div 
        className="meme-panel-header d-flex justify-content-between align-items-center p-3"
        onClick={this.toggleTextDefsSection}
        style={{ 
          cursor: 'pointer', 
          backgroundColor: '#f8f9fa',
          borderBottom: this.state.isTextDefsExpanded ? '1px solid #dee2e6' : 'none',
          borderRadius: '.25rem',
          boxShadow: '0 1px 2px rgba(0,0,0,.05)',
          transition: 'background-color 0.2s'
        }}
      >
        <h5 className="mb-0 d-flex align-items-center">
          <FontAwesomeIcon 
            icon={this.state.isTextDefsExpanded ? faChevronDown : faChevronRight} 
            className="me-2" 
          />
          Text Elements ({texts.length})
        </h5>
        <div>
          <Button 
            variant="primary" 
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              this.handleAddText();
            }}
            className="me-2"
          >
            <FontAwesomeIcon icon={faPlus} className="me-1" /> Add Text
          </Button>
          <span>{this.state.isTextDefsExpanded ? '▼' : '▶'}</span>
        </div>
      </div>
      
      {this.state.isTextDefsExpanded && (
        <div className="meme-panel-body p-3" style={{ 
          border: '1px solid #dee2e6', 
          borderTop: 'none', 
          borderRadius: '0 0 .25rem .25rem'
        }}>
          <TextDef 
            textDefs={texts} 
            signalChange={this.onTextsChange} 
            disableDelete={false}
            availableFonts={availableFonts}
          />
        </div>
      )}
    </div>

    {/* Overlay Section - Always visible now, not conditionally based on animated status */}
<div className="meme-overlays-panel mb-4">
  <div 
    className="meme-panel-header d-flex justify-content-between align-items-center p-3"
    onClick={this.toggleOverlayDefsSection}
    style={{ 
      cursor: 'pointer', 
      backgroundColor: '#f8f9fa',
      borderBottom: this.state.isOverlayDefsExpanded ? '1px solid #dee2e6' : 'none',
      borderRadius: '.25rem',
      boxShadow: '0 1px 2px rgba(0,0,0,.05)',
      transition: 'background-color 0.2s'
    }}
  >
    <h5 className="mb-0 d-flex align-items-center">
      <FontAwesomeIcon 
        icon={this.state.isOverlayDefsExpanded ? faChevronDown : faChevronRight} 
        className="me-2" 
      />
      Image Overlays ({overlays.length})
    </h5>
    <div>
      <Button 
        variant="primary" 
        size="sm"
        onClick={(e) => {
          e.stopPropagation();
          this.handleAddOverlay();
        }}
        className="me-2"
      >
        <FontAwesomeIcon icon={faPlus} className="me-1" /> Add Overlay
      </Button>
      <span>{this.state.isOverlayDefsExpanded ? '▼' : '▶'}</span>
    </div>
  </div>
  
  {this.state.isOverlayDefsExpanded && (
    <div className="meme-panel-body p-3" style={{ 
      border: '1px solid #dee2e6', 
      borderTop: 'none', 
      borderRadius: '0 0 .25rem .25rem'
    }}>
      <OverlayDef 
        overlayDefs={overlays}
        signalChange={this.onOverlaysChange} 
      />
    </div>
  )}
</div>

       {/* Collapsible JSON Preview */}
{ isAdmin && (
  <div className="json-preview">
    <div className="json-preview-header d-flex justify-content-between align-items-center" onClick={this.toggleJsonPreview}>
      <h6 className="mb-0">JSON Definition</h6>
      <span>{isJsonExpanded ? '▼' : '▶'}</span>
    </div>
    {isJsonExpanded && (
      <>
        <div className="mb-2 mt-2">
          <Button 
            variant="secondary" 
            size="sm"
            onClick={this.handleCopyDefinition} 
          >
            <FontAwesomeIcon icon={faCopy} className="me-1" /> Copy Definition
          </Button>
        </div>
        <Container className="meme-def-output" style={{ overflowX: 'auto' }}>
          <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'normal' }}>
            {JSON.stringify(this.generatePayload(), null, "\t")}
          </pre>
        </Container>
      </>
    )}
  </div>
)}

    
  </Container>
</Col>
        </Row>
    </Container>
);
}
}

export default MemeMaker;