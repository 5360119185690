import React, { Component, createRef } from 'react';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import './TextOverlay.css';

class TextOverlay extends Component {
    constructor(props) {
        super(props);
        const { text } = props;
        
        // Format colors immediately during initialization
        const formattedTextColor = this.formatColor(text.color);
        // Use fully transparent background if bgcolor is empty, null, etc.
        const formattedBgColor = text.bgcolor ? this.formatColor(text.bgcolor) : 'transparent';
        const formattedOutlineColor = text.outline ? this.formatColor(text.outline) : null;
        
        this.state = {
            position: {
                x: typeof text.pos.x === 'number' ? text.pos.x : 0,
                y: typeof text.pos.y === 'number' ? text.pos.y : 0
            },
            prevPropsHash: this.getPropPositionHash(props.text),
            isResizing: false,
            isDragging: false, // Add isDragging state
            formattedColors: {
                textColor: formattedTextColor,
                bgColor: formattedBgColor,
                outlineColor: formattedOutlineColor
            },
            fontSize: 16, // Default font size
            lineHeight: 1.2 // Default line height (same as PYTHON_LINE_SPACING)
        };
        
        // Create ref for the draggable element
        this.draggableRef = createRef();
        // Create ref for the text element to calculate optimal size
        this.textRef = createRef();
        this.resizableRef = createRef(); // Add a ref for the resizable component
        this.overlayRef = createRef(); // Add a ref for the overlay element
    }

    // Format color method
    formatColor = (color) => {
        if (!color) {
            return '#000000';
        }
        
        try {
            if (typeof color !== 'string') {
                return '#000000';
            }
            
            if (color.startsWith('#')) {
                return color;
            }
            else {
                return `#${color}`;
            }
        } catch (e) {
            console.error('Error formatting color:', e);
            return '#000000';
        }
    };

    getPropPositionHash = (text) => {
        return `${text.pos.x}-${text.pos.y}-${text.pos.w}-${text.pos.h}-${text.pos.rotation_angle || 0}`;
    };

    calculateOptimalFontSize = () => {
        if (!this.textRef.current) return;

        const { text } = this.props;
        const { pos } = text;
        const boxWidth = typeof pos.w === 'number' ? pos.w : 100;
        const boxHeight = typeof pos.h === 'number' ? pos.h : 50;
        
        const displayText = text.upperCase ? (text.text || '').toUpperCase() : (text.text || '');
        
        // Define constants like in Python
        const PADDING_INSIDE = 10;
        const MIN_FONT_SIZE = 10;
        const MAX_FONT_SIZE = 80;
        const LINE_SPACING = this.state.lineHeight; // Same as Python
        
        // Get the specified font or use a default
        const fontFamily = text.font || 'Arial, sans-serif';
        
        // Simulate the binary search from the Python function
        let minSize = MIN_FONT_SIZE;
        let maxSize = MAX_FONT_SIZE;
        let bestSize = MIN_FONT_SIZE;
        
        // Create temporary div for testing
        const testDiv = document.createElement('div');
        testDiv.style.position = 'absolute';
        testDiv.style.visibility = 'hidden';
        testDiv.style.width = `${boxWidth - 2 * PADDING_INSIDE}px`;
        testDiv.style.fontFamily = fontFamily;
        testDiv.style.fontWeight = 'bold';
        testDiv.style.wordBreak = 'break-word';
        testDiv.style.whiteSpace = 'pre-wrap'; // Preserve newlines
        testDiv.style.textAlign = 'center';
        document.body.appendChild(testDiv);
        
        // Binary search for best font size
        while (minSize <= maxSize) {
            const midSize = Math.floor((minSize + maxSize) / 2);
            testDiv.style.fontSize = `${midSize}px`;
            testDiv.style.lineHeight = `${LINE_SPACING * midSize}px`;
            testDiv.textContent = displayText;
            
            if (testDiv.offsetHeight <= (boxHeight - 2 * PADDING_INSIDE) && 
                this.checkWordsWidth(testDiv, boxWidth - 2 * PADDING_INSIDE)) {
                bestSize = midSize;
                minSize = midSize + 1;
            } else {
                maxSize = midSize - 1;
            }
        }
        
        // Clean up
        document.body.removeChild(testDiv);
        
        if (bestSize !== this.state.fontSize) {
            this.setState({ fontSize: bestSize });
        }
    };
    
    // Helper function to check if any word exceeds box width
    checkWordsWidth = (element, maxWidth) => {
        // Split text into lines first (respect newlines)
        const lines = element.textContent.split('\n');
        
        const testSpan = document.createElement('span');
        testSpan.style.font = window.getComputedStyle(element).font;
        testSpan.style.visibility = 'hidden';
        document.body.appendChild(testSpan);
        
        // Check each line separately
        for (let line of lines) {
            const words = line.split(' ');
            
            for (let word of words) {
                testSpan.textContent = word;
                if (testSpan.offsetWidth > maxWidth) {
                    document.body.removeChild(testSpan);
                    return false;
                }
            }
        }
        
        document.body.removeChild(testSpan);
        return true;
    };

    componentDidMount() {
        const { text } = this.props;
        this.setState({
            formattedColors: {
                textColor: this.formatColor(text.color),
                // Use fully transparent background if bgcolor is empty
                bgColor: text.bgcolor ? this.formatColor(text.bgcolor) : 'transparent',
                outlineColor: text.outline ? this.formatColor(text.outline) : null
            }
        }, () => {
            this.calculateOptimalFontSize();
        });
        
        // Listen for window resize to recalculate font size
        window.addEventListener('resize', this.calculateOptimalFontSize);
        // Add event listener for capture mode
        window.addEventListener('beforecapture', this.prepareForCapture);
    }

    componentWillUnmount() {
        // Clean up event listener
        window.removeEventListener('resize', this.calculateOptimalFontSize);
        window.removeEventListener('beforecapture', this.prepareForCapture);
    }

    componentDidUpdate(prevProps) {
        const { text } = this.props;
        const prevText = prevProps.text;
        
        // Check if colors have changed
        if (text.color !== prevText.color || 
            text.bgcolor !== prevText.bgcolor || 
            text.outline !== prevText.outline) {
            
            this.setState({
                formattedColors: {
                    textColor: this.formatColor(text.color),
                    // Use fully transparent background if bgcolor is empty
                    bgColor: text.bgcolor ? this.formatColor(text.bgcolor) : 'transparent',
                    outlineColor: text.outline ? this.formatColor(text.outline) : null
                }
            });
        }
        
        // Handle position changes
        const newPropHash = this.getPropPositionHash(text);
        const prevHash = this.state.prevPropsHash;
        
        if (!this.state.isResizing && newPropHash !== prevHash && 
            (text.pos.x !== prevProps.text.pos.x || 
             text.pos.y !== prevProps.text.pos.y || 
             text.pos.w !== prevProps.text.pos.w || 
             text.pos.h !== prevProps.text.pos.h)) {
            
            this.setState({
                position: {
                    x: typeof text.pos.x === 'number' ? text.pos.x : 0,
                    y: typeof text.pos.y === 'number' ? text.pos.y : 0
                },
                prevPropsHash: newPropHash
            }, () => {
                // Recalculate optimal font size when box dimensions change
                this.calculateOptimalFontSize();
            });
        }
        
        // Check if text content, font, or styling changed - AND TRIGGER FONT RECALCULATION
        if (text.text !== prevText.text || 
            text.font !== prevText.font ||  // This catches font changes
            text.upperCase !== prevText.upperCase) {
            
            //console.log(`Font changed from "${prevText.font}" to "${text.font}"`);
            this.calculateOptimalFontSize();
        }
    }

    handleDragStop = (e, data) => {
        if (this.state.isResizing) return;
        
        try {
            const previewEl = e.target.closest('.wysiwyg-preview');
            if (!previewEl) {
                this.updatePosition(data.x, data.y);
                return;
            }

            const rect = previewEl.getBoundingClientRect();
            const imageEl = previewEl.querySelector('img');
            
            if (!imageEl) {
                this.updatePosition(data.x, data.y);
                return;
            }

            const imageRect = imageEl.getBoundingClientRect();
            const relativeX = data.x - (imageRect.left - rect.left);
            const relativeY = data.y - (imageRect.top - rect.top);

            this.updatePosition(relativeX, relativeY);
        } catch (error) {
            console.warn('Error during drag operation:', error);
            this.updatePosition(data.x, data.y);
        }
        
        // Reset dragging state after a short delay
        setTimeout(() => {
            this.setState({ isDragging: false });
        }, 100);
    };

    updatePosition = (x, y) => {
        const { text, imageWidth, imageHeight, index, onPositionChange } = this.props;
        const width = typeof text.pos.w === 'number' ? text.pos.w : 100;
        const height = typeof text.pos.h === 'number' ? text.pos.h : 50;

        const newX = Math.max(0, Math.min(x, imageWidth - width));
        const newY = Math.max(0, Math.min(y, imageHeight - height));

        // Generate new hash that includes our changes
        const newPropHash = `${newX}-${newY}-${text.pos.w}-${text.pos.h}`;
        
        // First update local state
        this.setState({
            position: { x: newX, y: newY },
            prevPropsHash: newPropHash
        });

        // Then update parent
        onPositionChange(index, {
            x: newX,
            y: newY
        });
    };

    handleResizeStop = (e, { size }) => {
        // Prevent event bubbling
        e.stopPropagation();
        
        const { index, onPositionChange, imageWidth, imageHeight } = this.props;
        const { position } = this.state;
        const { pos } = this.props.text;
        
        // Calculate new dimensions
        const newWidth = Math.min(size.width, imageWidth - position.x);
        const newHeight = Math.min(size.height, imageHeight - position.y);
        const rotationAngle = pos.rotation_angle || 0;
        
        // Generate new hash that includes resize changes
        const newPropHash = `${position.x}-${position.y}-${newWidth}-${newHeight}-${rotationAngle}`;
        
        // Update state and reset resize flag after a short delay
        this.setState({ 
            prevPropsHash: newPropHash,
            isResizing: false 
        }, () => {
            // Recalculate font size after resize is complete
            
            this.calculateOptimalFontSize();
            
            // Add a small delay before allowing dragging again
            setTimeout(() => {
                this.setState({ isDragging: false });
            }, 100);
        });
        
        // Update parent with just the size change
        onPositionChange(index, {
            w: newWidth,
            h: newHeight
        });
    };

    // Add this method to map server font names to web font names
    mapFontToWebFont = (serverFontName) => {
        const { webFontMapping } = this.props;
        
        if (!webFontMapping || !serverFontName) {
            return 'Impact, fantasy'; // Default fallback
        }
        
        return webFontMapping[serverFontName] || 'Impact, fantasy';
    };

    // Add handleDragStart method that's missing
    handleDragStart = (e) => {
        // Only allow drag if not currently resizing
        if (this.state.isResizing) {
            e.stopPropagation();
            return false;
        }
        this.setState({ isDragging: true });
    };

    // Add handleResizeStart method that's missing
    handleResizeStart = (e, data) => {
        // Prevent event bubbling to avoid triggering drag
        e.stopPropagation();
        e.preventDefault();
        this.setState({ isResizing: true });
    };

    prepareForCapture = () => {
        if (this.overlayRef.current) {
            this.overlayRef.current.style.border = '0';
            this.overlayRef.current.style.outline = 'none';
            this.overlayRef.current.style.boxShadow = 'none';
        }
    };

    // Update the render method to use the mapped font
    render() {
        const { text, index, imageWidth, imageHeight, webFontMapping } = this.props;
        const { pos } = text;
        const { position, formattedColors, fontSize, isResizing } = this.state;

        const width = typeof pos.w === 'number' ? pos.w : 100;
        const height = typeof pos.h === 'number' ? pos.h : 50;
        const rotationAngle = typeof pos.rotation_angle === 'number' ? pos.rotation_angle : 0;
        
        const { textColor, bgColor, outlineColor } = formattedColors;
        
        // Map the server font name to web font name
        const serverFontName = text.font || 'Impact.ttf';
        const fontFamily = webFontMapping && webFontMapping[serverFontName] 
            ? webFontMapping[serverFontName] 
            : serverFontName || 'Impact, fantasy';
        
        // Get the raw text content
        const rawText = text.text || `Text ${index + 1}`;
        const displayText = text.upperCase ? rawText.toUpperCase() : rawText;
        
        // Process text to handle newlines
        const processedText = displayText.split('\n').map((line, i) => (
            <React.Fragment key={i}>
                {line}
                {i < displayText.split('\n').length - 1 && <br />}
            </React.Fragment>
        ));
        
        // Set up text styles with text stroke/outline if specified
        const textStyle = {
            color: textColor,
            backgroundColor: bgColor,
            fontFamily: fontFamily,
            fontSize: `${fontSize}px`,
            lineHeight: `${this.state.lineHeight}`,
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            padding: '10px',
            boxSizing: 'border-box',
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            wordBreak: 'break-word',
            cursor: isResizing ? 'auto' : 'move',
            // Apply rotation transform
            transform: `rotate(${rotationAngle}deg)`,
            transformOrigin: 'center center',
            // Only show the border when we're not capturing a screenshot
            border: document.querySelector('.capturing-screenshot') ? 'none' : '2px dashed transparent',
            // Update white-space property to respect newlines but still wrap text
            whiteSpace: 'pre-wrap'
        };
        
        // Add text stroke/outline effect if specified
        if (outlineColor) {
            const outlineWidth = text.outline_wt || 1;
            const strokeWidth = Math.max(1, Math.min(outlineWidth, 5)); // Limit between 1 and 5px
            
            textStyle.textShadow = `
                -${strokeWidth}px -${strokeWidth}px 0 ${outlineColor},
                0 -${strokeWidth}px 0 ${outlineColor},
                ${strokeWidth}px -${strokeWidth}px 0 ${outlineColor},
                ${strokeWidth}px 0 0 ${outlineColor},
                ${strokeWidth}px ${strokeWidth}px 0 ${outlineColor},
                0 ${strokeWidth}px 0 ${outlineColor},
                -${strokeWidth}px ${strokeWidth}px 0 ${outlineColor},
                -${strokeWidth}px 0 0 ${outlineColor}
            `;
        }
        
        // Set up resize and drag interaction modes
        const isDragDisabled = isResizing;
        
        return (
            <Draggable
                nodeRef={this.draggableRef}
                position={position}
                onStart={this.handleDragStart}
                onStop={this.handleDragStop}
                bounds="parent"
                grid={[5, 5]}
                disabled={isDragDisabled}
                cancel=".react-resizable-handle"
            >
                <div 
                    ref={this.draggableRef}
                    style={{ position: 'absolute', padding: 0, margin: 0 }}
                >
                    <ResizableBox
                        width={width}
                        height={height}
                        onResizeStart={this.handleResizeStart}
                        onResizeStop={this.handleResizeStop}
                        minConstraints={[50, 20]}
                        maxConstraints={[
                            Math.max(50, imageWidth - position.x),
                            Math.max(20, imageHeight - position.y)
                        ]}
                        resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
                        handleSize={[8, 8]}
                    >
                        <div 
                            ref={this.textRef}
                            className="text-overlay-box"
                            style={textStyle}
                        >
                            {processedText}
                        </div>
                    </ResizableBox>
                </div>
            </Draggable>
        );
    }
}

export default TextOverlay;