import React, { Component } from 'react';
import { Form, InputGroup, Button, ButtonGroup, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceSmile, faTimes } from '@fortawesome/free-solid-svg-icons';
import MemeFacePickerModal from './MemeFacePickerModal';

class MemeFacePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMemeFacePicker: false
        };
    }

    toggleMemeFacePicker = () => {
        this.setState(prevState => ({
            showMemeFacePicker: !prevState.showMemeFacePicker
        }));
    };

    handleMemeFacePicked = (memeFace) => {
        if (this.props.onMemeFaceSelect) {
            this.props.onMemeFaceSelect(memeFace);
        }
        this.setState({ showMemeFacePicker: false });
    };

    handleClear = () => {
        if (this.props.onClear) {
            this.props.onClear();
        }
    };

    render() {
        const { url, selectedMemeFace, label, readOnly = true, placeholder, onChange } = this.props;
        const { showMemeFacePicker } = this.state;

        return (
            <>
                {label && <Form.Label>{label}</Form.Label>}
                <InputGroup>
                    <Form.Control 
                        type="text"
                        value={url}
                        onChange={onChange}
                        readOnly={readOnly}
                        placeholder={placeholder}
                        onPaste={this.props.onPaste}
                    />
                    <ButtonGroup>
                        <Button 
                            variant="secondary" 
                            onClick={this.toggleMemeFacePicker}
                            style={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                padding: selectedMemeFace ? '0.25rem' : undefined 
                            }}
                        >
                            {selectedMemeFace ? (
                                <div style={{ 
                                    height: '24px', 
                                    width: '24px', 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center' 
                                }}>
                                    <Image 
                                        src={selectedMemeFace.thumb} 
                                        alt={selectedMemeFace.name} 
                                        style={{ 
                                            maxHeight: '100%', 
                                            maxWidth: '100%', 
                                            objectFit: 'contain' 
                                        }} 
                                    />
                                </div>
                            ) : (
                                <>
                                    <FontAwesomeIcon icon={faFaceSmile} className="me-1" /> Face
                                </>
                            )}
                        </Button>
                        {selectedMemeFace && (
                            <Button 
                                variant="secondary" 
                                onClick={this.handleClear}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>
                        )}
                    </ButtonGroup>
                </InputGroup>
                {selectedMemeFace && (
                    <div className="mt-1">
                        <small className="text-muted">{selectedMemeFace.name}</small>
                    </div>
                )}

                {/* Meme Face Picker Modal */}
                <MemeFacePickerModal
                    show={showMemeFacePicker}
                    onHide={this.toggleMemeFacePicker}
                    onMemeFaceSelect={this.handleMemeFacePicked}
                />
            </>
        );
    }
}

export default MemeFacePicker;