import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button, Form, ButtonGroup, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faCopy, 
  faCheck, 
  faLink, 
  faUpload, 
  faFileImport, 
  faBrain 
} from "@fortawesome/free-solid-svg-icons";
import { getApiPath, copyToClipboard } from "./Utils.js";
import './UploadModal.css';

const UploadModal = ({ 
  show, 
  onHide, 
  initialFile = null,
  onUploadComplete = () => {} 
}) => {
  // File upload states
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadedUrl, setUploadedUrl] = useState("");
  const [uploadCopied, setUploadCopied] = useState(false);
  
  const [selectedFile, setSelectedFile] = useState(initialFile);
  const [pastedFile, setPastedFile] = useState(null);
  const [pastedPreview, setPastedPreview] = useState(null);
  const [mobileToastVisible, setMobileToastVisible] = useState(false);
  
  // URL caching states
  const [url, setUrl] = useState("");
  const [cacheLoading, setCacheLoading] = useState(false);
  const [smartCacheLoading, setSmartCacheLoading] = useState(false);
  
  const mobileTextAreaRef = useRef(null);
  const fileInputRef = useRef(null);
  
  // If initialFile changes, update selectedFile
  useEffect(() => {
    if (initialFile) {
      setSelectedFile(initialFile);
      
      // If it's an image, create a preview
      if (initialFile.type.startsWith('image/')) {
        setPastedPreview(URL.createObjectURL(initialFile));
        setPastedFile(initialFile);
      }
    }
  }, [initialFile]);
  
  // Clean up any object URLs when component unmounts
  useEffect(() => {
    return () => {
      if (pastedPreview) {
        URL.revokeObjectURL(pastedPreview);
      }
    };
  }, [pastedPreview]);
  
  const handleFilePickerInputChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
      
      // If it's an image, create a preview
      if (e.target.files[0].type.startsWith('image/')) {
        const previewUrl = URL.createObjectURL(e.target.files[0]);
        // Clean up old preview URL if it exists
        if (pastedPreview) {
          URL.revokeObjectURL(pastedPreview);
        }
        setPastedPreview(previewUrl);
        setPastedFile(e.target.files[0]);
      } else {
        // If it's not an image (e.g., video), clear the preview
        if (pastedPreview) {
          URL.revokeObjectURL(pastedPreview);
          setPastedPreview(null);
        }
        setPastedFile(null);
      }
    }
  };
  
  const handlePasteInUploadModal = (e) => {
    const items = e.clipboardData?.items;
    if (!items) return;

    // Look for image items
    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        const file = item.getAsFile();
        if (file) {
          e.preventDefault(); // Prevent default paste
          
          // Clean up old preview URL if it exists
          if (pastedPreview) {
            URL.revokeObjectURL(pastedPreview);
          }
          
          // Create a preview URL
          const previewUrl = URL.createObjectURL(file);
          
          // Update state with file and preview
          setPastedFile(file);
          setPastedPreview(previewUrl);
          setSelectedFile(file);
          
          break;
        }
      }
    }
  };
  
  const handleFileUploadSubmit = async () => {
    const fileToUpload = selectedFile || pastedFile;
    if (!fileToUpload) return;

    setUploadLoading(true);
    setUploadError(null);
    setUploadedUrl("");

    try {
      const formData = new FormData();
      formData.append("file", fileToUpload, fileToUpload.name || "pasted-image.png");

      const url = getApiPath("upload", localStorage.getItem("apiKey"), "");

      const response = await fetch(url, {
        method: "POST",
        body: formData
      });

      if (!response.ok) {
        throw new Error(`Upload failed with status: ${response.status}`);
      }

      const resultUrl = await response.text();

      setUploadedUrl(resultUrl);
      setUploadLoading(false);
      
      // Clear file selection state
      setSelectedFile(null);
      
      // Notify parent component that upload is complete
      onUploadComplete(resultUrl);

    } catch (error) {
      console.error("Upload error:", error);
      setUploadError(error.message);
      setUploadLoading(false);
    }
  };
  
  // New caching functions
  const handleCacheUrl = async () => {
    if (!url.trim()) return;

    setCacheLoading(true);
    setUploadError(null);
    setUploadedUrl("");

    try {
      const apiPath = getApiPath("cache", localStorage.getItem("apiKey"), url);
      const response = await fetch(apiPath);
      
      if (!response.ok) {
        throw new Error(`Cache failed with status: ${response.status}`);
      }

      const resultUrl = await response.text();
      setUploadedUrl(resultUrl);
      setCacheLoading(false);
      
      // Notify parent component that cache is complete
      onUploadComplete(resultUrl);
    } catch (error) {
      console.error("Cache error:", error);
      setUploadError(error.message);
      setCacheLoading(false);
    }
  };

  const handleSmartCacheUrl = async () => {
    if (!url.trim()) return;

    setSmartCacheLoading(true);
    setUploadError(null);
    setUploadedUrl("");

    try {
      const apiPath = getApiPath("smartcache", localStorage.getItem("apiKey"), url);
      const response = await fetch(apiPath);
      
      if (!response.ok) {
        throw new Error(`SmartCache failed with status: ${response.status}`);
      }

      const resultUrl = await response.text();
      setUploadedUrl(resultUrl);
      setSmartCacheLoading(false);
      
      // Notify parent component that smartCache is complete
      onUploadComplete(resultUrl);
    } catch (error) {
      console.error("SmartCache error:", error);
      setUploadError(error.message);
      setSmartCacheLoading(false);
    }
  };
  
  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleCacheUrl();
    }
  };
  
  const handleCopyUploadedUrl = async () => {
    const success = await copyToClipboard(uploadedUrl);
    if (success) {
      setUploadCopied(true);
      setTimeout(() => {
        setUploadCopied(false);
      }, 2000);
    }
  };
  
  const handleCloseModal = () => {
    // Clean up preview
    if (pastedPreview) {
      URL.revokeObjectURL(pastedPreview);
    }
    
    // Reset state
    setSelectedFile(null);
    setPastedFile(null);
    setPastedPreview(null);
    setUploadError(null);
    setUploadedUrl("");
    setUploadLoading(false);
    setCacheLoading(false);
    setSmartCacheLoading(false);
    setUrl("");
    
    // Call parent's onHide function
    onHide();
  };
  
  const handleReset = () => {
    // Clear URL input
    setUrl("");
    
    // Clear file selections
    setSelectedFile(null);
    setPastedFile(null);
    
    // Clear preview image
    if (pastedPreview) {
      URL.revokeObjectURL(pastedPreview);
      setPastedPreview(null);
    }
    
    // Clear any errors
    setUploadError(null);
    
    // Reset loading states
    setUploadLoading(false);
    setCacheLoading(false);
    setSmartCacheLoading(false);
  };

  const isLoading = uploadLoading || cacheLoading || smartCacheLoading;
  const disableControls = isLoading || !!uploadedUrl;
  
  return (
    <>
      {/* File Upload/URL Modal */}
      <Modal 
        show={show && !uploadedUrl} 
        onHide={handleCloseModal}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload or Cache Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* URL Input and Cache Section */}
            <div className="url-input-section">
              <Form.Group className="mb-2">
                <Form.Label><strong>Enter URL to Cache:</strong></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="https://example.com/image.jpg"
                  value={url}
                  onChange={handleUrlChange}
                  onKeyDown={handleKeyDown}
                  disabled={disableControls}
                />
              </Form.Group>
              
              <Row className="url-actions">
                <Col sm={12} md={8} lg={6} className="d-grid d-md-block">
                  <ButtonGroup>
                    <Button 
                      variant="primary"
                      onClick={handleCacheUrl}
                      disabled={!url.trim() || disableControls}
                      className="url-action-button"
                    >
                      {cacheLoading ? (
                        <>
                          <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                          Caching...
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faFileImport} className="me-1" />
                          Cache
                        </>
                      )}
                    </Button>
                    <Button 
                      variant="info"
                      onClick={handleSmartCacheUrl}
                      disabled={!url.trim() || disableControls}
                      className="url-action-button"
                    >
                      {smartCacheLoading ? (
                        <>
                          <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                          Processing...
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faBrain} className="me-1" />
                          Smart Cache
                        </>
                      )}
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </div>
            
            {/* Divider between URL and File Upload */}
            <div className="section-divider">
              <span className="divider-text">OR</span>
            </div>
            
            {/* File Upload Section */}
            <div className="file-upload-section">
              <Form.Group className="mb-3">
                <Form.Label><strong>Select Media:</strong></Form.Label>
                <Form.Text className="text-muted d-block mb-2">
                  Select an image or video file to upload. 100MB maximum.
                </Form.Text>
                <div className="d-grid">
                  <Button 
                    variant="outline-primary" 
                    className="w-100 universal-file-button"
                    onClick={() => {
                      if (disableControls) return;
                      // Trigger the file input
                      if (fileInputRef.current) {
                        fileInputRef.current.click();
                      }
                    }}
                    disabled={disableControls}
                  >
                    <FontAwesomeIcon icon={faUpload} className="me-2" />
                    {/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) 
                      ? "Choose from Gallery" 
                      : "Select File"}
                  </Button>
                  <Form.Control
                    type="file"
                    ref={fileInputRef}
                    accept="image/*,video/*"
                    style={{ display: 'none' }}
                    onChange={handleFilePickerInputChange}
                    disabled={disableControls}
                  />
                </div>
                <Form.Text className="text-muted mt-2">
                  Supported formats: Images (PNG, JPEG, GIF, WebP) and Videos (MP4, QuickTime)
                </Form.Text>
              </Form.Group>
              
              {/* Add Paste Area */}
              <Form.Group className="mb-3">
                <Form.Label><strong>Or paste an image:</strong></Form.Label>
                <div 
                  className="paste-area"
                  onClick={() => {
                    if (disableControls) return;
                    // On mobile, it's better to prompt the user with a hidden textarea
                    if (mobileTextAreaRef.current) {
                      mobileTextAreaRef.current.focus();
                      
                      // For iOS, we need to show the paste menu
                      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
                        document.execCommand('paste');
                      }
                    }
                  }}
                  onPaste={disableControls ? null : handlePasteInUploadModal}
                  style={{
                    opacity: disableControls ? 0.7 : 1,
                    cursor: disableControls ? 'not-allowed' : 'pointer'
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} size="2x" className="mb-2" />
                  <p className="mb-0">Click here and paste an image from your clipboard</p>
                  <small className="text-muted">(Tap and use your device's paste option)</small>
                  
                  {/* Hidden textarea for mobile paste support */}
                  <textarea 
                    ref={mobileTextAreaRef}
                    style={{
                      position: 'absolute',
                      left: '-9999px',
                      width: '1px',
                      height: '1px'
                    }}
                    onPaste={disableControls ? null : handlePasteInUploadModal}
                    onFocus={() => {
                      if (disableControls) return;
                      if (/Android/.test(navigator.userAgent)) {
                        // On Android, show a toast to guide the user
                        setMobileToastVisible(true);
                        
                        setTimeout(() => {
                          setMobileToastVisible(false);
                        }, 3000);
                      }
                    }}
                    disabled={disableControls}
                  />
                </div>
                
                {/* Mobile paste guide toast */}
                {mobileToastVisible && (
                  <div className="mobile-toast">
                    Tap and hold to paste
                  </div>
                )}
              </Form.Group>
              
              {/* Preview area for pasted image */}
              {pastedPreview && (
                <Form.Group className="mb-3">
                  <Form.Label><strong>Image preview:</strong></Form.Label>
                  <div className="text-center">
                    <img 
                      src={pastedPreview} 
                      alt="Preview" 
                      className="image-preview"
                    />
                  </div>
                </Form.Group>
              )}
              
              {/* Upload button for file uploads */}
              <div className="d-grid gap-2 mb-3">
                <Button 
                  variant="primary"
                  onClick={handleFileUploadSubmit}
                  disabled={(!selectedFile && !pastedFile) || disableControls}
                >
                  {uploadLoading ? (
                    <>
                      <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      Uploading...
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faUpload} className="me-1" />
                      Upload File
                    </>
                  )}
                </Button>
              </div>
            </div>
            
            {/* Error Display */}
            {uploadError && (
              <div className="alert alert-danger">
                <strong>Error:</strong> {uploadError}
              </div>
            )}

            {/* Reset Button */}
            <div className="d-flex justify-content-end mt-3">
              <Button 
                variant="outline-secondary"
                onClick={handleReset}
                disabled={disableControls}
              >
                Reset Form
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      
      {/* Upload/Cache Result Modal */}
      <Modal 
        show={show && !!uploadedUrl} 
        onHide={handleCloseModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Process Complete</Modal.Title>
        </Modal.Header>
        <Modal.Body className="result-section">
          <div className="alert alert-success mb-3">
            <FontAwesomeIcon icon={faCheck} className="me-2" />
            {uploadLoading ? "File uploaded successfully!" : 
             cacheLoading ? "URL cached successfully!" :
             "Smart cache completed successfully!"}
          </div>
          
          <Form.Group className="mb-3">
            <Form.Label><strong>Result URL:</strong></Form.Label>
            <div className="input-group">
              <Form.Control
                type="text" 
                value={uploadedUrl} 
                readOnly 
              />
              <Button 
                variant={uploadCopied ? "success" : "outline-secondary"}
                onClick={handleCopyUploadedUrl}
              >
                <FontAwesomeIcon 
                  icon={uploadCopied ? faCheck : faCopy} 
                  className="me-1" 
                />
                {uploadCopied ? "Copied!" : "Copy"}
              </Button>
            </div>
          </Form.Group>
          
          <div className="mt-3 text-center">
            <a 
              href={uploadedUrl} 
              target="_blank" 
              rel="noopener noreferrer"
              className="btn btn-sm btn-primary"
            >
              <FontAwesomeIcon icon={faLink} className="me-1" />
              Open File
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="warning" onClick={handleReset}>
            Reset
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadModal;
