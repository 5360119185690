import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ButtonGroup, Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';

const ContentTypeDropdown = ({ contentTypes, selectedTypes, onToggleType }) => {
  const [show, setShow] = useState(false);
  const buttonRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });

  // Update dropdown position when toggle button position changes
  const updatePosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width
      });
    }
  };

  // Update position and handle clicks outside
  useEffect(() => {
    if (show) {
      updatePosition();
      
      // Add window resize listener
      window.addEventListener('resize', updatePosition);
      
      // Add click outside handler
      const handleClickOutside = (event) => {
        if (buttonRef.current && !buttonRef.current.contains(event.target) && 
            !document.getElementById('content-type-dropdown-menu').contains(event.target)) {
          setShow(false);
        }
      };
      
      document.addEventListener('mousedown', handleClickOutside);
      
      return () => {
        window.removeEventListener('resize', updatePosition);
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [show]);

  // Toggle the dropdown
  const toggleDropdown = () => {
    setShow(!show);
  };

  // Handle type selection
  const handleTypeClick = (type) => {
    onToggleType(type);
  };

  // Create title text
  const titleText = "Type: " + (selectedTypes.length > 0 
    ? selectedTypes.join(', ') 
    : "(all)");

  // Render the dropdown menu in a portal
  const renderDropdownMenu = () => {
    if (!show) return null;

    return ReactDOM.createPortal(
      <div 
        id="content-type-dropdown-menu"
        className="dropdown-menu show"
        style={{
          position: 'absolute',
          top: `${dropdownPosition.top}px`,
          left: `${dropdownPosition.left}px`,
          width: `${dropdownPosition.width}px`,
          zIndex: 9999,
          display: 'block',
          maxHeight: '300px',
          overflowY: 'auto'
        }}
      >
        {contentTypes.map((type, index) => (
          <Dropdown.Item 
            key={`content_type_${index}`}
            onClick={() => handleTypeClick(type)}
            className="d-flex align-items-center"
          >
            <FontAwesomeIcon 
              icon={selectedTypes.includes(type) ? faSquareCheck : faSquare} 
              className="me-2"
            /> 
            {type}
          </Dropdown.Item>
        ))}
      </div>,
      document.body
    );
  };

  return (
    <div className="content-type-dropdown-container">
      <ButtonGroup ref={buttonRef}>
        <Button 
          variant="secondary"
          onClick={toggleDropdown}
          className="content-type-dropdown-button d-flex align-items-center justify-content-between"
        >
          <span>{titleText}</span>
        </Button>
      </ButtonGroup>
      
      {renderDropdownMenu()}
    </div>
  );
};

export default ContentTypeDropdown;