import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Memes from './Memes.js';
import { useEffect, useState } from 'react';
import { setUserStorageItem } from './Utils.js';
import MemeOverlay from './MemeOverlay';
import RemoveBackground from './RemoveBackground';

// import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Routes,  
  Route,
  Navigate,
  useLocation
} from "react-router-dom";

import AppHeader from './AppHeader';
// import Home from './Home';
import Settings from './Settings';
import Stash from './Stash';
import AppsClients from './AppsClients';
import ApiDocs from './ApiDocs';
import About from './About'
import Cannon from './Cannon';
import Shitbot from './Shitbot';
import MemeMaker from './MemeMaker.js';
import User from './User';
import Login from './Login';
import Signup from './Signup';

// Authenticated route component
const ProtectedRoute = ({ children }) => {
  const apiKey = localStorage.getItem('apiKey');
  const location = useLocation();
  
  // Public routes that don't require authentication
  const publicRoutes = [ '/login', '/signup', '/about'];
  const isPublicRoute = publicRoutes.includes(location.pathname);
  
  if (!apiKey && !isPublicRoute) {
    // Redirect to login if no API key and route requires authentication
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  
  return children;
};

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  console.log("checking if user is authenticated in appjs. authenticated: " + authenticated);

  useEffect(() => {
    const apiKey = localStorage.getItem('apiKey');
    if (apiKey === null)
    {
      return;
    }

    if (apiKey) {
      setUserStorageItem(apiKey);
      setAuthenticated(true);
    }
  }, []); // Empty dependency array means this runs once on component mount

  return (
    <Router>
      <div className="App">
        <AppHeader className="App-header"></AppHeader> 
        <div className="App-body">
          <Routes>
            {/* Public routes */}
            
            <Route path="/login" element={<Login setAuthenticated={setAuthenticated} />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/about" element={<About />} />
            <Route path="/api-docs" element={<ApiDocs />} />
            
            {/* Protected routes */}

            <Route path="" element={<Stash />} />
            
            <Route path="/settings" element={
              <ProtectedRoute><Settings /></ProtectedRoute>
            } />
            <Route path="/stash" element={
              <ProtectedRoute><Stash /></ProtectedRoute>
            } />
            <Route path="/apps" element={
              <ProtectedRoute><AppsClients /></ProtectedRoute>
            } />
            <Route path="/cannon" element={
              <ProtectedRoute><Cannon /></ProtectedRoute>
            } />
            <Route path="/shitbot" element={
              <ProtectedRoute><Shitbot /></ProtectedRoute>
            } />
            <Route path="/bot" element={
              <ProtectedRoute><Shitbot /></ProtectedRoute>
            } />
            <Route path="/meme" element={
              <ProtectedRoute><MemeMaker /></ProtectedRoute>
            } />
            <Route path="/memes" element={
              <ProtectedRoute><Memes /></ProtectedRoute>
            } />
            <Route path="/meme_overlay" element={
              <ProtectedRoute><MemeOverlay /></ProtectedRoute>
            } />
            <Route path="/user" element={
              <ProtectedRoute><User /></ProtectedRoute>
            } />
            <Route path="/remove-background" element={
              <ProtectedRoute><RemoveBackground /></ProtectedRoute>
            } />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
