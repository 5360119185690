import React, { Component } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import MemeFacePicker from './MemeFacePicker';
import './TextDef.css'; // Reuse TextDef styling

class OverlayDef extends Component {
  constructor(props) {
    super(props);
    
    // Initialize state to track which overlays have expanded advanced settings
    this.state = {
      expandedAdvanced: props.overlayDefs.map(() => false)
    };
  }
  
  handleOverlayChange = (index, field) => (event) => {
    // Create deep copy of the overlays array to avoid state mutations
    const newOverlays = this.props.overlayDefs.map(overlay => ({
      ...overlay,
      pos: { ...overlay.pos }  // Deep copy the pos object too
    }));
    
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    
    if (field === 'overlay_url' || field === 'hint') {
      newOverlays[index][field] = value || null; // Convert empty string to null
    } else if (field === 'round_corners') {
      newOverlays[index][field] = value;
    } else if (['x', 'y', 'w', 'h', 'rotation_angle'].includes(field)) {
      // Always convert to number, default to 0 if empty
      newOverlays[index].pos[field] = value === '' ? 0 : Number(value);
    }
    
    this.props.signalChange(newOverlays);
  };

  // New method to handle meme face selection
  handleMemeFaceSelect = (index) => (memeFace) => {
    const newOverlays = this.props.overlayDefs.map(overlay => ({
      ...overlay,
      pos: { ...overlay.pos }
    }));
    
    newOverlays[index].overlay_url = memeFace.objectUrl;
    
    // If the overlay doesn't have a hint yet, use the meme face name
    if (!newOverlays[index].hint || newOverlays[index].hint === '') {
      newOverlays[index].hint = memeFace.name;
    }
    
    this.props.signalChange(newOverlays);
  };

  // New method to handle clearing a meme face
  handleClearMemeFace = (index) => () => {
    const newOverlays = this.props.overlayDefs.map(overlay => ({
      ...overlay,
      pos: { ...overlay.pos }
    }));
    
    newOverlays[index].overlay_url = '';
    
    this.props.signalChange(newOverlays);
  };

  deleteOverlay = (index) => () => {
    const newOverlays = [...this.props.overlayDefs];
    newOverlays.splice(index, 1);
    this.props.signalChange(newOverlays);
    
    // Update expanded state as well
    const newExpanded = [...this.state.expandedAdvanced];
    newExpanded.splice(index, 1);
    this.setState({ expandedAdvanced: newExpanded });
  };
  
  // Toggle expanded state for an overlay entry
  toggleAdvancedSettings = (index) => {
    const newExpanded = [...this.state.expandedAdvanced];
    newExpanded[index] = !newExpanded[index];
    this.setState({ expandedAdvanced: newExpanded });
  };

  // Handle paste events for image URLs
  handlePaste = (index) => (event) => {
    // If we have a paste handler from props, use it
    if (this.props.onPaste) {
      this.props.onPaste(event, index);
    }
  };

  render() {
    const { overlayDefs } = this.props;

    return (
      <Container className="text-def-container">
        {overlayDefs.map((overlay, index) => {
          // Create a meme face object if we have a URL
          const selectedMemeFace = overlay.overlay_url ? {
            objectUrl: overlay.overlay_url,
            thumb: overlay.overlay_url,
            name: overlay.hint || `Overlay ${index + 1}`
          } : null;
          
          return (
            <div key={index} className="text-entry mb-3">
              <Row className="align-items-center">
                <Col xs={9}>
                  <h6 className="mb-0">Overlay #{index + 1}</h6>
                </Col>
                <Col xs={3} className="text-end">
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={this.deleteOverlay(index)}
                  >
                    <FontAwesomeIcon icon={faTrash} className="me-1" /> Delete
                  </Button>
                </Col>
              </Row>

              <Form.Group as={Row} className="mt-2">
                <Form.Label column xs={3}>Image:</Form.Label>
                <Col xs={9}>
                  <MemeFacePicker
                    label={null}
                    url={overlay.overlay_url || ''}
                    selectedMemeFace={selectedMemeFace}
                    readOnly={false}
                    placeholder="Enter image URL or select a meme face"
                    onChange={this.handleOverlayChange(index, 'overlay_url')}
                    onPaste={this.handlePaste(index)}
                    onMemeFaceSelect={this.handleMemeFaceSelect(index)}
                    onClear={this.handleClearMemeFace(index)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Check
                  type="checkbox"
                  label="Round Corners"
                  checked={overlay.round_corners || false}
                  onChange={this.handleOverlayChange(index, 'round_corners')}
                  className="ms-3 mt-2"
                />
              </Form.Group>
              
              {/* Advanced Settings Accordion */}
              <div className="mt-2 mb-2">
                <Button 
                  variant="outline-secondary" 
                  size="sm"
                  onClick={() => this.toggleAdvancedSettings(index)}
                  className="w-100 text-left d-flex justify-content-between align-items-center"
                >
                  <span>Position & Size</span>
                  <span>{this.state.expandedAdvanced[index] ? '▲' : '▼'}</span>
                </Button>
                
                {this.state.expandedAdvanced[index] && (
                  <div className="advanced-settings-container p-2 border border-top-0">

                    <Form.Group as={Row}>
                      <Form.Label column xs={3}>Hint:</Form.Label>
                      <Col xs={9}>
                        <Form.Control
                          type="text"
                          value={overlay.hint || ''}
                          onChange={this.handleOverlayChange(index, 'hint')}
                          placeholder="Enter hint text"
                        />
                      </Col>
                    </Form.Group>
                
                    <Form.Group as={Row}>
                      <Form.Label column sm={3}>X/Y:</Form.Label>
                      <Col sm={8}>
                        <div className="dimension-controls">
                          <div className="pos-input-group">
                            <Form.Label>X:</Form.Label>
                            <Form.Control
                              type="number"
                              value={overlay.pos.x ?? 0}
                              onChange={this.handleOverlayChange(index, 'x')}
                              placeholder="Enter X position"
                              className="pos-input"
                            />
                          </div>
                          
                          <div className="pos-input-group">
                            <Form.Label>Y:</Form.Label>
                            <Form.Control
                              type="number"
                              value={overlay.pos.y ?? 0}
                              onChange={this.handleOverlayChange(index, 'y')}
                              placeholder="Enter Y position"
                              className="pos-input"
                            />
                          </div>
                        </div>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm={3}>W/H:</Form.Label>
                      <Col sm={8}>
                        <div className="dimension-controls">
                          <div className="pos-input-group">
                            <Form.Label>W:</Form.Label>
                            <Form.Control
                              type="number"
                              value={overlay.pos.w ?? 0}
                              onChange={this.handleOverlayChange(index, 'w')}
                              placeholder="Enter width"
                              className="pos-input"
                            />
                          </div>

                          <div className="pos-input-group">
                            <Form.Label>H:</Form.Label>
                            <Form.Control
                              type="number"
                              value={overlay.pos.h ?? 0}
                              onChange={this.handleOverlayChange(index, 'h')}
                              placeholder="Enter height"
                              className="pos-input"
                            />
                          </div>
                        </div>
                      </Col>
                    </Form.Group>

                    {/* Add Rotation Angle Input */}
                    <Form.Group as={Row}>
                      <Form.Label column sm={3}>Rotation:</Form.Label>
                      <Col sm={8}>
                        <div className="dimension-controls">
                          <div className="pos-input-group">
                            <Form.Label>Angle:</Form.Label>
                            <Form.Control
                              type="number"
                              value={overlay.pos.rotation_angle ?? 0}
                              onChange={this.handleOverlayChange(index, 'rotation_angle')}
                              placeholder="Degrees"
                              className="pos-input"
                            />
                          </div>
                        </div>
                      </Col>
                    </Form.Group>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </Container>
    );
  }
}

export default OverlayDef;