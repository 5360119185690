import React, {Component} from "react";
import {  Modal, Button, Image, Form, InputGroup, Row, Col, ButtonGroup, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToilet, faCopy, faTags, faClose, faSave, faUndo, faPoo, faHardDrive, faFileLines, 
         faSquare, faTrash, faCheckSquare, faVideo, faImage, faUser, faX, faStopwatch, 
         faFileVideo, faLink, faPaintBrush, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import 'moment-timezone';

import {getApiPath, getTogglePostPath, humanFileSize, isShitpostDomain, getShortenedUrl} from "./Utils.js";
import TagPicker from './TagPicker';  // Add this import
import './Post.css';

class Post extends Component 
{
    constructor(props){
        super(props);
        this.state = {
            showModal: false,
            name: this.props.post.name,
            shortUrl: null,
            showDetails: false, // Add new state for collapsible details
        }
    }   
    decimalToMinutesAndSeconds(decimalSeconds) {
        // Convert the decimal seconds to minutes and seconds
        const totalSeconds = decimalSeconds % 60;
        const totalMinutes = (decimalSeconds - totalSeconds) / 60;
    
        // Format the minutes and seconds as a string
        const minutes = Math.floor(totalMinutes).toString().padStart(2, '0');
        const seconds = Math.floor(totalSeconds).toString().padStart(2, '0');
    
        // Return the formatted string
        return `${minutes}:${seconds}`;
    }

    showModal = () => {
        console.log("Opening modal for post:", this.props.post._id);
        this.setState({ showModal: true })
    }

    closeModal = () => {
        console.log("Closing modal");  // Add for debugging
        this.setState({showModal: false})
    }

    copy = (e) => {
        // Prevent default if event exists
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        
        const urlToCopy = this.state.shortUrl || this.props.post.objectUrl;
    
        if (navigator.clipboard) {
            navigator.clipboard.writeText(urlToCopy)
                .then(() => {
                    // Only close modal after successful copy
                    this.closeModal();
                })
                .catch(err => {
                    console.error("Copy failed:", err);
                    alert("Failed to copy to clipboard: " + err.message);
                });
        } else {
            alert("Cannot write to clipboard on this device/security context");
            this.closeModal();
        }
    }

   
    flush = () => {
        if (!window.confirm("Are you sure you want to delete this post?")) {
            return;
        }
        
        var apiPath = getApiPath("delete_post", localStorage.getItem("apiKey"), this.props.post._id);
        
        // First set showModal to false
        this.setState({ showModal: false }, () => {
            // Then initiate the delete request
            fetch(apiPath, { method: "POST" })
                .then((response) => {
                    if (response.ok) {
                        // Signal the parent component that the post was flushed
                        this.props.signalFlush(this.props.post);
                    } else {
                        alert("Error occurred while deleting the post");
                    }
                })
                .catch(error => {
                    console.error("Error deleting post:", error);
                    alert("Error occurred while deleting the post");
                });
        });
    }

    toggleTag = (tag) => {
        var path = getTogglePostPath(this.props.post._id, tag);

        fetch(path)
        .then(response => {
            if(response.ok)
            {
                this.props.signalTagToggle();
            }
            else{
                alert("Error toggling tag")
            }
        })
    }

    nameOnChange = (e) => {
        this.setState({
            name: e.target.value
        });
    }

    savePost = () => {
        var apiUrl = getApiPath("post", localStorage.getItem("apiKey"), this.props.post._id)

        fetch(apiUrl,
            {
                method: "post",
                headers: {
                    "Content-Type" : "application/json"
                },
                body: JSON.stringify(
                    {
                        name: this.state.name,
                        expires: null
                    })
            })
            .then( (response) => {
                if (response.ok){
                    this.props.signalPostSaved();
                }
                else{
                    alert("Error saving post")
                }
            })

    }

    resetNameEdits = () => {
        this.setState({ name: this.props.post.name })
    }

    handleKeyPress = (e) => {
        if (e.key === "Enter")
        {
            this.savePost();
        }
    }

    copyIconButtonClick = (e) => {        
        e.stopPropagation(); //cancel parent component's click event
        this.copy();
    }

    reOCR = () => {

        var apiUrl = getApiPath("ocr", localStorage.getItem("apiKey"), this.props.post._id)

        fetch(apiUrl)
        .then( (response) => {
            if(response.ok){
                this.props.signalPostSaved()
            }
            else{
                alert("error processing request\n" + response.text())
            }
        })
        

    }

    handleGetShortUrl = async () => {
        const result = await getShortenedUrl(this.props.post.objectUrl);
        if (result.success) {
            this.setState({ shortUrl: result.shortUrl });
        } else {
            alert('Failed to generate short URL: ' + result.error);
        }
    }

    handleClick = (e) => {
        // Stop event propagation regardless of mode
        e.preventDefault();
        e.stopPropagation();
        
        if (this.props.isSelectionMode) {
            // In selection mode, toggle selection
            this.props.onToggleSelection();
        } else {
            // Normal mode, explicitly show modal
            this.setState({ showModal: true });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Add this to debug modal state changes
        if (prevState.showModal !== this.state.showModal) {
            console.log("Modal state changed to:", this.state.showModal);
        }
    }

    // Add a toggle function for details section
    toggleDetails = () => {
        this.setState(prevState => ({
            showDetails: !prevState.showDetails
        }));
    }

    render() {
        // Add a CSS class for selected posts
        const postClassName = `post ${this.props.isSelected ? 'post-selected' : ''}`;

        // Add selection indicator if in selection mode
        const selectionIndicator = this.props.isSelectionMode ? (
            <div className="selection-indicator">
                <FontAwesomeIcon 
                    icon={this.props.isSelected ? faCheckSquare : faSquare} 
                    className={`selection-icon ${this.props.isSelected ? 'selected' : ''}`}
                />
            </div>
        ) : null;

        return (            
            <span className={postClassName} onClick={this.handleClick}> 
                {selectionIndicator}                               
                <span>
                    {
                        
                     <img 
                        className="post-img post-img-thumb" 
                        alt="post " 
                        loading="lazy" 
                        src={this.props.post.thumb} 
                        onClick={(e) => {
                            if (!this.props.isSelectionMode) {
                                e.preventDefault();
                                e.stopPropagation();
                                this.showModal();
                            }
                        }}
                    ></img>
                        
                    }
                   

                    <div className='post-icons'>                    
                        <span className='ilb'>
                            <ButtonGroup>
                                { this.props.post.expires !== null ? ( <Button title="Eligible for Courtesy Flush" variant="outline-secondary"><FontAwesomeIcon icon={ isShitpostDomain ? faPoo : faTrash } /></Button> ) : "" }  
                                

                                
                                    
                                 <Button title={this.props.post.content_type} variant="outline-secondary" >
                                    <FontAwesomeIcon 
                                        icon={this.props.post.content_type.includes("video") ? faVideo : faImage }  />
                                </Button>
                                

                                <Button variant="outline-secondary" title="Tags on post">
                                    <FontAwesomeIcon icon={faTags}/>&nbsp;{this.props.post.tags.length}                                
                                </Button>
                                <Button variant="outline-secondary" title="Copy URL to Item" onClick={this.copyIconButtonClick} >
                                    <FontAwesomeIcon icon={faCopy} />
                                </Button>
                            </ButtonGroup>                                         
                        </span>
                    </div>
                </span>                

                <Modal 
                    show={this.state.showModal}  
                    onHide={this.closeModal}
                    size="xl"
                    fullscreen="lg-down"
                    backdrop="static"  // Change from "static" to true to allow clicking outside to close
                    keyboard={true}  // Allow ESC key to close
                >
                    <Modal.Header >
                        <Modal.Title className="post-modal-title">
                        <InputGroup className='post-input-group'>
                                <InputGroup.Text>Name</InputGroup.Text>
                                <Form.Control
                                    type="text" 
                                    className='post-img-name-input'
                                    onChange={this.nameOnChange}
                                    onKeyDown={this.handleKeyPress}
                                    value={this.state.name}
                                />
                                <Button className='btn-primary'><FontAwesomeIcon icon={faSave} onClick={this.savePost} title='Save' /></Button>
                                <Button className='btn-secondary' onClick={this.resetNameEdits}><FontAwesomeIcon icon={faUndo} title='Undo'/></Button>
                            </InputGroup>                         
                        </Modal.Title>    
                    </Modal.Header>

                    <Modal.Body>
                        <Row className="mb-3">
                            <Col className="text-center">
                                {
                                    this.props.post.content_type.includes("video") ? 
                                    ( 
                                        <video 
                                            className="stash-modal-video" 
                                            style={{ maxWidth: "100%", margin: "0 auto" }}
                                            type={ this.props.post.content_type === "video/quicktime" ? "video/mp4" : this.props.post.content_type }
                                            autoPlay controls muted name={this.props.post._id}>
                                            <source src={this.props.post.objectUrl} />
                                        </video>
                                    ) : 
                                    (
                                        <Image 
                                            loading="lazy" 
                                            fluid 
                                            className="post-img-modal" 
                                            style={{ maxWidth: "100%", margin: "0 auto" }}
                                            alt="post" 
                                            src={this.props.post.objectUrl}>
                                        </Image>
                                    )
                                }
                            </Col>
                        </Row>
                        
                        <Button 
                            onClick={this.toggleDetails}
                            className="mb-3 w-100"
                            variant="outline-secondary"
                        >
                            {this.state.showDetails ? "Hide Details" : "Show Details"} 
                            <FontAwesomeIcon 
                                icon={this.state.showDetails ? faCaretUp : faCaretDown} 
                                className="ms-2" 
                            />
                        </Button>
                        
                        <Collapse in={this.state.showDetails}>
                            <div>
                                <Row>
                                    <Col>
                                        {/* Replace the buttons with the TagPicker component */}
                                        <div className='mt-2'>
                                            <TagPicker
                                                availableTags={this.props.allTags || []}
                                                selectedTags={this.props.post.tags || []}
                                                onTagToggle={this.toggleTag}
                                                placeholder="Search or select tags..."
                                                showSelectedTags={true}
                                                selectedTagsPosition="block" // Use wrapped display for post modal
                                            />
                                        </div>
                                        
                                        {/* The rest of the details remain the same */}
                                        <div className='mt-2'>
                                            <span className="post-detail-segment">                                        
                                                <FontAwesomeIcon icon={faFileLines} className='post-detail-icon-col' onClick={this.reOCR} />                                        
                                                <span className='post-detail-segment-ocr'>
                                                    { this.props.post.ocr_text !== undefined ? this.props.post.ocr_text : "n/a" }
                                                </span>
                                            </span>
                                        </div>

                                        <div className='mt-2'>
                                            <span className='post-detail-segment' title="Courtesy Flush" >
                                                <FontAwesomeIcon icon={faPoo} className='post-detail-icon-col' /> 
                                                {
                                                    this.props.post.expires !== null ? 
                                                    ( 
                                                        <Moment tz="Etc/UTC" format="HH:mm:ss" withTitle durationFromNow date={this.props.post.expires['$date'] } />
                                                    ) : "will not be courtesy flushed"
                                                }
                                            </span>                                
                                        
                                            <span title="Total size for item and any thumbnails/smaller sizes"  className='post-detail-segment' >
                                                <FontAwesomeIcon icon={faHardDrive} className='post-detail-icon-col' /> {  this.props.post.total_size !== undefined ?  humanFileSize(this.props.post.total_size, false, 1) : "n/a" }
                                            </span>

                                            <span title="Content_Type"  className='post-detail-segment' >
                                                <FontAwesomeIcon icon={this.props.post.content_type.includes("video") ? faVideo : faImage } className='post-detail-icon-col' /> { this.props.post.content_type }
                                            </span>

                                            <span title="Dimensions" className='post-detail-segment'>
                                                <FontAwesomeIcon icon={faX} className='post-detail-icon-col' /> 
                                                {this.props.post.width !== undefined ? this.props.post.width : "tbd"} x {this.props.post.height !== undefined ? this.props.post.height : "tbd"}
                                            </span>

                                            {
                                                this.props.post.content_type.includes("video") ? ( <span title="Length/Duration"  className='post-detail-segment' >
                                                <FontAwesomeIcon icon={faStopwatch } className='post-detail-icon-col' /> { this.decimalToMinutesAndSeconds(this.props.post.length) }
                                            </span>) : ""
                                            }

                                            {
                                                this.props.post.content_type.includes("video") ? ( <span title="Video Codec"  className='post-detail-segment' >
                                                <FontAwesomeIcon icon={faFileVideo } className='post-detail-icon-col' /> { this.props.post.video_codec }
                                            </span>) : ""
                                            }
                                            
                                            <span title="Owner"  className='post-detail-segment' >
                                                <FontAwesomeIcon icon={faUser} className='post-detail-icon-col' /> { this.props.post.owner }
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Collapse>
                    </Modal.Body>
                    
                    <Modal.Footer>
                        <Button 
                            className='btn btn-danger' 
                            onClick={(e) => {
                                e.stopPropagation();
                                this.flush();
                            }}
                        >
                            <FontAwesomeIcon icon={isShitpostDomain ? faToilet : faTrash} />
                            {isShitpostDomain ? " Flush" : " Delete"}
                        </Button>

                        {!this.state.shortUrl ? (
                            <Button 
                                className='btn btn-primary' 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.handleGetShortUrl();
                                }}
                            >
                                <FontAwesomeIcon icon={faLink} /> Get Short Link
                            </Button>
                        ) : (
                            <a 
                                href={this.state.shortUrl} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="btn btn-success"
                                onClick={(e) => e.stopPropagation()}
                            >
                                {this.state.shortUrl}
                            </a>
                        )}
                        
                        <Button 
                            className='btn btn-primary' 
                            onClick={(e) => {
                                e.stopPropagation();
                                this.copy(e);
                            }}
                        >
                            <FontAwesomeIcon icon={faCopy} /> 
                            {this.state.shortUrl ? " Copy Short Link" : " Copy"}
                        </Button>
                        
                        {/* Use for Meme button - only shown for images, not videos */}
                        {!this.props.post.content_type.includes('video') && (
                            <Button 
                                className='btn btn-info' 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // Close modal first to prevent it from staying open
                                    this.setState({ showModal: false }, () => {
                                        // Navigate to meme maker with background URL in the same window
                                        window.location.href = `/meme?bg=${encodeURIComponent(this.props.post.objectUrl)}`;
                                    });
                                }}
                                title="Open this image in the Meme Maker"
                            >
                                <FontAwesomeIcon icon={faPaintBrush} /> Use for Meme
                            </Button>
                        )}
                        
                        <Button 
                            className='btn btn-secondary' 
                            onClick={(e) => {
                                e.stopPropagation();
                                this.closeModal();
                            }}
                        >
                            <FontAwesomeIcon icon={faClose} /> Close
                        </Button>
                    </Modal.Footer>
                    
                </Modal>
            </span>
            
            
        )
    }
}

export default Post
