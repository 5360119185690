import React, {Component} from "react";
import { Container, Row, Col, Button, ButtonGroup } from 'react-bootstrap';
import Post from './Post';
import {getApiBase, getApiPath, copyToClipboard} from "./Utils.js";
import TagPicker from './TagPicker';
import ContentTypeDropdown from './ContentTypeDropdown';
import UploadModal from './UploadModal';

import './Stash.css'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faSearch, faXmarkCircle, 
    faObjectGroup, faTrash, faCopy, faCheckSquare, faXmark,  
     faUpload, faChevronDown, faChevronUp 
} from "@fortawesome/free-solid-svg-icons";

class Stash extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            posts : [],        
            filterTags: [],
            user: null,
            searchString: "",
            supported_content_types: [],
            filter_content_type: [],
            items_per_page: 30,
            page: 1,
            isSelectionMode: false,
            selectedPosts: [],
            // Add states for clipboard upload modal
            showUploadModal: false,
            uploadedUrl: "",
            uploadLoading: false,
            uploadError: null,
            // Ref for active element tracking
            activeElementType: null,
            // Add state for file upload modal
            showFileUploadModal: false,
            selectedFile: null,
            // Add these states for infinite scrolling
            isLoadingMore: false,
            hasMorePosts: true,
            allPosts: [], // This will store all loaded posts
            pastedFile: null,
            pastedPreview: null,
            mobileToastVisible: false,
            // Add state for mobile filter collapse
            filtersCollapsed: true
        }
        // Create a ref for the intersection observer
        this.sentinelRef = React.createRef();
        // Create a ref for the paste area
        this.pasteAreaRef = null;
        this.mobileTextAreaRef = null;
        this.mobileCameraInputRef = null;
    }

    getSupportedContentTypes = () => {
        const apiPath = getApiBase() + "/supported_content_types";

        fetch(apiPath)
        .then ( response => response.json())
        .then( data => {
            this.setState( { supported_content_types: data })
        } );

    }

    getStash = (loadMore = false) => {
        const apiPath = getApiPath("stash", localStorage.getItem("apiKey"), "");      
        var qsStrings = []

        if (this.state.filterTags.length > 0) {
            qsStrings.push("t=" + this.state.filterTags.join(","));
        }
        
        if (this.state.searchString !== "") {
            qsStrings.push("s=" + encodeURIComponent(this.state.searchString));
        }

        if (this.state.filter_content_type.length > 0) {
            qsStrings.push("ct=" + this.state.filter_content_type.join(","));
        }

        // Calculate which page to load
        const pageToLoad = loadMore ? this.state.page + 1 : 1;
        
        qsStrings.push("c=" + this.state.items_per_page);
        qsStrings.push("p=" + pageToLoad);

        var qs = "";
        if (qsStrings.length > 0) {
            qs = "?" + qsStrings.join("&");
        }

        // Set loading state
        this.setState({ 
            isLoadingMore: true,
            page: loadMore ? pageToLoad : 1 // Update page immediately to prevent duplicate requests
        });
        
        console.log(`Fetching page ${pageToLoad} with ${this.state.items_per_page} items per page`);
        
        fetch(apiPath + qs)
        .then(response => response.json())
        .then(data => {
            // If this is the first page or a filter change, replace all posts
            // Otherwise append the new posts to existing ones
            const newPosts = loadMore ? [...this.state.posts, ...data] : data;
            
            // Update state with the new posts
            this.setState({
                posts: newPosts,
                isLoadingMore: false,
                // If we got fewer items than requested, there's no more data
                hasMorePosts: data.length >= this.state.items_per_page
            });
            
            console.log(`Loaded ${data.length} items. Total posts now: ${newPosts.length}`);
        })
        .catch(error => {
            console.error("Error fetching stash:", error);
            this.setState({ isLoadingMore: false });
        });
    }

    
    getUser = () => {
        const tagsApiPath = getApiPath("user", localStorage.getItem("apiKey"), "");

        fetch(tagsApiPath)
        .then(response => response.json())
        .then(data => {           
            this.setState({user: data } )
        })
    }

    componentDidMount = () => {
        // Parse query parameters from URL
        const parsedTags = this.parseQueryParameters();
        
        // If we have tags from URL, set them first and then get stash with those filters
        if (parsedTags && parsedTags.length > 0) {
            this.setState({ filterTags: parsedTags }, () => {
                // After state is updated, then call getStash and other initialization
                this.getStash();
                this.getUser();
                this.getSupportedContentTypes();
            });
        } else {
            // Otherwise proceed with the regular initialization
            this.refreshTagsAndStash();
        }
        
        document.addEventListener('keydown', this.handleKeyPress);
        
        // Add paste event listener for clipboard uploads
        // this.pasteListener = (e) => this.handleGlobalPaste(e);
        // document.addEventListener('paste', this.pasteListener);
        
        // Track focus/blur events to know if user is in a form field
        document.addEventListener('focusin', this.handleFocusIn);
        document.addEventListener('focusout', this.handleFocusOut);
        
        // Add drag and drop event listeners
        this.dragDropTarget = document.body;
        this.dragDropTarget.addEventListener('dragover', this.handleDragOver);
        this.dragDropTarget.addEventListener('drop', this.handleDrop);
        this.dragDropTarget.addEventListener('dragenter', this.handleDragEnter);
        this.dragDropTarget.addEventListener('dragleave', this.handleDragLeave);
        
        // Add scroll listener for sticky header
        window.addEventListener('scroll', this.handleStickyHeader);
        
        // Set up infinite scroll with Intersection Observer
        this.setupInfiniteScroll();

        // Set initial navbar offset for filter container
        setTimeout(() => {
            const filterContainer = document.querySelector('.stash-filters-container');
            const navbarHeight = document.querySelector('.navbar')?.offsetHeight || 56;
            if (filterContainer) {
                filterContainer.style.top = `${navbarHeight}px`;
            }
        }, 100);
    }

    setupInfiniteScroll = () => {
        // Disconnect previous observer if it exists
        if (this.observer) {
            this.observer.disconnect();
        }
        
        // Create the observer
        this.observer = new IntersectionObserver(entries => {
            // If the sentinel comes into view and we're not already loading, and we have more posts
            if (entries[0].isIntersecting && !this.state.isLoadingMore && this.state.hasMorePosts) {
                console.log("Sentinel element visible, loading more posts");
                this.loadMorePosts();
            }
        }, { 
            threshold: 0.1,
            rootMargin: '100px' // Load a bit before the user reaches the end
        });
        
        // Start observing the sentinel element once it's available
        if (this.sentinelRef.current) {
            this.observer.observe(this.sentinelRef.current);
            console.log("Infinite scroll observer attached");
        } else {
            console.log("Sentinel ref not available yet");
            // Try again in a moment
            setTimeout(() => {
                if (this.sentinelRef.current) {
                    this.observer.observe(this.sentinelRef.current);
                    console.log("Infinite scroll observer attached (delayed)");
                }
            }, 500);
        }
    }

    loadMorePosts = () => {
        // Only load more if we're not already loading and there are more to load
        if (!this.state.isLoadingMore && this.state.hasMorePosts) {
            console.log("Loading more posts, current page:", this.state.page);
            this.getStash(true);  // Pass true to indicate we're loading more
        }
    }

    componentWillUnmount() {
        // Remove event listeners
        document.removeEventListener('keydown', this.handleKeyPress);
        // document.removeEventListener('paste', this.pasteListener);
        document.removeEventListener('focusin', this.handleFocusIn);
        document.removeEventListener('focusout', this.handleFocusOut);
        
        // Remove drag and drop event listeners
        if (this.dragDropTarget) {
            this.dragDropTarget.removeEventListener('dragover', this.handleDragOver);
            this.dragDropTarget.removeEventListener('drop', this.handleDrop);
            this.dragDropTarget.removeEventListener('dragenter', this.handleDragEnter);
            this.dragDropTarget.removeEventListener('dragleave', this.handleDragLeave);
        }
        
        // Remove scroll listener
        window.removeEventListener('scroll', this.handleStickyHeader);
        
        // Clean up the intersection observer
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    // Track what element is currently active
    handleFocusIn = (e) => {
        if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA' || 
            e.target.isContentEditable || e.target.getAttribute('role') === 'textbox') {
            this.setState({ activeElementType: 'text-input' });
        } else {
            this.setState({ activeElementType: e.target.tagName.toLowerCase() });
        }
    }

    handleFocusOut = () => {
        this.setState({ activeElementType: null });
    }

    // Handle clipboard paste events
    handleGlobalPaste = async (e) => {
        // If the file upload modal is open, don't process global pastes
        if (this.state.showFileUploadModal) {
            return;
        }

        // Only handle paste if not in a text input, textarea, or upload modal
        const { activeElementType, showUploadModal } = this.state;
        if (activeElementType === 'text-input' || showUploadModal) {
            return; // Let the default paste behavior work in inputs
        }

        const items = e.clipboardData?.items;
        if (!items) return;

        // Look for image items
        for (const item of items) {
            if (item.type.indexOf('image') === 0) {
                const file = item.getAsFile();
                if (file) {
                    e.preventDefault(); // Prevent default paste
                    
                    // Instead of processing the paste directly, let's open the upload modal
                    this.setState({
                        showFileUploadModal: true, 
                        selectedFile: file,
                        pastedFile: file,
                        pastedPreview: URL.createObjectURL(file)
                    });
                    break;
                }
            }
        }
    }



    // Handle copying the uploaded URL
    handleCopyUploadedUrl = async () => {
        const success = await copyToClipboard(this.state.uploadedUrl);
        if (success) {
            // Show a brief "Copied!" message or similar feedback
            this.setState({ uploadCopied: true });
            setTimeout(() => {
                this.setState({ uploadCopied: false });
            }, 2000);
        }
    }

    refreshTagsAndStash = () => {
        this.getStash();
        this.getUser();
        this.getSupportedContentTypes();
    }
  
    signalFlush = (post) => {
        console.dir(post)   
        this.getStash();     
    }

    toggleTag = (tag) => {
        var filterTags = this.state.filterTags;

        if (filterTags.indexOf(tag) === -1) {
            filterTags.push(tag);
        } else {
            filterTags.splice(filterTags.indexOf(tag), 1);
        }

        this.setState({ 
            filterTags: filterTags,
            page: 1, // Reset to page 1
            hasMorePosts: true // Reset the "hasMore" flag
        }, () => {
            this.updateUrlWithFilters();
            this.getStash(false); // false = replace posts, not append
        });
    }

    onSearchStringChange = (e) => {
        this.setState( { searchString: e.target.value })
    }

    onItemsPerPageChanced = (e) => {
        this.setState({ items_per_page: e.target.value }, () => this.getStash())
    }

    clearSearchString = () => {
        this.setState({ 
            searchString: "",
            filterTags: [],      // Clear selected tags
            filter_content_type: [], // Clear content type filter
            page: 1,             // Reset to page 1
            hasMorePosts: true   // Reset the "hasMore" flag
        }, () => {
            this.updateUrlWithFilters(); // Update the URL to reflect no tags
            this.getStash(false);   // Refresh the stash with no filters
        });
    }

    handleKeyDown = (e) => {
        if (e.key === "Enter")
        {
            this.getStash();
        }
    }

    handleKeyPress = (event) => {
    // Exit selection mode if ESC key is pressed
    if (event.key === 'Escape' && this.state.isSelectionMode) {
        this.toggleSelectionMode();
        return;
    }

    // Skip if we're typing in search field
    if (document.activeElement.classList.contains('stash-search-input')) return;

    // Remove arrow key navigation since we now use infinite scroll
    // No need to handle left and right arrow keys anymore
    };

    // filterContentType = (ct) => {
    //     this.setState({ filter_content_type: ct },
    //         this.getStash)
    // }

    toggleType = (ct) => {
        var filterCT = this.state.filter_content_type;

        if (filterCT.indexOf(ct) === -1) {
            filterCT.push(ct);
        } else {
            filterCT.splice(filterCT.indexOf(ct), 1);
        }

        this.setState({ 
            filter_content_type: filterCT,
            page: 1, // Reset to page 1
            hasMorePosts: true // Reset the "hasMore" flag
        }, () => {
            this.getStash(false); // false = replace posts, not append
        });
    }

    toggleSelectionMode = () => {
        this.setState(prevState => ({
            isSelectionMode: !prevState.isSelectionMode,
            selectedPosts: [] // Clear selections when toggling mode
        }));
    }

    togglePostSelection = (postId) => {
        this.setState(prevState => {
            const selectedPosts = [...prevState.selectedPosts];
            const index = selectedPosts.indexOf(postId);
            
            if (index === -1) {
                // Add post to selection
                selectedPosts.push(postId);
            } else {
                // Remove post from selection
                selectedPosts.splice(index, 1);
            }
            
            return { selectedPosts };
        });
    }

    selectAll = () => {
        const allPostIds = this.state.posts.map(post => post._id);
        this.setState({ selectedPosts: allPostIds });
    }

    deselectAll = () => {
        this.setState({ selectedPosts: [] });
    }

    bulkDeleteSelected = () => {
        if (this.state.selectedPosts.length === 0) {
            alert("No posts selected");
            return;
        }
        
        if (!window.confirm(`Are you sure you want to delete ${this.state.selectedPosts.length} posts?`)) {
            return;
        }
        
        const apiPath = getApiBase() + "/posts";
        
        fetch(apiPath, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-API-KEY": localStorage.getItem("apiKey")
            },
            body: JSON.stringify({
                _ids: this.state.selectedPosts,
                apikey: localStorage.getItem("apiKey")
            })
        })
        .then(response => {
            if (response.ok) {
                this.setState({ selectedPosts: [] });
                this.getStash();
            } else {
                alert("Error deleting posts");
            }
        })
        .catch(err => {
            console.error("Error in bulk delete:", err);
            alert("Error deleting posts");
        });
    }

    bulkCopyUrls = async () => {
        if (this.state.selectedPosts.length === 0) {
            alert("No posts selected");
            return;
        }
        
        // Get the URLs for all selected posts
        const selectedUrls = this.state.posts
            .filter(post => this.state.selectedPosts.includes(post._id))
            .map(post => post.objectUrl);
        
        // Join URLs with newlines
        const urlText = selectedUrls.join('\n');
        
        // Use the utility function to copy to clipboard
        const success = await copyToClipboard(urlText);
        
        if (success) {
            alert(`Copied ${selectedUrls.length} URLs to clipboard`);
        } else {
            alert("Failed to copy URLs to clipboard");
        }
    }

    parseQueryParameters = () => {
        // Get the query string from the URL
        const queryParams = new URLSearchParams(window.location.search);
        
        // Check for 'tag' parameter
        const tagParam = queryParams.get('tag');
        if (tagParam) {
            // Split by commas if multiple tags are provided
            const tags = tagParam.split(',').map(tag => tag.trim()).filter(tag => tag);
            
            if (tags.length > 0) {
                // Return the tags instead of setting state directly
                return tags;
            }
        }
        
        return null;
    }

    updateUrlWithFilters = () => {
        const { filterTags } = this.state;
        
        // Create a new URLSearchParams object
        const queryParams = new URLSearchParams();
        
        // Add tags to query parameters if they exist
        if (filterTags && filterTags.length > 0) {
            queryParams.set('tag', filterTags.join(','));
        }
        
        // Update the browser's URL without reloading the page
        const newUrl = window.location.pathname + 
            (queryParams.toString() ? `?${queryParams.toString()}` : '');
        
        window.history.pushState({}, '', newUrl);
    }

    // Handle drag events
    handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        // Don't show drag overlay if inside a text input
        if (this.state.activeElementType === 'text-input') return;
        
        this.setState({ isDraggingOver: true });
    }

    handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        // Only set dragging to false if we're leaving the document
        // This prevents flickering when moving between elements
        if (!e.relatedTarget || e.relatedTarget === document.body) {
            this.setState({ isDraggingOver: false });
        }
    }

    handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        this.setState({ isDraggingOver: false });
        
        // Don't handle drops in text inputs
        if (this.state.activeElementType === 'text-input') return;
        
        // Process dropped files
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const file = e.dataTransfer.files[0];
            
            // Check if it's an image or media file
            if (file.type.startsWith('image/') || file.type.startsWith('video/')) {
                // Open the upload modal with the file
                this.setState({
                    showFileUploadModal: true,
                    selectedFile: file
                });
            }
        }
    };

      

    // Handle file upload submit
    handleFilePickerUploadSubmit = async () => {
        const { selectedFile } = this.state;
        if (!selectedFile) return;

        this.setState({ uploadLoading: true, uploadError: null });

        try {
            const formData = new FormData();
            formData.append("file", selectedFile, selectedFile.name || "pasted-image.png");

            const url = getApiPath("upload", localStorage.getItem("apiKey"), "");

            const response = await fetch(url, {
                method: "POST",
                body: formData
            });

            if (!response.ok) {
                throw new Error(`Upload failed with status: ${response.status}`);
            }

            const resultUrl = await response.text();

            this.setState({
                uploadedUrl: resultUrl,
                uploadLoading: false,
                showFileUploadModal: false,
                selectedFile: null,
                pastedFile: null,
                pastedPreview: null,
                showUploadModal: true // Show the success modal
            });

            // Refresh the stash to show the newly uploaded file
            this.getStash();

        } catch (error) {
            console.error("Upload error:", error);
            this.setState({
                uploadError: error.message,
                uploadLoading: false
            });
        }
    }

    // Handle file selection from the file input
    handleFileInputChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            this.setState({ selectedFile: e.target.files[0] });
        }
    }

    // Handle the form submission to upload the file
    handleFileUploadSubmit = (e) => {
        e.preventDefault();
        
        if (!this.state.selectedFile) {
            return;
        }
        
        // Close the file selection modal
        this.setState({ showFileUploadModal: false });
        
        // Use the existing upload handler with the selected file
        this.uploadClipboardImage(this.state.selectedFile);
        
        // Reset the selected file
        this.setState({ selectedFile: null });
    }

    handleStickyHeader = () => {
        const filterContainer = document.querySelector('.stash-filters-container');
        const navbarHeight = document.querySelector('.navbar')?.offsetHeight || 56;  // Get actual navbar height
        
        if (filterContainer) {
            if (window.scrollY > 10) {
                filterContainer.classList.add('is-sticky');
                filterContainer.style.top = `${navbarHeight}px`;  // Dynamic height
            } else {
                filterContainer.classList.remove('is-sticky');
                filterContainer.style.top = `${navbarHeight}px`;  // Keep consistent
            }
        }
    }


   
    render() {

        if (this.state.user === undefined || this.state.user === null ){
            return "";
        }

        var posts = this.state.posts.map( (p) => {
            return (
                <Post 
                    signalPostSaved={this.getStash}
                    signalTagToggle={this.getStash} 
                    allTags={this.state.user.tags} 
                    tagsForPicker={this.state.tagsForPicker} 
                    key={p._id} 
                    signalFlush={this.signalFlush} 
                    post={p}
                    isSelectionMode={this.state.isSelectionMode}
                    isSelected={this.state.selectedPosts.includes(p._id)}
                    onToggleSelection={() => this.togglePostSelection(p._id)}
                ></Post>
            )
        })
        
    //   var cts = this.state.supported_content_types.map( (ct, ix) => {
    //     return (
    //         <Dropdown.Item id={ix} label={ct} name={ct} key={`content_type_${ix}`} 
    //             onClick={ () => { this.toggleType(ct)} }  >
    //             <FontAwesomeIcon icon={  this.state.filter_content_type.includes(ct) ? faSquareCheck : faSquare } /> {ct}
    //         </Dropdown.Item>
            
    //     )
    //   } )
        
        // var tags2 = this.state.user.tags.map( (tag, ix ) =>{
        //     return (
        //         <Dropdown.Item key={`check-${tag}`} className='btn-secondary'
        //             id={ix}
        //             label={tag} 
        //             name={tag}  
        //             onClick={ () => { this.toggleTag(tag)} }
        //             > <FontAwesomeIcon icon={  this.state.filterTags.includes(tag) ? faSquareCheck : faSquare } /> {tag}</Dropdown.Item>
        //     )
        // })     

        const selectionControls = this.state.isSelectionMode ? (
            <ButtonGroup className='stash-selection-controls ms-2'>
                <Button 
                    variant='secondary' 
                    onClick={this.deselectAll}
                    title="Deselect all"
                >
                    <FontAwesomeIcon icon={faXmark} />
                </Button>
                <Button 
                    variant='secondary' 
                    onClick={this.selectAll}
                    title="Select all"
                >
                    <FontAwesomeIcon icon={faCheckSquare} />
                </Button>
                <Button 
                    variant='danger' 
                    onClick={this.bulkDeleteSelected}
                    disabled={this.state.selectedPosts.length === 0}
                    title="Delete selected"
                >
                    <FontAwesomeIcon icon={faTrash} /> ({this.state.selectedPosts.length})
                </Button>
                <Button 
                    variant='primary' 
                    onClick={this.bulkCopyUrls}
                    disabled={this.state.selectedPosts.length === 0}
                    title="Copy URLs"
                >
                    <FontAwesomeIcon icon={faCopy} /> ({this.state.selectedPosts.length})
                </Button>
                <Button 
                    variant='secondary' 
                    onClick={this.toggleSelectionMode}
                    title="Exit selection mode"
                >
                    Done
                </Button>
            </ButtonGroup>
        ) : null;

        return (            
            <Container className='mt-2'>
                {/* Drag and Drop Overlay */}
                {this.state.isDraggingOver && (
                    <div className="drop-area-overlay">
                        <div className="drop-message">
                            <FontAwesomeIcon icon={faUpload} size="3x" />
                            <h3>Drop to Upload</h3>
                        </div>
                    </div>
                )}
                
                {/* Wrap the filters in a sticky container */}
                <div className="stash-filters-container position-sticky">
                    {/* Mobile Toggle Button - improved design */}
                    <div className="d-md-none mb-2 text-center">
                        <Button 
                            variant={this.state.filtersCollapsed ? "outline-primary" : "outline-secondary"}
                            size="sm" 
                            className="w-100 d-flex justify-content-between align-items-center"
                            onClick={() => this.setState(prevState => ({ filtersCollapsed: !prevState.filtersCollapsed }))}
                        >
                            <span>
                                <FontAwesomeIcon 
                                    icon={this.state.filtersCollapsed ? faSearch : faXmark} 
                                    className="me-2" 
                                />
                                {this.state.filtersCollapsed ? "Show Filters" : "Hide Filters"}
                            </span>
                            <FontAwesomeIcon 
                                icon={this.state.filtersCollapsed ? faChevronDown : faChevronUp} 
                            />
                        </Button>
                    </div>
                    
                    {/* Add a smooth transition effect */}
                    <div className={`filter-collapse-container ${this.state.filtersCollapsed ? 'collapsed' : 'expanded'}`}>
                        <Row className={`stash-filters-row ${this.state.filtersCollapsed ? 'd-none d-md-flex' : 'd-flex'}`}>
                            <Col>
                                {/* First row with search, content type, and action buttons */}
                                <div className="filter-top-row d-flex flex-wrap align-items-center">
    {/* Left side controls (search, content type, action buttons) */}
    <div className="filter-left d-flex flex-wrap align-items-center flex-grow-1">
        {/* Search input */}
        <div className="search-container me-2 mb-2">
            <input 
                placeholder="Search" 
                type='text' 
                className="stash-search-input" 
                value={this.state.searchString} 
                onChange={this.onSearchStringChange} 
                onKeyDown={this.handleKeyDown} 
            />
            
            <ButtonGroup className='stash-search-buttons'>
                <Button 
                    variant="secondary" 
                    className='stash-search-button' 
                    onClick={() => {
                        this.setState({ 
                            page: 1,
                            hasMorePosts: true
                        }, () => this.getStash(false));
                    }}
                >
                    <FontAwesomeIcon icon={faSearch} />&nbsp;Search
                </Button>
                <Button 
                    variant='secondary' 
                    className='stash-clear-button' 
                    onClick={this.clearSearchString}
                    title="Clear all filters"
                >
                    <FontAwesomeIcon icon={faXmarkCircle} />
                </Button> 
            </ButtonGroup>
        </div>
        
        {/* Content type filter */}
        <div className="me-2 mb-2">
            <ContentTypeDropdown 
                contentTypes={this.state.supported_content_types}
                selectedTypes={this.state.filter_content_type}
                onToggleType={this.toggleType}
            />
        </div>
        
        {/* Action buttons */}
        <div className="me-2 mb-2 d-flex">
            <Button 
                className='upload-btn me-2'
                variant="outline-secondary"
                title="Upload a file"
                onClick={() => this.setState({ showFileUploadModal: true })}
            >
                <FontAwesomeIcon icon={faUpload} /> Upload
            </Button>

            <Button 
                className='select-btn'
                variant={this.state.isSelectionMode ? "primary" : "outline-secondary"}
                title="Select multiple posts"
                onClick={this.toggleSelectionMode}
            >
                <FontAwesomeIcon icon={faObjectGroup} />
                {this.state.isSelectionMode ? " Cancel" : " Select"}
            </Button>
        </div>
        
        {/* Selection controls */}
        {selectionControls && (
            <div className="mb-2">
                {selectionControls}
            </div>
        )}
        
        {/* Tag filter - now positioned next to other controls instead of far right */}
        <div className="tag-filter-wrapper d-flex mb-2">
            <div className="stash-tag-filter-container d-flex align-items-center">
                <div className="stash-tag-picker-input me-2">
                    <TagPicker
                        availableTags={this.state.user.tags || []}
                        selectedTags={this.state.filterTags || []}
                        onTagToggle={this.toggleTag}
                        placeholder="Filter by tags..."
                        showSelectedTags={true}
                        selectedTagsPosition="inline" // Use horizontal scrolling for stash filter
                    />
                </div>
            </div>
        </div>
    </div>
</div>
                            </Col>
                        </Row>
                    </div>
                </div>

                {this.state.isSelectionMode && this.state.selectedPosts.length > 0 && (
                    <Row className="mt-2 pt-3">
                        <Col>
                            <div className="selection-status alert alert-info">
                                {this.state.selectedPosts.length} posts selected
                            </div>
                        </Col>
                    </Row>
                )}

                <Container className='stash mt-4'>
                    {
                        this.state.posts !== undefined && this.state.posts.length > 0 ? posts : "No posts found"
                    }
                    
                    {/* Loading indicator */}
                    {this.state.isLoadingMore && (
                        <Row className="my-3">
                            <Col className="text-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <p className="mt-2">Loading more posts...</p>
                            </Col>
                        </Row>
                    )}
                    
                    {/* End of results message */}
                    {!this.state.isLoadingMore && !this.state.hasMorePosts && this.state.posts.length > 0 && (
                        <Row className="my-3">
                            <Col className="text-center">
                                <p>No more posts to load</p>
                            </Col>
                        </Row>
                    )}
                    
                    {/* Sentinel element for infinite scroll */}
                    <div ref={this.sentinelRef} style={{ height: '10px', width: '100%' }}></div>

                    {/* Page info */}
                    {!this.state.isLoadingMore && (
                        <Row className="my-2">
                            <Col className="text-center">
                                <small className="text-muted">
                                    Page {this.state.page} • {this.state.posts.length} items loaded • {this.state.items_per_page} per page
                                </small>
                            </Col>
                        </Row>
                    )}
                </Container>    

                {/* Upload Modal Component */}
                <UploadModal
                    show={this.state.showFileUploadModal || this.state.showUploadModal}
                    onHide={() => {
                        this.setState({
                            showFileUploadModal: false,
                            showUploadModal: false,
                            selectedFile: null,
                            pastedFile: null,
                            pastedPreview: null,
                            uploadedUrl: ""
                        });
                    }}
                    initialFile={this.state.selectedFile || this.state.pastedFile}
                    onUploadComplete={(url) => {
                        // Refresh the stash to show the newly uploaded file
                        this.getStash();
                    }}
                />
            </Container>            
        )
    }
}


export default Stash
