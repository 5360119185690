import React, { Component } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './TextDef.css';

class TextDef extends Component {
  constructor(props) {
    super(props);
    
    // Initialize state to track which text entries have expanded advanced settings
    this.state = {
      expandedAdvanced: props.textDefs.map(() => false)
    };
  }
  
  handleTextChange = (index, field) => (event) => {
    // Create deep copy of the texts array to avoid state mutations
    const newTexts = this.props.textDefs.map(text => ({
      ...text,
      pos: { ...text.pos }  // Deep copy the pos object too
    }));
    
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    
    if (field === 'text' || field === 'color' || field === 'bgcolor' || 
        field === 'font' || field === 'hint' || field === 'outline') {
      newTexts[index][field] = value || null; // Convert empty string to null
    } else if (field === 'upperCase') {
      newTexts[index][field] = value;
    } else if (field === 'outline_wt') {
      // Directly parse as float and handle empty string
      const numValue = value === '' ? null : parseFloat(value);
      newTexts[index][field] = isNaN(numValue) ? null : numValue;
    } else if (['x', 'y', 'w', 'h', 'rotation_angle'].includes(field)) {
      // Parse values more carefully for position fields
      const numValue = value === '' ? 0 : parseFloat(value);
      newTexts[index].pos[field] = isNaN(numValue) ? 0 : numValue;
    }
    
    this.props.signalChange(newTexts);
  };

  deleteText = (index) => () => {
    const newTexts = [...this.props.textDefs];  // Use props instead of state
    newTexts.splice(index, 1);
    this.props.signalChange(newTexts);  // Signal change to parent
    
    // Update expanded state as well
    const newExpanded = [...this.state.expandedAdvanced];
    newExpanded.splice(index, 1);
    this.setState({ expandedAdvanced: newExpanded });
  };
  
  // Toggle expanded state for a text entry
  toggleAdvancedSettings = (index) => {
    const newExpanded = [...this.state.expandedAdvanced];
    newExpanded[index] = !newExpanded[index];
    this.setState({ expandedAdvanced: newExpanded });
  };

  render() {
    const { textDefs, availableFonts } = this.props;

    return (
      <Container className="text-def-container">
        
        {textDefs.map((text, index) => (
          <div key={index} className="text-entry mb-3">
            <Row className="align-items-center">
              <Col xs={6}>
                <h6 className="mb-0">Text #{index + 1}</h6>
              </Col>
              <Col xs={3}>
                <Form.Check
                  type="checkbox"
                  label="Uppercase"
                  checked={text.upperCase}
                  onChange={this.handleTextChange(index, 'upperCase')}
                />
              </Col>
              <Col xs={3} className="text-end">
                <Button
                  variant="danger"
                  size="sm"
                  onClick={this.deleteText(index)}                  
                >
                  <FontAwesomeIcon icon={faTrash} className="me-1" /> Delete
                </Button>
              </Col>
            </Row>

            <Form.Group as={Row} className="mt-2">
              <Form.Label column xs={3}>Text:</Form.Label>
              <Col xs={9}>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={text.text || ''}
                  onChange={this.handleTextChange(index, 'text')}
                  placeholder="Enter meme text (supports newlines)"
                  style={{ fontFamily: 'monospace' }}
                />
              </Col>
            </Form.Group>

            
            
            {/* Advanced Settings Accordion */}
            <div className="mt-2 mb-2">
              <Button 
                variant="outline-secondary" 
                size="sm"
                onClick={() => this.toggleAdvancedSettings(index)}
                className="w-100 text-left d-flex justify-content-between align-items-center"
              >
                <span>Advanced Settings</span>
                <span>{this.state.expandedAdvanced[index] ? '▲' : '▼'}</span>
              </Button>
              
              {this.state.expandedAdvanced[index] && (
                <div className="advanced-settings-container p-2 border border-top-0">
                  <Form.Group as={Row}>
              <Form.Label column xs={3}>Hint:</Form.Label>
              <Col xs={9}>
                <Form.Control
                  type="text"
                  value={text.hint || ''}
                  onChange={this.handleTextChange(index, 'hint')}
                  placeholder="Enter hint text"
                />
              </Col>
            </Form.Group>
            
                  <Form.Group as={Row}>
                    <Form.Label column xs={3}>Color:</Form.Label>
                    <Col xs={3}>
                      <Form.Control
                        type="text"
                        value={text.color || ''} // Ensure value is never undefined
                        onChange={this.handleTextChange(index, 'color')}
                        placeholder="e.g., FF0000"
                        className="color-input"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column xs={3}>Font:</Form.Label>
                    <Col xs={9}>
                      <Form.Select
                        value={text.font || ''}
                        onChange={this.handleTextChange(index, 'font')}
                      >
                        <option value="">default (impact)</option>
                        {Object.entries(availableFonts).map(([name, file]) => (
                          <option key={file} value={file}>
                            {name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={3}>Outline Color & Wt:</Form.Label>
                    <Col sm={5}>
                      <div className="d-flex align-items-center">
                        <Form.Control
                          type="text"
                          value={text.outline || ''}
                          onChange={this.handleTextChange(index, 'outline')}
                          placeholder="e.g., FFFFFF80" 
                          className="color-input me-3"
                        />
                        <Form.Label className="mb-0 me-2"></Form.Label>
                        <Form.Control
                          type="number"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          value={text.outline_wt || 1}
                          onChange={this.handleTextChange(index, 'outline_wt')}
                          placeholder="Weight"
                          className="outline-weight-input"
                        />
                      </div>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={3}>BG&nbsp;Color:</Form.Label>
                    <Col sm={3}>
                      <Form.Control
                        type="text"
                        value={text.bgcolor || ''}
                        onChange={this.handleTextChange(index, 'bgcolor')}
                        placeholder="e.g., FFFFFF"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={3}>X/Y:</Form.Label>
                    <Col sm={8}>
                      <div className="dimension-controls">
                        <div className="pos-input-group">
                          <Form.Label>X:</Form.Label>
                          <Form.Control
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            value={text.pos.x ?? 0}
                            onChange={this.handleTextChange(index, 'x')}
                            placeholder="X"
                            className="pos-input"
                          />
                        </div>
                        
                        <div className="pos-input-group">
                          <Form.Label>Y:</Form.Label>
                          <Form.Control
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            value={text.pos.y ?? 0}
                            onChange={this.handleTextChange(index, 'y')}
                            placeholder="Enter Y position"
                            className="pos-input"
                          />
                        </div>
                      </div>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={3}>W/H:</Form.Label>
                    <Col sm={8}>
                      <div className="dimension-controls">
                        <div className="pos-input-group">
                          <Form.Label>W:</Form.Label>
                          <Form.Control
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            step="10"
                            min="0"
                            value={text.pos.w ?? 0}
                            onChange={this.handleTextChange(index, 'w')}
                            placeholder="Enter width"
                            className="pos-input"
                          />
                        </div>

                        <div className="pos-input-group">
                          <Form.Label>H:</Form.Label>
                          <Form.Control
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            value={text.pos.h ?? 0}
                            onChange={this.handleTextChange(index, 'h')}
                            placeholder="Enter height"
                            className="pos-input"
                          />
                        </div>
                      </div>
                    </Col>
                  </Form.Group>

                  {/* Add Rotation Angle Input */}
                  <Form.Group as={Row}>
                    <Form.Label column sm={3}>Rotation:</Form.Label>
                    <Col sm={8}>
                      <div className="dimension-controls">
                        <div className="pos-input-group">
                          <Form.Label>Angle:</Form.Label>
                          <Form.Control
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            value={text.pos.rotation_angle ?? 0}
                            onChange={this.handleTextChange(index, 'rotation_angle')}
                            placeholder="Degrees"
                            className="pos-input"
                          />
                        </div>
                      </div>
                    </Col>
                  </Form.Group>
                </div>
              )}
            </div>
          </div>
        ))}
      </Container>
    );
  }
}

TextDef.defaultProps = {
  availableFonts: {}  // Provide default empty object for fonts
};

export default TextDef;